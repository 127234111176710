/* eslint-disable react-hooks/exhaustive-deps */
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import useLicenseStore, { adminApplyForLicense } from '../../../../App/stores/LicenseStore';
import CommonButton from '../../../../Components/Button/CommonButton';
import CommonInput from '../../../../Components/Input/CommonInput';
import CommonModal from '../../../../Components/Modal/CommonModal';
import { CarManagementContext } from '../../../../Context/CarManagementContext';
import LicenseCard from '../../../Users/Companies/CompanyDetails/CarManagement/LicenseCard';

function AdminLicenseApply() {
    const {
        selectedLicenseIndex,
        dispatch,
        allCarLicense,
        licenseStartDate,
        setLicenseStartDate,
    } = useContext(CarManagementContext);

    const { showAdminApplyLicense, setShowAdminApplyLicense, licenseDetails } = useLicenseStore();

    const [applyForm, setApplyForm] = useState({
        user_id: "",  //company id
        id: "", //target Car Id
        license_id: "",
        number: "",
        renew_license_start: "",
        purchase_license_comment: "",
    });

    // const handleClose = (event, reason) => {
    //     if (reason && reason === "backdropClick") return;
    //     setShowAdminApplyLicense(false);
    // };
    useEffect(() => {
        setApplyForm({
            ...applyForm,
            user_id: licenseDetails?.company_user_id,
            id: licenseDetails?.id,
            number: licenseDetails?.number
        });

    }, [licenseDetails]);

    useEffect(() => {
        setApplyForm({
            ...applyForm,
            number: allCarLicense[selectedLicenseIndex]?.number
        });
    }, [selectedLicenseIndex])

    const handleModalClose = () => {
        setShowAdminApplyLicense(false);
        dispatch({
            type: "SET_SELECTED_LICENSE_INDEX",
            payload: { index: 0, id: 0 },
        });
        setLicenseStartDate("")
        setApplyForm({
            user_id: "",  //company id
            id: "", //target Car Id
            license_id: "",
            number: "",
            renew_license_start: "",
            purchase_license_comment: "",
        })
    }
    return (
        <>
            <CommonModal
                showModal={showAdminApplyLicense}
                setShowModal={handleModalClose}
                modalTitle={t("Admin Purchase License")}

                mainContent={
                    <div className='pt-5'>
                        <div className="flex items-center justify-center">
                            <div className="flex justify-start space-x-5 overflow-x-auto">
                                {allCarLicense.map((license, index) => (
                                    <LicenseCard
                                        key={index}
                                        isSelected={selectedLicenseIndex === index ? true : false}
                                        onClick={(id) => {

                                            setApplyForm({ ...applyForm, license_id: license.id });

                                            dispatch({
                                                type: "SET_SELECTED_LICENSE_INDEX",
                                                payload: { index: index, id: license.id },
                                            });
                                            console.log(id);
                                        }}
                                        info={license}
                                    />
                                ))}
                            </div>
                        </div>

                        {selectedLicenseIndex !== "" ? (
                            <div className="pt-[20px] text-cBodyText text-fs16 font-bold">
                                {t("License Description:")}
                            </div>
                        ) : (
                            <div className="pt-[20px] text-cBodyText text-fs16 font-bold">
                                {t("Select a license above, to see details")}
                            </div>
                        )}
                        <div
                            className="prose prose-lg"
                            dangerouslySetInnerHTML={{
                                __html:
                                    selectedLicenseIndex !== null &&
                                    allCarLicense[selectedLicenseIndex]?.description,
                            }}
                        />

                        {/* date picker */}
                        <div className="w-full py-5">
                            <CommonInput
                                label={"start date"}
                                type="date"
                                placeholder={t("Select Start Date")}
                                value={(licenseStartDate)}
                                onChange={(e) => {
                                    setLicenseStartDate(e.target.value);
                                    setApplyForm({ ...applyForm, renew_license_start: e.target.value });
                                }}
                            />
                        </div>

                        <CommonInput
                            textarea={true}
                            max_char_limit={500}
                            is_show_char_limit={true}
                            label={t("Write Comment")}
                            placeholder={t("Write your comment here...")}
                            name="purchaseLicenseComment"
                            value={applyForm.purchase_license_comment}
                            onChange={(e) => setApplyForm({ ...applyForm, purchase_license_comment: e.target.value })}
                        />

                        <div className="flex flex-row justify-center pt-5">
                            {/* <CommonButton
                                btnLabel={t("Skip")}
                                colorType="danger"
                                onClick={() => setShowAdminApplyLicense(false)}
                            /> */}
                            <CommonButton
                                width="w-[180px]"
                                btnLabel={t("Apply For License")}
                                isDisabled={licenseStartDate && selectedLicenseIndex !== "" ? false : true}
                                colorType={licenseStartDate && selectedLicenseIndex !== "" ? "primary" : "base"}
                                onClick={async () => {
                                    let applySuccess;
                                    let formData = {...applyForm,
                                        id: licenseDetails?.id,
                                        license_id: applyForm?.license_id || allCarLicense[selectedLicenseIndex]?.id
                                    };

                                    if (licenseDetails?.license_status === "expire_warning" || licenseDetails?.license_status === "expired") applySuccess = await adminApplyForLicense(formData, true);
                                    else applySuccess = await adminApplyForLicense(formData);

                                    if (applySuccess) {
                                        setShowAdminApplyLicense(false)
                                        dispatch({
                                            type: "SET_SELECTED_LICENSE_INDEX",
                                            payload: { index: 0, id: 0 },
                                        });
                                        setLicenseStartDate("")
                                        setApplyForm({
                                            user_id: "",  //company id
                                            id: "", //target Car Id
                                            license_id: "",
                                            number: "",
                                            renew_license_start: "",
                                            purchase_license_comment: "",
                                        })
                                    }
                                }}
                            />
                        </div>
                    </div>
                }
            />
        </>
    );
}

export default AdminLicenseApply