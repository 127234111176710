import { t } from 'i18next';
import { useContext, useEffect } from "react";
import { useParams } from 'react-router-dom';
import CommonButton from '../../../../../Components/Button/CommonButton';
import CommonInput from '../../../../../Components/Input/CommonInput';
import { DriverManagementContext } from '../../../../../Context/DriverManagementContext';
import EmptyMessage from '../../../../../Components/EmptyMessage';

function UpdateDriver() {
  const {
    updateDriver,
    setUpdateChange,
    updateDriverInvitation,
    canUpdateSubmit,
    setCanUpdateSubmit,
    setShowModal,
    setSelectedDriverUID,
  } = useContext(DriverManagementContext);

  const { company_id } = useParams();

  useEffect(() => {
    setSelectedDriverUID(company_id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company_id])

  useEffect(() => {
    updateDriver.email?.toString().length > 0 &&
      updateDriver.comment?.toString().length > 0
      ? setCanUpdateSubmit(true)
      : setCanUpdateSubmit(false);
  });


  return (
    <>
      {updateDriver.email ? <form onSubmit={(e) => e.preventDefault()} className="card">
        <div className="mb-5 text-xl font-bold">{t("Driver Details")}</div>
        <CommonInput
          required={false}
          label={t("Name")}
          placeholder="Name"
          name="name"
          type="text"
          disabled={true}
          value={updateDriver.name}
          onChange={setUpdateChange}
        />
        <div className='mt-5'></div>

        <CommonInput
          required={false}
          label={t("Email")}
          placeholder="abc@mail.com"
          name="email"
          type="email"
          disabled={true}
          value={updateDriver.email}
          onChange={setUpdateChange}
        />

        <div className="pt-5">
          <CommonInput
            disabled={true}
            required={false}
            label={t("Phone Number")}
            placeholder="Phone Number"
            name="phone"
            type="number"
            value={updateDriver.phone}
            onChange={setUpdateChange}
          />
        </div>

        <div className='pt-s20'>
          <CommonInput
            required={false}
            disabled={true}
            textarea={true}
            max_char_limit={255}
            is_show_char_limit={false}
            label={t("Instruction")}
            placeholder={t("No note")}
            name="comment"
            value={updateDriver.comment}
            onChange={setUpdateChange}
          />
        </div>
        <div className="flex flex-row items-center justify-between pt-5">
          {/* <CommonButton
          btnLabel={t("Delete")}
          colorType="danger"
          onClick={() => setShowModal(true)}
        />
        <CommonButton
          btnLabel={t("Save")}
          type="submit"
          onClick={updateDriverInvitation}
          colorType={canUpdateSubmit ? "primary" : "base"}
          disabled={canUpdateSubmit ? true : false}
        /> */}
        </div>
      </form> :
        <div className="card h-[30rem] flex justify-center text-center text-gray-500 font-bold italic text-lg">
          <EmptyMessage message={t('Please select a driver to see details.')} />
        </div>
      }
    </>
  );
}

export default UpdateDriver;
