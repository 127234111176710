/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import {
  IoIosArrowForward,
  IoMdRadioButtonOff,
  IoMdRadioButtonOn,
} from "react-icons/io";
import { useLocation, useParams } from "react-router-dom";
import CommonButton from "../../../../../Components/Button/CommonButton";
import Input02 from "../../../../../Components/Input/Input02";
import CommonModal from "../../../../../Components/Modal/CommonModal";
import { ShiftPlannerContext } from "../../../../../Context/ShiftPlannerContext";
import { Toastr } from "../../../../../Utility/UtilityFunctions";

const SelectLicensePlate = ({
  data_id = null,
  onChange,
  disabled = false,
  lisensenNumberUpdate,
  tempName = "",
  isInMaintenance = false,
  hasManagement = false,
  value
}) => {
  const {
    getShiftCarsDriversList,
    setLicensePlateValue,
    shiftCars,
    setCarId,
    clearOnSubmitShift,
    setClearOnSubmitShift,
    isShiftDetailsID,
    setTempCarName,
    carId,
    setUpdateShift,
    updateShift,
    licenseName, 
    setLicenseName,
    selectedCar,
    setSelectedCar,
    // filterState,
  } = useContext(ShiftPlannerContext);

  const { company_id } = useParams();

  const [showModal, setShowModal] = useState(false);

  const location = useLocation();

  const transportation_types = [
    {
      id: 1,
      title: "AA 00 555",
    },
    {
      id: 2,
      title: "SD 00 325",
    },
    {
      id: 3,
      title: "FF 03 556",
    },
    {
      id: 4,
      title: "AE 05 105",
    },
    {
      id: 5,
      title: "55 SS 654",
    },
    {
      id: 6,
      title: "BB 10 888",
    },
    {
      id: 7,
      title: "QQ 22 648",
    },
    {
      id: 8,
      title: "PP 05 886",
    },
    {
      id: 9,
      title: "WE 99 555",
    },
    {
      id: 10,
      title: "KJ 12 155",
    },
  ];

  const ChangeLicensePlateValue = (e) => {
    setLicensePlateValue(e.target.value);
  };

  const submit = () => {
    const selected_transportation_type = shiftCars.find(
      (item) => item.id === selectedCar
    );
    if(hasManagement) {
      onChange(selected_transportation_type)
      setShowModal(false)
      return;
    }
    setLicenseName(selected_transportation_type.name);
    setTempCarName(selected_transportation_type.name);
    setLicensePlateValue(selected_transportation_type.car_license_plate_number);
    setCarId(selectedCar);
    console.log("selected car:::::", selected_transportation_type.title);
    setShowModal(false);
  };

  console.log(licenseName)

  useEffect(() => {
    if (clearOnSubmitShift) {
      setSelectedCar(null);
      setLicenseName("");
    }
  }, [clearOnSubmitShift]);

  useEffect(()=> {
    if(hasManagement) {
      setSelectedCar(value?.id)
    }
  }, [])

  const handleClose = () => {
    if(hasManagement) {
      setSelectedCar(value?.id)
    }
    else {
      setSelectedCar(carId);
    }
    setShowModal(false)
  }

  return (
    <div onClick={() => {}}>
      <Input02
        onClick={() => {
          // /users/companies/details/82/shift-planner/edit-shift
          if (
            location.pathname ===
              "/users/companies/details/" +
                company_id +
                "/shift-planner/edit-shift" &&
            carId
          ) {
            setSelectedCar(carId);
          }

          if (!disabled) {
            setClearOnSubmitShift(false);
            setShowModal(true);

            location.pathname ===
            "/users/companies/details/" +
              company_id +
              "/shift-planner/edit-shift"
              ? getShiftCarsDriversList(isInMaintenance, true, company_id)
              : getShiftCarsDriversList(isInMaintenance, false, company_id);
          } else {
            isInMaintenance
              ? Toastr(
                  "First, Fill up [ Start Date, End Date, Start Time and End Time ] fields above!",
                  "error"
                )
              : Toastr(
                  "First, Fill up [ Start Date, Start Time and End Time ] fields above!",
                  "error"
                );
          }
        }}
        name="transport_type"
        value={
          tempName
            ? tempName
            : licenseName
            ? licenseName
            : data_id
            ? transportation_types[data_id].title
            : ""
        }
        // value={licensePlateValue}
        onChange={(e) => {
          ChangeLicensePlateValue(e);
        }}
        className="cursor-pointer"
        label="Select Car/License Plate"
        type="text"
        placeholder="Select Car"
        icon={
          <IoIosArrowForward
            onClick={() => setShowModal(true)}
            className="h-[70%] cursor-pointer"
          />
        }
        is_readonly={true}
      />
      <CommonModal
        showModal={showModal}
        setShowModal={handleClose}
        modalTitle="Select Car"
        mainContent={
          <div>
            <div className="pt-5">
              <div className="max-h-[40vh] overflow-auto">
                {shiftCars?.length ? (
                  shiftCars?.map((item) => (
                    <div
                      key={item?.id}
                      onClick={() => {
                        setSelectedCar(item?.id);

                        location.pathname ===
                          "/users/companies/details/" +
                            company_id +
                            "/shift-planner/edit-shift" &&
                          setUpdateShift({ ...updateShift, car_id: item?.id });
                      }}
                      className={`${
                        selectedCar === item?.id && "ring-cSecondary ring-1"
                      } border border-transparent flex justify-between items-center py-3 m-[2px] px-4 mb-3 cursor-pointer rounded-lg bg-cMoreLiteGrey gs-text-body font-normal`}
                    >
                      <span className="max-w-[30vw] truncate">
                        {item?.name}{" "}
                        {item?.car_license_plate_number
                          ? `- ${item?.car_license_plate_number}`
                          : ""}
                      </span>

                      {selectedCar === item?.id ? (
                        <IoMdRadioButtonOn className="text-xl gs-text-secondary" />
                      ) : (
                        <IoMdRadioButtonOff className="text-xl" />
                      )}
                    </div>
                  ))
                ) : (
                  <div className="mx-auto text-center w-full font-semibold text-slate-500 italic pb-5">
                    No data found !
                  </div>
                )}
              </div>
            </div>

            <div className="mt-4 w-full flex justify-center">
              <CommonButton
                onClick={submit}
                type="button"
                btnLabel="Submit"
                className={`mr-4 inline-flex justify-center px-4 py-2 w-36 text-sm font-medium text-cWhite ${
                  selectedCar !== null
                    ? "bg-limadi"
                    : "gs-placeholder cursor-not-allowed"
                } border border-transparent rounded-br5`}
                disabled={selectedCar === null}
              />
            </div>
          </div>
        }
      />
    </div>
  );
};

export default SelectLicensePlate;
