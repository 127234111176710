/* eslint-disable react-hooks/exhaustive-deps */
import { t } from "i18next";
import { useContext, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import CommonButtonOutlined from "../../../../../../Components/Button/CommonButtonOutlined";
import CircularProgressBar from "../../../../../../Components/CircularProgressBar";
import { ShiftPlannerContext } from "../../../../../../Context/ShiftPlannerContext";
import { iRefresh } from "../../../../../../Utility/Sources";

const ShiftPlannerBar = () => {
  const location = useLocation();
  const { company_id } = useParams();

  const {
    isShiftHistory,
    shift,
    isLoading,
    setIsShiftDetailsData,
    getShiftHistoryList,
    getShiftPlannerList,
    isCreatingShift,
    setClearOnSubmitShift,
    setIsShiftHistory,
    setIsShiftDetailsID,
    setStartDate,
    setEndDate,
    setStartTimeValue,
    setEndTimeValue,
    setCommentValue,
    setUpdateShift,
    setChipData,
    onResetFilterHandler,
    setShiftFilter,
    setSelected,
    setSelectedCar,
  } = useContext(ShiftPlannerContext);

  const refreshFoo = async () => {
    if (isCreatingShift) {
      setStartDate("");
      setEndDate("");
      setStartTimeValue("");
      setEndTimeValue("");
      setCommentValue("");
      setClearOnSubmitShift(true);
    }
    setShiftFilter({
      startDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
      car: {},
      driver: {},
      isMaintenance: null,
      status: null,
      isApplied: false
    })
    setSelected(null)
    setSelectedCar(null)
    setUpdateShift({});
    setIsShiftDetailsID(null);
    setIsShiftDetailsData({});
    onResetFilterHandler();

    if (isShiftHistory) {
      getShiftHistoryList(company_id);
    } else {
      getShiftPlannerList(company_id);
    }
    setChipData({});
  };

  useEffect(() => {
    setChipData({});
    onResetFilterHandler();
  }, []);

  return (
    <>
      {/* starts::title bar */}

      <div className="flex flex-row justify-between card">
        {/* bar title */}
        <div className="flex flex-row items-start ">
          {isShiftHistory ? (
            <div className="mr-5 text-2xl font-bold">
              {" "}
              {t("Shift History")} ({shift.length && shift.length}){" "}
            </div>
          ) : (
            <div className="text-2xl font-bold mt-[0px] mr-5">
              {" "}
              {t("Shifts")} ({shift?.length ?? 0}){" "}
            </div>
          )}

          {/* refresh icon button */}
          <div>
            {!isLoading ? (
              <img
                onClick={refreshFoo}
                src={iRefresh}
                alt="refresh-icon"
                className="h-8 cursor-pointer"
              />
            ) : (
              <CircularProgressBar />
            )}
          </div>
        </div>

        <div>
          <div className="flex flex-row justify-end">
            {/* Shift Details */}
            {location.pathname ===
              "/users/companies/details/" +
                company_id +
                "/shift-planner/shift-history" ||
            location.pathname ===
              "/users/companies/details/" +
                company_id +
                "/shift-planner/create-new-shift" ||
            location.pathname ===
              "/users/companies/details/" +
                company_id +
                "/shift-planner/edit-shift" ? (
              <Link
                className=""
                to={"/users/companies/details/" + company_id + "/shift-planner"}
              >
                <CommonButtonOutlined
                  btnLabel={t("Shift Details")}
                  onClick={() => {
                    setIsShiftHistory(false);
                    setChipData({});
                  }}
                  type="submit"
                />
              </Link>
            ) : (
              <> </>
            )}
          </div>

          <div className="flex flex-row justify-end w-full space-x-5"></div>
        </div>
      </div>
      {/* ends::title bar */}
    </>
  );
};

export default ShiftPlannerBar;
