import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import CommonButton from "../../../../Components/Button/CommonButton";
import CommonImage from "../../../../Components/Image/CommonImage";
import CommonModal from "../../../../Components/Modal/CommonModal";
import { FavoriteCompanyContext } from "../../../../Context/FavoriteCompanyContext";
import { iAddress, iCvrIcon, iDummyCompany, iEmail, iFacebook, iHeartIcon, iLinkedIn, iPhone, iStarIcon, iTwitter, iWebsiteBlack } from "../../../../Utility/Sources";
import { BaseUrlSrc } from "../../../../Utility/url";
import RatingFiveStar from "./RatingFiveStar";
import useFavoriteCompaniesStore from "../../../../App/stores/FavoriteCompaniesStore";

const CompanyDetails = ({ data, reviewData, userID }) => {
  const { addFavoriteCompany, setSelectedIndex, setSelectedFavIndex, setFavCompanyDetails } = useContext(FavoriteCompanyContext);
  const [showFavConfirm, setShowFavConfirm] = useState(false);
  const [socialMedia, setSocialMedia] = useState({})

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { socialMediaData() }, [data])


  const { customer_id } = useParams();
  const { driver_id } = useParams();


  // generate social media data
  const socialMediaData = () => {
    if (data.social_media.length === 0) return;
    let tempMedia = {}

    // eslint-disable-next-line array-callback-return
    data.social_media.map(item => {
      if (item.domain === "facebook") tempMedia.facebook = item
      if (item.domain === "twitter") tempMedia.twitter = item
      if (item.domain === "linkedin") tempMedia.linkedin = item
    })
    setSocialMedia(tempMedia)
  }

  const { setShowReviewModal } = useFavoriteCompaniesStore();
  return (
    <div>
      {/* name logo rate review */}
      <div className="flex items-start justify-between">
        <div className="flex flex-col items-center justify-center">
          <div className="text-2xl font-semibold">{data.name}</div>
          <div className="bg-[#DFDFDF] w-[100px] h-[100px] overflow-hidden rounded-full mt-5 border-2 border-[#89919E] ">
            {data.image ? (
              <img
                className="object-cover w-full h-full "
                src={data?.image ? BaseUrlSrc + data?.image : iDummyCompany}
                alt="company-img"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = iDummyCompany;
                }}
              />
            ) : (
              <img
                className="object-cover w-full h-full p-7"
                src={iDummyCompany}
                alt="company-img"
              />
            )}
          </div>
        </div>
        <div
        onClick={() => {
           setShowReviewModal(true);
           console.log({reviewData});
        }}
        className="flex justify-center items-center space-x-2 cursor-pointer" 
        >
          
          <RatingFiveStar rating={parseFloat(reviewData?.rating?.toFixed(1))} /> <button className="text-[#F89818]" >({parseFloat(reviewData?.people_reviewed?.toFixed(1))} ratings)</button>
        </div>
        {/* <div className="flex flex-col items-center justify-center">
          <div className="flex items-center">

            <img src={iStarIcon} alt="" />
            <span className="pl-1 pr-5 text-base font-normal pt-s2">{data.rating}</span>
            <img src={iHeartIcon} alt="" />
          </div>
          <div className="mt-5 text-[#595959] font-medium">{t("Total Review")}</div>
          <div className="text-lg font-semibold">
            {reviewData.people_reviewed}
          </div>
         <div className="text-[#F89818] text-sm font-normal underline cursor-pointer">
            {customer_id ?
              <Link to={"/users/customers/details/" + customer_id + "/favorite-company/reviews"}>
                {t("Total Comments")} ({reviewData.reviews && reviewData.reviews.length})
              </Link> :
              <Link to={"/users/drivers/details/" + driver_id + "/favorite-company/reviews"}>
                {t("Total Comments")} ({reviewData.reviews && reviewData.reviews.length})
              </Link>
            }
          </div> 
        </div> */}
      </div>

      {/* about */}
      <div className="py-5">
        <div className="text-2xl font-semibold">{t("About Company")}</div>
        {data.about ? (
          <div
            className="bg-[#F8F8F8] p-[10px] rounded-md mt-5 break-all whitespace-pre-line"
            dangerouslySetInnerHTML={{ __html: data.about }}
          ></div>
        ) : (
          <div className="bg-[#F8F8F8] p-[10px] rounded-md mt-5 text-sm font-normal break-all whitespace-pre-line">No about</div>
        )}
      </div>

      <div className="pb-5">
        <div className="text-2xl font-semibold">{t("Terms and Conditions")}</div>
        {data?.terms_condition ? (
          <div
            className="bg-[#F8F8F8] p-[10px] rounded-md mt-5"
            dangerouslySetInnerHTML={{ __html: data?.terms_condition.terms_condition }}
          ></div>
        ) : (
          <div className="bg-[#F8F8F8] p-[10px] rounded-md mt-5 text-sm font-normal break-all whitespace-pre-line">No terms & conditions</div>
        )}
      </div>

      {/* contact us and social media */}
      <div className="flex items-start justify-between pb-5">
        <div className="flex flex-col items-start justify-start">

          {/* contact us */}
          <span className="text-2xl font-semibold">{t("Contact Us")}</span>

          <div className="flex items-center pt-2">
            <img className="w-[15px] h-[16px]" src={iCvrIcon} alt="" />
            <span className="pl-[10px] text-[#595959] text-sm font-normal">
              {
                data.email ?
                  <div>{data.cvr}</div>
                  :
                  "No CVR"
              }
            </span>
          </div>


          <div className="flex items-center pt-2">
            <img className="w-[15px] h-[16px]" src={iEmail} alt="" />
            <span className="pl-[10px] text-[#595959] text-sm font-normal underline">
              {
                data.email ?
                  <a href={`mailto:${data.email}`}>{data.email}</a>
                  :
                  "No email"
              }
            </span>
          </div>

          <div className="flex items-center pt-2">
            <img className="w-[15px] h-[16px]" src={iPhone} alt="" />
            <span className={`pl-[10px] text-[#595959] text-sm font-normal ${data.phone ? "underline" : ""}`}>
              {
                data.phone ?
                  <a href={`tel:${data.phone}`}>{data.phone}</a>
                  :
                  "No phone number"
              }
            </span>
          </div>

          {/* //todo: go to this address */}
          <div className="flex items-center pt-2">
            <img className="w-[15px] h-[16px]" src={iAddress} alt="" />
            <span className={`pl-[10px] text-[#595959] text-sm font-normal ${data.address ?"":""}`}>
              {
                data.address ?
                  data.address
                  :
                  "No address"
              }
            </span>
          </div>

          <div className="flex items-center pt-2">
            <img className="w-[15px] h-[16px]" src={iWebsiteBlack} alt="" />
            <span className={`pl-[10px] text-[#595959] text-sm font-normal ${data.address ?"underline":""}`}>
              {data?.website ? <a target={'_blank'}
                href={data?.website?.includes("http") ? data?.website : `https://${data?.website}`} rel='noreferrer'
                className='break-all cursor-pointer'>{data?.website}</a> : 'No website'}
            </span>
          </div>

        </div>

        {/* social links */}
        {data.social_media.length > 0 ?
          <div
          onClick={() => {
            console.log("data.social_media", data.social_media);
            
          }}
          className="flex flex-col">
            <span className="text-2xl font-semibold text-center">
              {t("Social Links")}
            </span>
            <div className="flex flex-row items-center pt-[20px]">

              {
                !!socialMedia?.facebook?.link ? (<a href={`${socialMedia.facebook.link}`} target="_blank" rel="noreferrer">
                  <img className="mx-2 w-[40px] h-[40px] " src={iFacebook} alt="social logo" />
                </a>)
                  : ""
              }

              {
                !!socialMedia?.twitter?.link ? <a href={`${socialMedia.twitter.link}`} target="_blank" rel="noopener noreferrer" >
                  <img className="mx-2 w-[40px] h-[40px] cursor-pointer" src={iLinkedIn} alt="social logo" />
                </a>
                  : ""
              }

              {
                !!socialMedia?.linkedin?.link ? <a href={`${socialMedia.linkedin.link}`} target="_blank" rel="noreferrer">
                  <img className="mx-2 w-[40px] h-[40px] cursor-pointer" src={iTwitter} alt="social logo" />
                </a>
                  : ""
              }

            </div>
          </div>
          : ""
        }

      </div>

      {/* remove button */}
      {/* <div className="flex items-center justify-center">
        <CommonButton
          btnLabel={t("Remove")}
          colorType="danger"
          onClick={async () => setShowFavConfirm(true)}
        />
      </div> */}


      {/* remove favorite company modal */}
      <CommonModal
        showModal={showFavConfirm}
        setShowModal={setShowFavConfirm}
        modalTitle={t("Confirmation")}
        mainContent={
          <div className="mt-5">
            <div className="pb-5 text-base text-center">
              {t("Are you sure you want to remove this company from favorite?")}
            </div>

            <div className="flex justify-center">
              <CommonButton
                btnLabel={t("Confirm")}
                colorType="danger"
                onClick={async () => {
                  addFavoriteCompany(userID);
                  setSelectedIndex(0);
                  setSelectedFavIndex(0);
                  setFavCompanyDetails({});
                  setShowFavConfirm(false);
                }}
              />
            </div>
          </div>
        }
      />

    </div>
  );
};

export default CompanyDetails;
