import React from 'react'
import useLicenseStore, {changeLicenseStatus} from '../../../App/stores/LicenseStore'
import CommonButton from '../../../Components/Button/CommonButton';
import CommonModal from '../../../Components/Modal/CommonModal';

export default function DeleteLicensePackage() {
    const { showDeleteLicense, setShowDeleteLicense } = useLicenseStore();
    return (
        <CommonModal
            showModal={showDeleteLicense}
            setShowModal={setShowDeleteLicense}
            modalTitle="confirmation"
            mainContent={
                <>
                    <div className="py-5 text-center">
                        Are you sure you want to deactivate this license package?
                    </div>
                </>
            }
            secondaryActionButton={
                <CommonButton
                    btnLabel='cancel'                    
                />
            }
            primaryActionButton={
                <CommonButton
                    btnLabel='Deactivate'
                    colorType='danger'
                    onClick={()=> changeLicenseStatus()}
                />
            }
        />
    )
}
