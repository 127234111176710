import { Tooltip } from "antd";
import React, { useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { InfoIcon } from "../../App/Utility/ImageImports";
import { iInfo } from "../../Utility/Sources";
// import { HideIcon, ShowIcon } from "../../App/Utility/ImageImports";

const CommonInput = ({
  labelCapitalize = true,
  onClick,
  onChange,
  tooltip = "",
  name,
  warningBorder = false,
  unnecessaryCharacters = false,
  value,
  type = "text",
  label,
  placeholder,
  className = "",
  className2 = "",
  className3 = "",
  className4 = "",
  max_input,
  min_input,
  min_number,
  max_number,
  is_readonly = false,
  no_label = false,
  icon = null,
  pipe = false,
  textarea = false,
  withStar = true,
  rows = 7,
  cols = 15,
  togglePasswordBtn = false,
  required = false,
  disabled = false,
  startDate,
  allowPastDates = false,
  disableCopy = false,
  disablePaste = false,
  notEditable = false,
  max_char_limit = 255,
  is_show_char_limit = false,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [inputType, setInputType] = useState(type);

  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  function handleKeyPress(event) {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (type === "tel") {
      if (
        [
          "/",
          "*",
          "#",
          "<",
          ">",
          "-",
          ".",
          ",",
          "e",
          ".e",
          "@",
          "%",
          "&",
          "(",
          ")",
          "=",
        ].includes(keyValue)
      ) {
        event.preventDefault();
      }
    } else {
      if (
        [
          "/",
          "+",
          "++",
          "*",
          "#",
          "<",
          ">",
          "-",
          ".",
          ",",
          "e",
          ".e",
          "@",
          "%",
          "&",
          "(",
          ")",
          "=",
        ].includes(keyValue)
      ) {
        event.preventDefault();
      }
    }
  }

  return (
    <div>
      {!no_label && (
        <div
          className={`text-cInputLabel text-fs16 mb-s10 font-semibold text-gray-600 ${
            labelCapitalize ? "capitalize" : ""
          }  w-full ${
            required === true && type !== "date" && withStar === true
              ? "req-field"
              : ""
          }`}
        >
          {label}
        </div>
      )}

      <div
        className={`${className2} ${
          icon && "relative flex justify-center items-center w-full"
        }`}
      >
        <div
          className={`${
            togglePasswordBtn || tooltip ? "relative" : ""
          } relative`}
        >
          {!textarea && (
            <input
              onKeyPress={unnecessaryCharacters ? handleKeyPress : null}
              onPaste={(e) => {
                if (disablePaste) {
                  e.preventDefault();
                  return false;
                }
                onChange(e);
              }}
              onCopy={(e) => {
                if (disableCopy) {
                  e.preventDefault();
                  return false;
                }
              }}
              disabled={disabled}
              required={required}
              onClick={onClick}
              onChange={onChange}
              type={inputType}
              name={name}
              value={value && value}
              data-date-format={type === "date" ? "DD MMMM YYYY" : ""}
              maxLength={max_input ? max_input : 4096}
              minLength={min_input ? min_input : 0}
              onKeyDown={(e) => {
                if (type === "date") e.preventDefault();
              }}
              max={max_number}
              onInput={maxLengthCheck}
              min={
                type === "number"
                  ? min_number
                  : type === "date"
                  ? startDate
                    ? startDate
                    : allowPastDates
                    ? ""
                    : new Date().toISOString().split("T")[0]
                  : ""
              }
              className={`bg-cMoreLiteGrey ${
                warningBorder ? "border border-cRed" : "border-cNmSelect"
              } bg-cMoreLiteGrey px-5 py-s7 w-full rounded-md ${className3} ${
                togglePasswordBtn === true ? "pr-s50" : ""
              }
                ${
                  disabled
                    ? "cursor-not-allowed text-gray-400"
                    : "cursor-text text-cTextBlack"
                }
              `}
              placeholder={placeholder}
              readOnly={is_readonly}
            />
          )}

          {tooltip ? (
            <Tooltip
              title={tooltip}
              className="absolute top-[9px] right-3 cursor-pointer"
            >
              <img src={InfoIcon} alt="" />
            </Tooltip>
          ) : (
            ""
          )}

          {togglePasswordBtn === true ? (
            showPassword === true ? (
              <div
                onClick={() => {
                  setShowPassword(!showPassword);
                  if (inputType === "password") {
                    setInputType("text");
                  } else {
                    setInputType("password");
                  }
                }}
                className="absolute p-2 cursor-pointer top-1 right-3"
              >
                <AiFillEye />
              </div>
            ) : (
              <div
                onClick={() => {
                  setShowPassword(!showPassword);
                  if (inputType === "password") {
                    setInputType("text");
                  } else {
                    setInputType("password");
                  }
                }}
                className="absolute p-2 cursor-pointer top-1 right-3"
              >
                <AiFillEyeInvisible />
              </div>
            )
          ) : (
            ""
          )}

          {disabled === true && notEditable === true ? (
            <Tooltip title="Not Editable">
              <img
                src={iInfo}
                alt="show-hide-icon"
                className="absolute top-1 right-3 p-1.5 cursor-pointer"
              />
            </Tooltip>
          ) : (
            ""
          )}
        </div>

        <div className="relative">
          {textarea && (
            <textarea
              // autoComplete={autoComplete}
              disabled={disabled}
              name={name}
              required={required}
              onChange={(e) => {
                if (
                  is_show_char_limit &&
                  e.target.value.length > max_char_limit
                )
                  return;
                onChange(e);
              }}
              value={value ? value : ""}
              className={`h-full w-full resize-none rounded-md px-5 py-3 bg-cMoreLiteGrey ${className3} ${
                disabled ? "cursor-not-allowed text-gray-400" : "cursor-auto text-cTextBlack"
              }`}
              rows={rows}
              cols={cols}
              placeholder={placeholder}
            ></textarea>
          )}

          {icon && (
            <div
              className={`absolute right-1 px-2 ${
                pipe && "border-l"
              } border-cInputBorder text-gray-600 ${className4}`}
            >
              {icon}
            </div>
          )}

          {disabled === true && notEditable === true && textarea ? (
            <Tooltip title="Not Editable">
              <img
                src={iInfo}
                alt="show-hide-icon"
                className="absolute top-1 right-3 p-1.5 cursor-pointer"
              />
            </Tooltip>
          ) : (
            ""
          )}
          {is_show_char_limit && (
            <div className="absolute right-2 bottom-2">
              {" "}
              {value?.length ?? 0}/{max_char_limit}{" "}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CommonInput;
