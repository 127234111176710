import { t } from 'i18next';
import * as React from "react";
import { useParams } from 'react-router-dom';
import CommonButton from '../../../../../Components/Button/CommonButton';
import CommonInput from '../../../../../Components/Input/CommonInput';
import CommonModal from '../../../../../Components/Modal/CommonModal';
import { CarManagementContext } from '../../../../../Context/CarManagementContext';
import LicenseCard from "./LicenseCard";

export default function LicenseModal() {
  const {
    openLicenseModal,
    setOpenLicenseModal,
    selectedLicenseIndex,
    dispatch,
    handleApplyForLicense,
    addTypeUpdate,
    setAddTypeUpdateFormChange,
    allCarLicense,
    updateCar,
    UpdateApplyForLicense,
    detailsState,
    licenseStartDate,
    setLicenseStartDate,
  } = React.useContext(CarManagementContext);

  const { company_id } = useParams();


  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpenLicenseModal(false);
    console.log(addTypeUpdate);
  };

  React.useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModalClose = () => {
    setOpenLicenseModal(false);
    dispatch({
      type: "SET_SELECTED_LICENSE_INDEX",
      payload: { index: "", id: "" },
    });
    setLicenseStartDate("");
    setAddTypeUpdateFormChange({target: {
      name: "purchaseLicenseComment",
      valuee: ""
    }})
  }

  return (
    <>
      <CommonModal
        showModal={openLicenseModal}
        setShowModal={handleModalClose}
        modalTitle={t("Choose License Packages")}
        mainContent={
          <div className='pt-5'>
            <div className="flex justify-center items-center">
              <div className="flex w-full justify-start space-x-5 overflow-x-auto">
                {allCarLicense.map((license, index) => (
                  <LicenseCard
                    key={index}
                    index={index}
                    isSelected={selectedLicenseIndex === index ? true : false}
                    onClick={(id) => {
                      dispatch({
                        type: "SET_SELECTED_LICENSE_INDEX",
                        payload: { index: index, id: license.id },
                      });
                      console.log(id);
                    }}
                    info={license}
                  />
                ))}
              </div>
            </div>

            {selectedLicenseIndex !== "" ? (
              <div className="pt-[20px] text-cBodyText text-fs16 font-bold">
                {t("License Description:")}
              </div>
            ) : (
              <div className="pt-[20px] text-cBodyText text-fs16 font-bold">
                {t("Select a license above, to see details")}
              </div>
            )}
            <div
              className="prose prose-lg"
              dangerouslySetInnerHTML={{
                __html:
                  selectedLicenseIndex !== null &&
                  allCarLicense[selectedLicenseIndex]?.description,
              }}
            />

            {/* date picker */}
            <div className="py-5 w-full">
              <CommonInput
                disabled={selectedLicenseIndex === ""}
                label={"start date"}
                type="date"
                placeholder={t("Select Start Date")}
                value={(licenseStartDate)}
                onChange={(e) => setLicenseStartDate(e.target.value)}
              />
            </div>

            <CommonInput
              withStar={false}
              required={true}
              textarea={true}
              max_char_limit={500}
              is_show_char_limit={true}
              label={t("Application Note")}
              placeholder={t("Enter a note to admin for license activation")}
              name="purchaseLicenseComment"
              value={addTypeUpdate.purchaseLicenseComment}
              onChange={setAddTypeUpdateFormChange}
            />

            <div className="flex flex-row justify-between pt-5">
              <CommonButton
                btnLabel={t("Skip")}
                colorType="danger"
                onClick={() => handleClose()}
              />
              <CommonButton
                width="w-[180px]"
                btnLabel={
                  updateCar?.licenseStatus === "expire_warning" ||
                    updateCar?.licenseStatus === "expired"
                    ? t("Renew License")
                    : t("Apply For License")
                }
                isDisabled={licenseStartDate && selectedLicenseIndex !== "" ? false : true}
                colorType={licenseStartDate && selectedLicenseIndex !== "" ? "primary" : "base"}
                onClick={() => {
                  detailsState === "add"
                    ? handleApplyForLicense(company_id)
                    : UpdateApplyForLicense(company_id);
                  setOpenLicenseModal(false);
                  dispatch({
                    type: "SET_SELECTED_LICENSE_INDEX",
                    payload: { index: 0, id: 0 },
                  });
                  setLicenseStartDate("");
                  setAddTypeUpdateFormChange({target: {
                    name: "purchaseLicenseComment",
                    valuee: ""
                  }})
                }}
              />
            </div>
          </div>
        }
      />
    </>
  );
}
