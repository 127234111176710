/* eslint-disable react-hooks/exhaustive-deps */
import { t } from "i18next";
import { useContext, useEffect } from "react";
import ListSkeleton01 from "../../../../../../Components/Skeletons/ListSkeleton01";
import { ShiftPlannerContext } from "../../../../../../Context/ShiftPlannerContext";
import { iFilter } from "../../../../../../Utility/Sources";
import Shift from "../Shift";
import Chip from "./Chip";
import LimadiSearchBox from "../../../../../../Components/Input/LimadiSearchBox";

const ShiftPlannerList = () => {
  const {
    searchShift,
    searchKey,
    isLoading,
    shift,
    setIsCompleted,
    setIsDeleted,
    setLicensePlateValue,
    setSelectedDriver,
    setFilterState,
    setShowFilterModal,
    chipData,
    setChipData,
    onResetFilterHandler,
    setSearchKey,

    filterStartDate,
    filterEndDate,
    filterStartTime,
    filterEndTime,
    licensePlateValue,
    selectedDriver,
    isCompleted,
    isDeleted,
    is_maintenance,
    filterStatus,
    isFilterApplied,
    shiftFilter,
  } = useContext(ShiftPlannerContext);

  useEffect(() => {
    setIsDeleted(false);
    setIsCompleted(false);
    setLicensePlateValue("");
    setSelectedDriver("");
  }, []);

  const closeChip = (key) => {
    const newChipData = { ...chipData };
    delete newChipData[key];
    setChipData(newChipData);
  };

  return (
    <>
      <div className="">
        <div className="flex-col justify-start pb-5 bg-white shadow rounded-xl">
          {/* hb search box with filter btn setup */}
          <div className="flex items-center justify-between px-5 pt-5 pb-s10">
            <div className="w-full pr-2">
              <LimadiSearchBox
                fullWidth={true}
                placeholder={t("search")}
                type="search"
                withClearSearch={true}
                onSearchClear={() => {
                  setSearchKey("");
                  searchShift("");
                }}
                onChange={(e) => {
                  searchShift(e.target.value);
                }}
                name="searchKey"
                value={searchKey}
              />
            </div>

            <div className="relative p-1.5">
              <img
                className="cursor-pointer select-none"
                onClick={() => {
                  setShowFilterModal(true);
                  setFilterState(true);
                }}
                src={iFilter}
                alt=""
              />
              {shiftFilter?.isApplied && (
                shiftFilter?.startDate ||
                shiftFilter?.endDate ||
                shiftFilter?.startTime ||
                shiftFilter?.endTime ||
                shiftFilter?.car?.id ||
                shiftFilter?.driver?.id ||
                shiftFilter?.isMaintenance ||
                shiftFilter?.status
              )
                 ? (
                <div className="absolute right-0 top-0">
                  <div className="rounded-full w-2 h-2 bg-cBrand"></div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="flex flex-row flex-wrap justify-start mx-5">
            {Object.entries(chipData).map(([key, value], index) => (
              <Chip
                key={index}
                title={value}
                onClick={() => {
                  closeChip(key);
                  setChipData({});
                  onResetFilterHandler();
                }}
              />
            ))}
          </div>

          {/* hy list ui starts */}
          <ul className="overflow-y-auto scroll-smooth max-h-[60vh]">
            {isLoading ? (
              <ListSkeleton01 />
            ) : (
              <>
                {shift?.length ? (
                  shift?.map((item) => (
                    <Shift
                      key={item?.id}
                      data={item}
                    />
                  ))
                ) : (
                  <div className="h-[30px] flex justify-center text-center text-gray-500 font-bold italic text-base">
                    {t("No Data Available!")}
                  </div>
                )}
              </>
            )}

            <div className="mb-3"></div>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ShiftPlannerList;
