import React, { useEffect } from "react";
import Option from "./Components/Option";
import RightArrow from "../../Images/icons/right_arrow.svg";
import { useTranslation } from "react-i18next";
import OptionNested from "./Components/OptionNested";
import useSettingsStore, { setContent } from "../../App/stores/SettingsStore";
import { useState } from "react";
import { PageTitle } from "../../Utility/UtilityFunctions";

const Settings = () => {
  const { t } = useTranslation();

  const { isActive, activeComponent, setEditNow } = useSettingsStore();
  const [tSubSection, setTSubSection] = useState();
  const [forceCloseSection, setForceCloseSection] = useState(false);

  useEffect(() => {
    setContent("change_pass");
    PageTitle('Limadi | Settings');
  }, []);

  return (
    <>
      <div className="pb-5 text-fs28 font-bold">
        Settings
      </div>
      <div className="grid grid-cols-12 gap-2 md:gap-8 2xl:gap-12">
        <div className="col-span-12 lg:col-span-4">
          {/* settings options[push notification, email, change password, lang, terms and condition, faq] */}
          <div className="bg-white p-s10 md:p-5 shadow rounded-xl flex-col">
            <Option
              onClick={() => {
                setContent("change_pass");
                setForceCloseSection(true);
                setTSubSection("");
              }}
              label={t("Change Password")}
              source={RightArrow}
              className={"mb-[5px] mt-[5px]"}
              isActive={isActive === "change_pass"}
            />

            <OptionNested
              forceClose={forceCloseSection}
              mainLabel={t("Terms & Condition")}
              source={RightArrow}
              className={"mb-[5px] mt-[5px]"}
              isActive={isActive === "tc"}
              options={[
                {
                  name: t("customer"),
                  isActive: tSubSection,
                  onClick: function () {
                    setEditNow("")
                    setTSubSection("customer");
                    setContent("tc_customer");
                    setForceCloseSection(false);
                  },
                  description: "",
                  isEditing: false
                },

                {
                  name: t("company"),
                  isActive: tSubSection,
                  onClick: function () {
                    setEditNow("")
                    setTSubSection("company");
                    setContent("tc_company");
                    setForceCloseSection(false);
                  },
                  description: "",
                  isEditing: false
                },

                {
                  name: t("driver"),
                  isActive: tSubSection,
                  onClick: function () {
                    setEditNow("")
                    setTSubSection("driver");
                    setContent("tc_driver");
                    setForceCloseSection(false);
                  },
                  description: "",
                  isEditing: false
                },
              ]}
            />
            <Option
              onClick={() => {
                setContent("admin_access");
                setForceCloseSection(true);
                setTSubSection("");
                setEditNow("")
              }}
              label={t("Admin Access")}
              source={RightArrow}
              className={"mb-[5px] mt-[5px]"}
              isActive={isActive === "admin_access"}
            />
            <Option
              onClick={() => {
                setContent("faq");
                setForceCloseSection(true);
                setTSubSection("");
              }}
              label={"FAQs"}
              source={RightArrow}
              className={"mt-[5px] "}
              isActive={isActive === "faq"}
            />

          </div>
        </div>
        <div className="col-span-12 lg:col-span-8">
          {/* settings content */}
          <div className="bg-white p-s10 md:p-5 shadow rounded-xl">
            {activeComponent}
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
