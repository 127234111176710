import { t } from 'i18next';
import axios from 'axios';
import create from 'zustand'
import { kuGetShiftRouteList, kuRequestCount } from '../../Utility/url';
import { Toastr,  } from '../../Utility/UtilityFunctions';
import useGeneralStore from './GeneralStore';
import { k_data_set, role } from '../Utility/const';


const { setLoading, setIsLoadingSearch } = useGeneralStore.getState()

const useShiftStore = create((set, get) => ({

    shiftRouteList: [],
    setShiftRouteList: (value) => set({ shiftRouteList: value }),

    showCustomStopModal: false,
    setShowCustomStopModal: (value) => set({ showCustomStopModal: value }),

    customStopModalData: {},
    setCustomStopModalData: (value) => set({ customStopModalData: value }),

}))

//! api related functions


export const getShiftRouteList = async (shift_id, withLoading = true) => {
    const { setShiftRouteList } = useShiftStore.getState();
    if (withLoading) setLoading(true);
    try {
        const res = await axios.get(kuGetShiftRouteList, { params: { shift_id: shift_id } });
        console.log('getShiftRouteList: ', res.data);

        if (res?.data?.success) {
            setShiftRouteList(res?.data?.data);
            if (withLoading) setLoading(false)
        }
        else {
            Toastr({ message: res?.data?.message });
            if (withLoading) setLoading(false)
        }
        return true;
    } catch (err) {
        Toastr(({ message: t("An error occurred!") }))
        if (withLoading) setLoading(false)
        console.log('getShiftRouteList: ', err)
        return false;
    }
}













export default useShiftStore;