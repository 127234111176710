import React from 'react'
import { useTranslation } from 'react-i18next';
import { formatDate, formatDateTime, formatTime } from '../../../../../../Utility/UtilityFunctions';
import CommonModal from '../../../../../../Components/Modal/CommonModal';
import useShiftStore from '../../../../../../App/stores/ShiftStore';
import CommonViewComponent from '../../../../../../Components/CommonViewComponent';
import useGeneralStore from '../../../../../../App/stores/GeneralStore';
import ImageViewerModal from '../../../../Common/RequestSection/modal/ImageViewerModal';

const CustomStopDetails = () => {
    const { t } = useTranslation();
    const { showCustomStopModal, setShowCustomStopModal, customStopModalData } = useShiftStore();
    const { setImageViewerModalUrl,setShowImageViewerModal, showImageViewerModal } = useGeneralStore();
    const defineStatus = (status) => {
        const statusMappings = {
            'complete': 'completed',
            'delivered': 'completed',
            'not_delivered': 'not completed',
            'hold': 'on hold',
            'init': 'not optimized',
            'ongoing': 'ongoing',
            'optimized': 'optimized',
        };

        if (!!status) status = statusMappings[status];
        else status = 'not started';

        // Capitalize first letter
        return status?.charAt(0).toUpperCase() + status?.slice(1);
    }

    const isShowDriverComment = (status) => {
        if (status === "delivered" || status === "complete" || status === "not_delivered")
            return true;
        else return false;
    }

    const getExpectedTime = () => {
        const date = customStopModalData?.approx_time?.split(" ")?.[0]
        const time = customStopModalData?.approx_time?.split(" ")?.[1]

        return formatDateTime(date, time, true)
    }

    const getCompletedTime = () => {
        const date = customStopModalData?.driver_complete_time?.split(" ")?.[0]
        const time = customStopModalData?.driver_complete_time?.split(" ")?.[1]

        return formatDateTime(date, time, true)
    }

    return (
        <CommonModal
            showModal={showCustomStopModal}
            setShowModal={setShowCustomStopModal}
            modalTitle={t('Custom Stop Details')}
            mainContent={
                <div
                    onClick={() => { console.log('customStopModalData: ', customStopModalData); }}
                    className='pt-5 space-y-5'>
                        <div className='flex w-full justify-between'>
                        <CommonViewComponent capitalizedData={true} labelText={t('Status')}
                            value={defineStatus(customStopModalData?.status, !!customStopModalData?.approx_time) ?? " "} />
                        {
                            (customStopModalData?.status !== "delivered" && customStopModalData?.status !== "not_delivered" && customStopModalData?.status === "optimized") || customStopModalData?.status === "ongoing"
                                ?
                                <p className='font-normal break-all text-fs14 min-h-4 text-cGray800'>{`Exp. Complete: ${getExpectedTime()}`}</p>
                                : 
                                customStopModalData?.status === "delivered" ? <p className='font-normal break-all text-fs14 min-h-4 text-cGray800'>{`Completed: ${getCompletedTime()}`}</p> 
                                : customStopModalData?.status === "not_delivered" ? <p className='font-normal break-all text-fs14 min-h-4 text-cGray800'>{`Not Completed: ${getCompletedTime()}`}</p> 
                                : <></>
                        }
                    </div>
                    <CommonViewComponent capitalizedData={true} labelText={t('Stop Type')} value={'Custom'} />
                    <CommonViewComponent capitalizedData={false} labelText={t('Title')} value={customStopModalData?.title ?? " "} />
                    <CommonViewComponent capitalizedData={false} labelText={t('Date')} value={(formatDate(customStopModalData?.date) ?? " ")} />
                    <CommonViewComponent capitalizedData={false} labelText={t('Time Range')} value={(formatTime(customStopModalData?.start_time) ?? " ") + ' - ' + formatTime(customStopModalData?.end_time) ?? "--"} />

                    <CommonViewComponent capitalizedData={false} labelText={t('Duration (min)')} value={customStopModalData?.duration ?? "No duration"} />
                    <CommonViewComponent capitalizedData={false} labelText={t('Address')} value={customStopModalData?.address ?? "No address"} />
                    <CommonViewComponent capitalizedData={false} labelText={t('Floor')} value={customStopModalData?.floor_number ?? "No floor number"} />

                    <CommonViewComponent capitalizedData={false} labelText={t('Comment')} value={customStopModalData?.comment ?? "No comment"} />
                    {customStopModalData?.attachment ?
                        <div>
                            <div className={`capitalize text-[#939699]`}>Attachment</div>
                            <div className='text-fs14 font-fw400 text-[#2257AA] underline cursor-pointer'
                                onClick={() => {
                                    setImageViewerModalUrl(customStopModalData?.attachment);
                                    setShowImageViewerModal(true);
                                }}>
                                {t('Attachment')}
                            </div>
                        </div>
                        : ''
                    }


                    {(customStopModalData?.status === "delivered" || customStopModalData?.status === "not_delivered" || customStopModalData?.status === "complete")
                        ?
                        <>

                            <div className={`sub-title capitalize flex flex-row justify-between space-x-4 items-center w-full text-fs16 font-fw500`}>
                                <div className='text-cGray800'> {t("Completion Details")} </div>
                            </div>

                            {isShowDriverComment(customStopModalData?.status) && <CommonViewComponent
                                labelText={t('Driver Comment')}
                                value={customStopModalData?.driver_comment ?? 'No comment'}
                                className='my-[20px]'
                            />}

                            <div className='flex flex-col items-start justify-start gap-5 my-s20'>

                            {
                                customStopModalData?.driver_attachment &&
                                <div>
                                    <div className={`capitalize text-[#939699]`}>Driver Attachment</div>
                                    <div className='text-fs14 font-fw400 text-[#2257AA] underline cursor-pointer'
                                        onClick={() => {
                                            setImageViewerModalUrl(customStopModalData?.driver_attachment);
                                            setShowImageViewerModal(true);
                                        }}>
                                        {t('Attachment')}
                                    </div>
                                </div>
                            }
                            {
                                customStopModalData?.driver_signature &&
                                <div>
                                    <div className={`capitalize text-[#939699]`}>Driver Signature</div>
                                    <div className='text-fs14 font-fw400 text-[#2257AA] underline cursor-pointer'
                                        onClick={() => {
                                            setImageViewerModalUrl(customStopModalData?.driver_signature);
                                            setShowImageViewerModal(true);
                                        }}>
                                        {t('Signature')}
                                    </div>
                                </div>
                            }
                            </div>
                        </>
                        : <></>
                    }

                    {showImageViewerModal ? <ImageViewerModal /> : ""}
                </div>
            }
        />
    )
}

export default CustomStopDetails