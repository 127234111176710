import { t } from 'i18next';
import { useContext } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import CommonButtonOutlined from '../../../../../../Components/Button/CommonButtonOutlined';
import CircularProgressBar from '../../../../../../Components/CircularProgressBar';
import { ShiftPlannerContext } from '../../../../../../Context/ShiftPlannerContext';
import { iRefresh } from '../../../../../../Utility/Sources';
import useDriverStore from '../../../../../../App/stores/DriverStore';

const ShiftPlannerBar = () => {

  const location = useLocation();
  const { driver_id } = useParams();

  const {
    isShiftHistory,
    shift,
    isLoading,
    setIsShiftDetailsData,
    getShiftHistoryList,
    getShiftPlannerList,
    isCreatingShift,
    setClearOnSubmitShift,
    setIsShiftHistory,
    setIsShiftDetailsID,
    setStartDate,
    setEndDate,
    setStartTimeValue,
    setEndTimeValue,
    setCommentValue,
    setUpdateShift,
    setSearchKey,
    setChipData,
    onResetFilterHandler,
    setLicensePlateValue
  } = useContext(ShiftPlannerContext);

  const {driverDetails}=useDriverStore();

  const refreshFoo = async () => {
    setSearchKey("");
    onResetFilterHandler();
    setLicensePlateValue("")

    //await getShiftPlannerList(driver_id, true,driverDetails?.company.id);
    if (isCreatingShift) {
      setStartDate("");
      setEndDate("");
      setStartTimeValue("");
      setEndTimeValue("");
      setCommentValue("");
      setClearOnSubmitShift(true);
    }
    setUpdateShift({});

    setIsShiftDetailsID(null);
    setIsShiftDetailsData({});

    if (window.location.pathname === ("/users/drivers/details/" + driver_id + "/shift-planner/shift-history")) {
      getShiftHistoryList(driver_id, true,driverDetails?.company?.id);
    } else {
      getShiftPlannerList(driver_id, true,driverDetails?.company?.id);
    }
    setChipData({});
  }

  return (
    <>
      {/* starts::title bar */}

      <div className="flex flex-row justify-between card">
        {/* bar title */}
        <div className="flex flex-row items-start ">

          {isShiftHistory ?
            <div className="mr-5 text-2xl font-bold"> {t("Shift History")} ({shift.length && shift.length}) </div>
            :
            <div className="text-2xl font-bold mt-[0px] mr-5">  {t("Shifts")} ({shift?.length ?? 0}) </div>
          }

          {/* refresh icon button */}
          <div>
            {!isLoading ?
              <img
                onClick={refreshFoo}
                src={iRefresh}
                alt="refresh-icon"
                className="h-8 cursor-pointer"
              />
              : <CircularProgressBar />
            }
          </div>

        </div>

        <div>
          <div className='flex flex-row justify-end'>
            {/* Shift Details */}
            {location.pathname === "/users/drivers/details/" + driver_id + "/shift-planner/shift-history" ||
              location.pathname === "/users/drivers/details/" + driver_id + "/shift-planner/create-new-shift" ||
              location.pathname === "/users/drivers/details/" + driver_id + "/shift-planner/edit-shift" ?
              <Link className='' to={"/users/drivers/details/" + driver_id + "/shift-planner"} >
                <CommonButtonOutlined
                  btnLabel={t("Shift Details")}
                  onClick={() => {
                    setIsShiftHistory(false);
                    setChipData({});
                  }}
                  type="submit"
                />
              </Link>
              : <> </>
            }
          </div>

          <div className='flex flex-row justify-end w-full space-x-5'>

            {/* Shift History */}
            {/* {location.pathname === "/users/drivers/details/" + driver_id + "/shift-planner" ?
              <Link to={"/users/drivers/details/" + driver_id + "/shift-planner/shift-history"}>
                <CommonButtonOutlined
                  btnLabel={t("Shift History")}
                  onClick={() => {
                    setIsShiftHistory(true);
                    setIsShiftDetailsData({});
                    setChipData({});
                  }}
                  type="submit"
                />
              </Link>
              : <> </>
            } */}

          </div>
        </div>

      </div>
      {/* ends::title bar */}
    </>
  );
};

export default ShiftPlannerBar;
