/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import {
  IoIosArrowForward,
  IoMdRadioButtonOff,
  IoMdRadioButtonOn
} from "react-icons/io";
import { useParams } from "react-router-dom";
import Input02 from "../../../../../Components/Input/Input02";
import CommonModal from "../../../../../Components/Modal/CommonModal";
import { ShiftPlannerContext } from "../../../../../Context/ShiftPlannerContext";

const SelectDriver = ({
  data_id = null,
  isUnderMaintanance = false,
  onChange,
  disabled = false,
  tempName = "",
  hasManagement = false,
  value = {},
}) => {

  const {
    setSelectedDriver,
    shiftDrivers,
    setDriverUserId,
    clearOnSubmitShift,
    setClearOnSubmitShift,
    setUpdateShift,
    updateShift,
    getShiftCarsDriversList,
    selected, 
    setSelected,
    driverName, 
    setDriverName,
    driverUserId
  } = useContext(ShiftPlannerContext);

  // const [show_transportation_modal, setTransportationModal] = useState(false);
  const [show_modal, setShowModal] = useState(false);

  const { company_id } = useParams();

  const transportation_types = [
    {
      id: 1,
      title: "🅰🅽🅾🅽🆈🅼🅾🆄🆂",
    },
    {
      id: 2,
      title: "A͒N͒O͒N͒Y͒M͒O͒U͒S͒‿✶",
    },
    {
      id: 3,
      title: "✭anonyмoυѕ☆",
    },
    {
      id: 4,
      title: "｡αทøղⓨℳօuร︵⁹",
    },
    {
      id: 5,
      title: "αиσиумσυѕ",
    },
    {
      id: 6,
      title: "︵⁹¹αиőⓝY๖ۣۜmσⓊs̫☞╯",
    },
    {
      id: 7,
      title: "αиσиумσυѕ",
    },
    {
      id: 8,
      title: "A̺͆N̺͆O̺͆N̺͆Y̺͆M̺͆O̺͆U̺͆S̺͆︵²ᵏ³",
    },
    {
      id: 9,
      title: "๖ۣۜa๖ۣۜn๖ۣۜo๖ۣۜn๖ۣۜy๖ۣۜm๖ۣۜo๖ۣۜu๖ۣۜs",
    },
    {
      id: 10,
      title: "ⒶⓃⓄⓃⓎⓂⓄⓊⓈ",
    },
  ];

  const submit = () => {
    const selected_transportation_type = shiftDrivers.find(
      (item) => item.id === selected
    );
    if(hasManagement) {
      onChange(selected_transportation_type);
      setShowModal(false)
      return;
    }
    setDriverName(selected_transportation_type.name);
    setSelectedDriver(selected_transportation_type.name);
    setDriverUserId(selected);
    console.log("driver user id::", selected);
    setUpdateShift({ ...updateShift, driver_user_id: selected });
    setShowModal(false);
  };

  useEffect(() => {
    if (isUnderMaintanance) {
      setSelectedDriver(undefined);
    }
    if (clearOnSubmitShift) {
      setSelected(null);
      setDriverName("");
    }
  }, [clearOnSubmitShift]);

  useEffect(()=> {
    if(hasManagement) {
      setSelected(value?.id)
    }
  }, [])

  const handleClose = () => {
    if(hasManagement) {
      setSelected(value?.id)
    }
    else {
      setSelected(driverUserId);
    }
    setShowModal(false)
  }

  return (
    <>
      <Input02
        onClick={() => {
          if(disabled || isUnderMaintanance) return;
          console.log("clicked");
          setShowModal(true);
          setClearOnSubmitShift(false);
          getShiftCarsDriversList(isUnderMaintanance, false, company_id);
        }}
        name="transport_type"
        value={tempName
              ? tempName : driverName
              ? driverName
              : isUnderMaintanance
                ? ""
                : data_id
                  ? transportation_types[data_id].title
                  : ""
        }
        className={`${disabled || isUnderMaintanance ? "cursor-not-allowed" : "cursor-pointer"} capitalize ${isUnderMaintanance ? "opacity-50" : ""
          }`}
        className2={`${isUnderMaintanance ? "opacity-50" : ""}`}
        label="Select Driver"
        type="text"
        placeholder="Driver Name"
        icon={<IoIosArrowForward className={`h-[70%] ${disabled || isUnderMaintanance ? "cursor-not-allowed" : "cursor-pointer"}`} />}
        is_readonly={true}
        is_disabled={isUnderMaintanance || disabled ? true : false}
      />

      <CommonModal
        showModal={show_modal}
        setShowModal={handleClose}
        modalTitle="Select Driver"
        mainContent={
          <div className="pt-5">
            <div>
              <div className="max-h-[40vh] overflow-auto">
                {shiftDrivers?.length ? (
                  shiftDrivers?.map((item) => (
                    <div
                      key={item?.id}
                      onClick={() => setSelected(item?.id)}
                      className={`${selected === item?.id || item?.name === tempName
                        ? "ring-1 ring-cSecondary"
                        : ""
                        } border border-transparent flex justify-between items-center py-3 m-[2px] px-4 mb-3 cursor-pointer rounded-lg bg-cListItem text-gray-600 font-normal`}
                    >
                      <span className="max-w-[30vw] truncate">
                        {item?.name}
                      </span>
                      {selected === item?.id ? (
                        <IoMdRadioButtonOn className="text-xl gs-text-secondary" />
                      ) : (
                        <IoMdRadioButtonOff className="text-xl" />
                      )}
                    </div>
                  ))
                ) : (
                  <div className="flex flex-row items-center justify-center h-full font-semibold gs-text-placeholder italic pb-5">
                    No data found !
                  </div>
                )}
              </div>
            </div>

            <div className="mt-4 w-full flex justify-center">
              <button
                onClick={submit}
                type="button"
                className={`mr-4 inline-flex justify-center px-4 py-2 w-36 text-sm font-medium text-cWhite ${selected !== null
                  ? "bg-limadi"
                  : "gs-placeholder cursor-not-allowed"
                  } border border-transparent rounded-br5`}
                disabled={selected === null}
              >
                Submit
              </button>
            </div>
          </div>
        }
      />


    </>
  );
};

export default SelectDriver;
