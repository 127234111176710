import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import AddBtnOutlined from "../Button/AddBtnOutlined";
import CommonSearchBox from "../Input/CommonSearchBox";
import AutoPaginate from "./AutoPaginate";
import LimadiSearchBox from "../Input/LimadiSearchBox";

export default function CommonTable({
  onSearchClear = () => { },
  withClearSearch = false,
  outerPadding = "p-s20",
  secondaryPaddingBottom = "pb-0",
  titleComponent,
  tableTitle = "",
  secondaryTitle = "",
  headers,
  items,
  pagination = true,
  addItemModal,
  addItemTitle,
  addItemFunction,
  searchValue,
  searchOnChange,
  shoSearchBox = true,
  seeAllText,
  seeAllLink = "#",
  showingFrom = 0,
  showingTo = 0,
  totalResult = 0,
  pagesArray = [],
  showChip = false,
  chipArray = [
    {
      title: "Option 01",
      selected: false,
      action: () => {
        console.log("Option 01");
      },
    },
    {
      title: "Option 02",
      selected: true,
      action: () => {
        console.log("Option 02");
      },
    },
    {
      title: "Option 03",
      selected: false,
      action: () => {
        console.log("Option 03");
      },
    },
    {
      title: "Option 04",
      selected: false,
      action: () => {
        console.log("Option 04");
      },
    },
    {
      title: "Long Option 05",
      selected: false,
      action: () => {
        console.log("Option 05");
      },
    },
    {
      title: "Long Option 06",
      selected: false,
      action: () => {
        console.log("Option 06");
      },
    },
  ],
  currentPageNumber,
  prevPageUrl,
  nextPageUrl,
  paginationOnClick,
  showPagination = true,
  showPageCountText = true,
  paginationObject,
  search_loading = false,
  topRightComponent,
  filterFunction = false,
  filterComponent
}) {
  const { t } = useTranslation();

  useEffect(() => {
    // console.log("COMMON TABLE => pagesArray:::", pagesArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={`${outerPadding} bg-white text-cTextBlack rounded-br5 w-full`} >

        {/* blue: table upper side components */}
        <div className={`flex justify-between items-center relative ${secondaryPaddingBottom}`}>

          {/* green: table left top */}
          <div className="flex flex-col ">

            {/* idea: table title to sho... */}
            {tableTitle ? (
              <div className="pb-5 capitalize text-fs20 font-fw600">
                {tableTitle}
              </div>
            ) : (
              ""
            )}

            {/* idea: left side top custom component */}
            {topRightComponent ?
              <div className="pb-5">
                {topRightComponent}
              </div>
              : ""}

            {/* idea: chip to show... */}
            {showChip ?
              <div className={`flex space-x-5 overflow-x-auto mb-s10 ${shoSearchBox ? "max-w-[40vw]" : "max-w-[55vw]"}`}>
                {
                  chipArray.map((item, index) =>
                    <div
                      key={index}
                      onClick={item.action}

                      className={`
                    mb-s10 px-s10 min-w-[150px] h-s36 flex items-center justify-center rounded-full cursor-pointer select-none text-fs16 font-fw600
                      ${item.selected ? "bg-cBrand text-white" : "bg-white text-cChipText border border-cChipBorder hover:text-white  hover:bg-cBrand"}
                    `}

                    >{item.title}</div>
                  )}
              </div> : ""}
          </div>

          {/* green: only table right top component */}
          <div className="flex flex-col justify-start h-full">

            {/* idea: right side top title or custom component */}
            {titleComponent ? (
              <div className="bg-cWhite">{titleComponent}</div>
            ) : (
              ""
            )}

            {/* idea: add item button text */}
            {addItemTitle ? (
              <>
                <div className="pl-5"></div>
                <AddBtnOutlined
                  btnLabel={addItemTitle}
                  onClick={() => addItemFunction(true)}
                />
              </>
            ) : (
              ""
            )}

            {/* idea: see all text custom with link */}
            {seeAllText ? (
              <div className="absolute w-full pb-5 text-right top-s10 right-s10">
                <Link
                  to={seeAllLink}
                  className="text-fs16 font-fw600 text-cBrand"
                >
                  {seeAllText}
                </Link>
              </div>
            ) : (
              ""
            )}

            {/* idea: search box */}
            {
              filterFunction === false ?
                (shoSearchBox === true ? (
                  <div className="self-end pb-5">
                    <LimadiSearchBox
                      value={searchValue}
                      onChange={searchOnChange}
                      search_loading={search_loading}
                      onSearchClear={onSearchClear}
                      withClearSearch={withClearSearch}
                    />
                  </div>
                ) : (
                  ""
                )) :
                <div className="flex">
                  <div className="pr-s10">
                    {(shoSearchBox === true ? (
                      <div className="self-end pb-5">
                        <LimadiSearchBox
                          value={searchValue}
                          onChange={searchOnChange}
                          onSearchClear={onSearchClear}
                          withClearSearch={withClearSearch}
                          search_loading={search_loading}
                        />
                      </div>
                    ) : (
                      ""
                    ))}
                  </div>
                  <div>{filterComponent}</div>
                </div>
            }

            {/* idea: secondary title text right top corner */}
            {secondaryTitle ?
              <>
                <div className="pr-1 font-semibold capitalize text-fs28">
                  {secondaryTitle}
                </div>
              </>
              : ""}
          </div>
        </div>

        {/* blue: main table ui */}
        {/* green: Headers... */}
        <div className="lg:overflow-visible overflow-x-auto lg:max-w-full max-w-[1024px]">
          <table className="w-full table-border-outer">
            <thead className="">
              <tr>
                {headers.map(({ index, name, className }) => {
                  return (
                    <th
                      key={index}
                      className={`
                      ${className}
                      border-collapse text-center capitalize text-fs16 font-fw700 py-s10
                      ${index === headers.length ? "border-r-[0px]" : "border-r-[1px]"}
                    `}
                    >
                      {name}
                    </th>
                  );
                })}
              </tr>
            </thead>

            {/* green: table body rows.. */}
            <tbody className="border-collapse border-[1px]"> {items}</tbody>
          </table>
        </div>

        {/* blue: Pagination goes here ! */}
        {pagination ? (
          <div className={`flex justify-between items-center ${showPagination || showPageCountText ? "h-s60 pt-5" : ""}`} >
            {showPageCountText ? (
              totalResult > 0 ?
                <div className="text-sm">
                  {t("Showing")} {showingFrom} {t("to")} {showingTo}, {t("out of")} {totalResult} {t("results.")}
                </div>
                :
                <div className="text-sm">
                  No results available.
                </div>
            ) : (
              ""
            )}

            {showPagination ? (
              <div className="">
                <AutoPaginate
                  paginationObject={paginationObject}
                  paginationOnClick={paginationOnClick}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
