import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useLicenseStore, { getApplicationDetails } from '../../../App/stores/LicenseStore';
import CommonButton from '../../../Components/Button/CommonButton';
import TitleBar from '../../../Components/Common/TitleBar';
import CommonImage from '../../../Components/Image/CommonImage';
import BackLinks from '../../../Components/Layout/BackLinks';
import CommonTable from '../../../Components/Table/CommonTable';
import NoDataRow from '../../../Components/Table/NoDataRow';
import { CarManagementContext } from '../../../Context/CarManagementContext';
import { iBellWhite, iDummyCar, iEditLicense, iWarningRed } from '../../../Utility/Sources';
import { BaseUrlSrc } from '../../../Utility/url';
import { formatDate } from '../../../Utility/UtilityFunctions';
import LicenseHistoryRows from './LicenseHistoryRows';
import useGeneralStore from '../../../App/stores/GeneralStore';

function LicenseDetails() {
    const { getAllLicenseList } = useContext(CarManagementContext);

    const { setShowAcceptModal, setShowAdminApplyLicense, setShowRejectModal, licenseDetails, setLicenseApplicationID, setShowDeactivateLicense } = useLicenseStore();

    const { application_id } = useParams();

    const navigateTo=useNavigate();

    const header = [
        { index: 1, name: ("license ") },
        { index: 2, name: ("price") },
        { index: 3, name: ("duration (Days)") },
        { index: 4, name: ("start date") },
        { index: 5, name: ("end date") },
        { index: 6, name: ("status") },
    ];

    useEffect(() => {
        console.log("ID:: ", application_id);
        getApplicationDetails(application_id);
        window.scrollTo(0, 0);

        setLicenseApplicationID(application_id);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    console.log("licenseDetails?.name",licenseDetails)

    return (
        <>
            <BackLinks
                linksArray={[
                    { label: "License Applications", linkTo: "/license/application" },
                    { label: "Details", linkTo: "" }
                ]}
            />

            <TitleBar label={"License Details"} showLoadingBtn={false} hideCounter={true} />

            <div className='p-5 bg-white shadow-sm rounded-br20'>
                <div className="flex items-start justify-between space-x-5 h-fit">

                    {/* blue: left items */}
                    <div className={`relative p-5 w-2/3 bg-cMessageTitleBG rounded-br10 h-[235px]`}>
                        {/* todo: edit license details */}
                        {(licenseDetails?.license_status === "active" || licenseDetails?.license_status === "expire_warning") ? <div onClick={() => setShowAcceptModal(true)} className="absolute cursor-pointer right-5">
                            <img src={iEditLicense} alt="" />
                        </div> : ""}
                        <div className="flex items-center">
                            <CommonImage src={licenseDetails?.image} alt="" className='w-[88px] h-[88px] rounded-full  object-cover' />

                            {/* car description */}
                            <div className='font-medium pl-s10'>
                                <div className="font-semibold text-fs24 overflow-clip break-all">
                                    {licenseDetails?.name ? licenseDetails?.name : "No name"}
                                </div>

                                <div className="text-cLightBlack text-fs12">
                                    {licenseDetails?.car_license_plate_number ? licenseDetails?.car_license_plate_number : "No license plate number"}
                                </div>

                                <div className="text-cLightBlack text-fs12">
                                    License Start: {licenseDetails?.license_start ? formatDate(licenseDetails?.license_start) : "No start date"}
                                </div>

                                <div className="font-semibold capitalize text-cMainBlack text-fs12">
                                    License Status: {licenseDetails?.license_status === "expire_warning" ? 
                                    "Expire soon": licenseDetails?.license_status === "no_license" ? "No license": licenseDetails?.license_status }
                                </div>

                                <div 
                                onClick={()=>{navigateTo(`/users/companies/details/${licenseDetails?.company?.id}`)}} 
                                className="font-semibold capitalize cursor-pointer text-cMainBlack text-fs12">
                                    Company Name: {licenseDetails?.company?.name ? licenseDetails?.company?.name : "No company name"}
                                </div>
                            </div>
                        </div>

                        <div className="flex items-center">
                            {/* red: DEPRECATED NOW */}
                            {/* <div className="flex flex-col justify-center items-center pt-5 w-[88px]">
                                <CommonSwitch
                                    checked={licenseDetails?.license?.is_active}
                                    onClick={() => {
                                        console.log("CLICK!!!!!");
                                        if (licenseDetails?.license?.is_active===true) {
                                            setShowDeactivateLicense(true);
                                        }
                                    }}
                                />
                                <div className="text-base">
                                    {licenseDetails?.license?.is_active ? (
                                        <span className="text-cPrimary">Active</span>
                                    ) : (
                                        <span className="text-cRed">Deactivated</span>
                                    )}
                                </div>
                            </div> */}

                            {/* y: warning area */}
                            {licenseDetails?.license_status === "expire_warning" &&
                                <div className='flex max-w-[330px] pt-s20'>
                                    <img src={iWarningRed} alt="" />
                                    <div className='text-cRed text-fs12 pl-s10'>
                                        The license is going to be expired in {licenseDetails?.expire_in} days. Please purchase / Renew license to continue.
                                    </div>
                                </div>}

                        </div>
                        {/* {licenseDetails?.license_status === "expire_warning" && <div className="pt-s5">
                            <CommonButton
                                colorType='danger'
                                width='w-[200px]'
                                btnLabel='Send Notification'
                                fullRounded={true}
                                leftIcon={
                                    <>
                                        <img src={iBellWhite} alt="" />
                                    </>
                                }
                            />
                        </div>} */}

                    </div>

                    {/* blue: right items */}
                    <div className={`overflow-hidden relative w-1/3 border p-s15 ${licenseDetails?.license_status === "no_license" ? "border-white" : "border-cBrand"} h-[235px] rounded-br10`}>

                        {/* 
                                show only if status: expired, no_license, rejected
                        */}
                        {/* g: overlay */}
                        {(licenseDetails?.license_status === "expired" || licenseDetails?.license_status === "no_license" || licenseDetails?.license_status === "rejected") &&
                            <>
                                <div className="absolute top-0 left-0 z-20 w-full h-full backdrop-blur-sm"></div>
                                <div className={`absolute w-full h-full top-0 left-0 rounded-br10 z-30 text-white ${(licenseDetails?.license_status === "expired" || licenseDetails?.license_status === "no_license" || licenseDetails?.license_status === "rejected") ? "bg-black opacity-80 backdrop-blur-sm" : ""}`}>
                                    {(licenseDetails?.license_status === "expired" || licenseDetails?.license_status === "no_license" || licenseDetails?.license_status === "rejected") &&
                                        <div className='flex flex-col items-center justify-center w-full h-full opacity-100'>
                                            <div className='pb-s10 text-fs20 font-fw600'>
                                                No Active License
                                            </div>
                                            <CommonButton
                                                fullRounded={true}
                                                colorType="white"
                                                btnLabel='Apply For License'
                                                width='w-[180px]'
                                                dropShadow={true}
                                                onClick={async () => {
                                                    await getAllLicenseList();
                                                    setShowAdminApplyLicense(true);
                                                }}
                                            />
                                        </div>
                                    }
                                </div>
                            </>
                        }
                        <div className="font-semibold text-fs20">
                            {licenseDetails?.license?.number ? licenseDetails?.license?.number : "No license number"}
                        </div>

                        <div className="text-2xl font-semibold text-cBrand pt-s10">
                            DKK {licenseDetails?.license?.price ? licenseDetails?.license?.price.toLocaleString("da-DK") : "0"}
                        </div>

                        <div className="text-cBodyText text-fs14 pt-s5">
                            Expiry Date: {licenseDetails?.license_end ? formatDate(licenseDetails?.license_end) : "No expiry date"}
                        </div>

                        <div className="font-semibold py-s10 text-fs14">
                            Duration: {licenseDetails?.license?.duration ? (licenseDetails?.license?.duration + " Days") : "No duration"}
                        </div>

                        <div className="text-cBodyText text-fs14 overflow-y-auto max-h-[60px]">
                            {licenseDetails?.license?.description ? licenseDetails?.license?.description : "No description"}
                        </div>
                    </div>
                </div>


                {/* green: buttons area */}
                {/* 
                    license action flow:
                        no_license / pending > accept / reject > active / expire_warning > expired
                */}
                <div className={`flex justify-between pt-5 w-full ${licenseDetails?.license_status === "pending" ? "flex-row" : "flex-row-reverse"}`}>

                    {licenseDetails?.license_status === "pending" && <CommonButton btnLabel='Reject' colorType='danger' onClick={() => setShowRejectModal(true)} />}
                    {
                        licenseDetails?.license_status === "pending" ?
                            <CommonButton btnLabel='Accept' onClick={() => setShowAcceptModal(true)} />
                            : licenseDetails?.license_status === "processing" ?
                                <CommonButton btnLabel='Activate' onClick={() => setShowAcceptModal(true)} />
                                : ""
                    }

                </div>

                <div className="pt-5">
                    <hr />
                </div>

                {/* License History */}
                <CommonTable
                    tableTitle='License History'
                    showPageCountText={false}
                    shoSearchBox={false}
                    showPagination={false}
                    headers={header}

                    items={
                        <>
                            {
                                licenseDetails?.license_application?.length > 0 ? licenseDetails.license_application.map((item, index) =>

                                    <LicenseHistoryRows key={index} data={item} />
                                )
                                    : <NoDataRow columnNumber={6} />
                            }
                        </>
                    }
                />
            </div>

        </>
    )
}

export default LicenseDetails