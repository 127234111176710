import React from 'react'
import CommonImage from '../../../Components/Image/CommonImage'
import { formatDate } from '../../../Utility/UtilityFunctions'

function NoBidActionRow({ data, num }) {
  return (
    <tr className='hover:bg-cGridView'>
      <td className='border-collapse border-[1px] border-cNmSelect py-s10 text-center px-s10'>
        {data?.request_id}
      </td>

      <td className='border-r-[1px] border-b-[1px] border-cNmSelect border-collapse p-s10 text-left max-w-[150px] min-w-[150px] truncate'>
        <div className='flex items-center'>
          <div className='mx-s5 max-w-[40px] min-w-[40px]'>
            <CommonImage className='rounded-full w-s40 h-s40 grow-0' src={data?.creator?.image} alt="" />
          </div>
          <div className='capitalize items-center pl-s10 truncate'>{data?.creator?.name ? data?.creator?.name : ''}</div>
        </div>
      </td>

      <td className='border-collapse border-[1px] border-cNmSelect py-s10 text-left'>
        <span className="pl-s10">{data?.title ? data?.title : ""}</span>
      </td>

      <td className='border-collapse border-[1px] border-cNmSelect py-s10 text-left'>
        <span className="pl-s10">{data?.creator?.email ? data?.creator?.email : ""}</span>
      </td>

      <td className='border-collapse border-[1px] border-cNmSelect py-s10 text-center'>
        <span className="pl-s10">{data?.creator?.last_active_at ? formatDate(data?.creator?.last_active_at) : ""}</span>
      </td>

      <td className='border-collapse border-[1px] border-cNmSelect py-s10 text-center'>
        <span className="pl-s10">{data?.biddings_count ? data?.biddings_count : ""}</span>
      </td>

    </tr>
  )
}

export default NoBidActionRow