import React, { useContext, useEffect } from 'react'
import useShiftStore, { getShiftRouteList } from '../../../../../../App/stores/ShiftStore'
import { ShiftPlannerContext } from '../../../../../../Context/ShiftPlannerContext';
import CommonStopsList from '../../../../../../Components/CommonStopsList';
import { calculateDistance, formatDate } from '../../../../../../Utility/UtilityFunctions';
import SecondaryTitle from '../../../../../../Components/SecondaryTitle';
import { useTranslation } from 'react-i18next';
import { iOnHoldIcon } from '../../../../../../Utility/Sources';

function RouteOverview() {
    const { isShiftDetailsData } = useContext(ShiftPlannerContext);
    const { shiftRouteList } = useShiftStore();
    useEffect(() => {
        handleRouteFetch()
    }, [isShiftDetailsData])

    const handleRouteFetch = async() => {
        if (isShiftDetailsData?.id) {
            await getShiftRouteList(isShiftDetailsData?.id, true)
        }
    }

    const { t } = useTranslation();


    return (
        <div>
            {(shiftRouteList?.length > 0 && (isShiftDetailsData?.status === 'ongoing' || isShiftDetailsData?.status === 'break')) ?
                <>
                    <div className='pt-5'>
                        <SecondaryTitle title={`${t("Route Overview")} (` + (shiftRouteList?.length ?? 0) + ')'} />
                    </div>
                    <div className='w-full pb-10 space-y-5'>
                        {
                            shiftRouteList?.length > 0 ? shiftRouteList?.map((item, index) =>
                                <div className="">
                                    <CommonStopsList
                                        isUnOptimized={item?.q_index && item?.status === 'un_optimized' || item?.status === 'init'}
                                        key={index}
                                        totalKm={item?.status === 'hold' ? "0 KM" : calculateDistance(item?.distance)?.distance +
                                            ' ' + calculateDistance(item?.distance)?.unit}
                                        time={item?.status === 'hold' ? "00:00" : item?.approx_time ?? "00:00"}
                                        count={item?.q_index ?? "NA"}
                                        showImage={item?.status === "hold"}
                                        imageSrc={iOnHoldIcon}
                                        routeType={item?.stop_type}
                                        title={item?.type === 'default' ? `Request ID - ${item?.request_id}` : item?.title}
                                        subTitleOne={ item?.stop_details?.duration ? `${(item?.stop_details?.products?.length ?? 0) + ' Packages'}, Duration: ${item?.stop_details?.duration} mins` : (item?.stop_details?.products?.length ?? 0) + ' Packages'}
                                        subTitleTwo={item?.stop_details?.date ? `${formatDate(item?.stop_details?.date)}, ${item?.stop_details?.start_time?.slice(0,5)} - ${item?.stop_details?.end_time?.slice(0,5)}` : null}
                                        subTitleThree={item?.address ?? "No address"}
                                        title_max_width='300'
                                        accentType={item?.status === 'hold' ? 'on_hold' : 'transparent'}

                                        accentBorderType={
                                            item?.status === 'hold' ? 'on_hold'
                                                : item?.status === 'on_going' ? 'warning'
                                                    : item?.status === 'init' ? 'warning'
                                                        : item?.q_index && item?.status === 'un_optimized' ? 'danger'
                                                            : item?.status === 'complete' && item?.stop_status === 'not_delivered' ? 'base'
                                                                : item?.status === 'complete' && item?.stop_status === 'delivered' ? 'base'
                                                                    : 'transparent'
                                        }

                                        circleColorType={
                                            item?.status === 'hold' || item?.status === 'on_going' ? 'warning'
                                                : item?.status === 'init' ? 'warning'
                                                    : item?.q_index && item?.status === 'un_optimized' ? 'danger'
                                                        : item?.status === 'complete' && item?.stop_status === 'not_delivered' ? 'base'
                                                            : item?.status === 'complete' && item?.stop_status === 'delivered' ? 'base'
                                                                : 'transparent'
                                        }

                                        topRightComponent={
                                            item?.q_index && item?.status === 'un_optimized' ? 'Not optimized'
                                                : item?.status === 'init' ? 'Not optimized'
                                                    : item?.status === 'complete' && item?.stop_status === 'delivered' ? 'Completed'
                                                        : item?.status === 'complete' && item?.stop_status === 'not_delivered' ? 'Completed'
                                                            : item?.status === 'on_going' ? 'Ongoing'
                                                                : item?.status === 'hold' ? 'On Hold'
                                                                    : item?.status
                                        }

                                        topRightComponentType={
                                            item?.status === 'hold' ? 'on_hold'
                                                : item?.status === 'on_going' ? 'warning'
                                                    : item?.status === 'init' ? 'warning'
                                                        : item?.q_index && item?.status === 'un_optimized' ? 'danger'
                                                            : item?.status === 'complete' && item?.stop_status === 'not_delivered' ? 'base'
                                                                : item?.status === 'complete' && item?.stop_status === 'delivered' ? 'base'
                                                                    : 'transparent'
                                        }
                                    />
                                </div>
                            )
                                : ""
                        }

                    </div>
                </>
                : ""
            }
        </div>
    )
}

export default RouteOverview
