import axios from "axios";
import { t } from "i18next";
import create from "zustand";
import ChangePassword from "../../Pages/Settings/Components/ChangePassword";
import FAQ from "../../Pages/Settings/Components/FAQ";
import LanguageSetup from "../../Pages/Settings/Components/LanguageSetup";
import TermsAndCondition from "../../Pages/Settings/Components/TermsAndCondition";
import {
  kuAddFAQ,
  kuAddTermsAndCondition,
  kuChangePassword,
  kuDeleteFAQ,
  kuGetAdminAccess,
  kuGetAllFAQ,
  kuGetTermsAndCondition,
  kuToggleFAQ,
  kuUpdateAdminAccess,
  kuUpdateFAQ,
  kuUpdateTermsAndCondition,
} from "../../Utility/url";
import { Toastr } from "../../Utility/UtilityFunctions";
import useGeneralStore from "./GeneralStore";
import AdminAccessInfo from "../../Pages/Settings/Components/AdminAccessInfo";

// const { t } = useTranslation();

const useSettingsStore = create((set) => ({
  appLanguage: [
    { name: "English", code: "en" },
    { name: "Danish", code: "da" },
  ],

  activeLang: "",
  setActiveLanguage: (value) => set({ activeLang: value }),

  lang_code: "",
  setLang_code: (lang_code) => set({ lang_code }),

  isActive: "",
  setIsActive: (value) => set({ isActive: value }),

  selectedFiler: false,
  setSelectedFiler: (value) => set({ selectedFiler: value }),

  faqToggle: false,
  setFAQToggle: (value) => set({ faqToggle: value }),

  allFaqList: [],
  setAllFaqList: (value) => set({ allFaqList: value }),

  activeComponent: "",
  setActiveComponent: (value) => set({ activeComponent: value }),

  termsGdprRole: "",
  setTermsGdprRole: (value) => set({ termsGdprRole: value }),

  termsGdprData: {
    gdpr: {
      id: "",
      app_type: "",
      type: "",
      description: "",
    },
    terms_condition: {
      id: "",
      app_type: "",
      type: "",
      description: "",
    },
  },
  setTermsGdprData: (value) => set({ termsGdprData: value }),

  editTermsGdprDescription: "",
  setEditTermsGdprDescription: (value) => set({ editTermsGdprDescription: value }),

  adminAccessData: null,
  setAdminAccessData: (value) => set({ adminAccessData: value }),

  editAdminAccessData: null,
  setEditAdminAccessData: (value) => set({ editAdminAccessData: value }),

  updateTermsData: (value) => set({ editTermsGdprDescription: value }),
  updateGdprData: (value) =>
    set((state) => ({
      termsGdprData: {
        ...state.termsGdprData,
        gdpr: {
          ...state.termsGdprData.gdpr,
          description: value,
        },
      },
    })),

    editNow: "",
    setEditNow: (value) => set({ editNow: value }),
}));
export default useSettingsStore;

// green: settings content setup of right side
export const setContent = (type) => {
  // cpForm, submitChangePassword, handleCpChange, cpCanSubmit, setCpSubmit,
  const { setIsActive, setActiveComponent } = useSettingsStore.getState();
  switch (type) {
    case "language":
      setIsActive("language");
      setActiveComponent(<LanguageSetup />);
      break;
    case "change_pass":
      setIsActive("change_pass");
      setActiveComponent(<ChangePassword />);
      break;
    case "tc_customer":
      setIsActive("tc_customer");
      setActiveComponent(<TermsAndCondition title={t("customer")} />);
      break;
    case "tc_driver":
      setIsActive("tc_driver");
      setActiveComponent(<TermsAndCondition title={t("driver")} />);
      break;
    case "tc_company":
      setIsActive("tc_company");
      setActiveComponent(<TermsAndCondition title={t("company")} />);
      break;
    case "admin_access":
        setIsActive("admin_access");
        setActiveComponent(<AdminAccessInfo title={t("company")} />);
        break;
    case "faq":
      setIsActive("faq");
      setActiveComponent(<FAQ />);
      break;
    default:
      setActiveComponent(<LanguageSetup />);
      break;
  }
};

// blue: faq list
export const getAllFAQ = async () => {
  const { setLoading } = useGeneralStore.getState();
  const { setAllFaqList } = useSettingsStore.getState();

  try {
    setLoading(true);
    const res = await axios.get(kuGetAllFAQ);
    console.log(res.data);
    if (res.data.success) {
      setAllFaqList(res.data.data);
    } else {
      console.log(res.data.message);
      Toastr(res.data.message);
    }
    setLoading(false);
  } catch (err) {
    console.log(err);
    Toastr(t("An error occurred!"));
    setLoading(false);
  }
};

// blue: add faq
export const addFAQ = async (form) => {
  const { setLoading } = useGeneralStore.getState();
  const { setSelectedFiler } = useSettingsStore.getState();

  try {
    setLoading(true);
    const body = {
      title: form.qus,
      description: form.ans,
      app_type: form.type,
    };
    const res = await axios.post(kuAddFAQ, body);
    console.log(JSON.stringify(res.data));
    if (res.data.success) {
      setSelectedFiler("all_faq");
      getAllFAQ();
    } else {
      console.log(res.data.message);
      Toastr(res.data.message);
    }
    setLoading(false);
  } catch (err) {
    console.log(err);
    Toastr(t("An error occurred!"));
    setLoading(false);
  }
};

//   blue: delete faq
export const deleteFAQ = async (item) => {
  const { setLoading } = useGeneralStore.getState();

  console.log("Item id:", item);

  try {
    setLoading(true);
    const body = {
      id: item,
    };
    const res = await axios.post(kuDeleteFAQ, body);
    console.log(JSON.stringify(res.data));
    if (res.data.success) {
      await getAllFAQ();
    } else {
      console.log(res.data.message);
      Toastr(res.data.message);
    }
    setLoading(false);
  } catch (err) {
    console.log(err);
    Toastr(t("An error occurred!"));
    setLoading(false);
  }
};

//   blue: update faq
export const updateFAQ = async (item) => {
  const { setLoading } = useGeneralStore.getState();

  console.log("Item From context:", item);

  try {
    setLoading(true);
    const body = {
      id: item.id,
      title: item.title,
      description: item.description,
      app_type: item.app_type,
    };
    const res = await axios.post(kuUpdateFAQ, body);
    console.log(JSON.stringify(res.data));
    if (res.data.success) {
      await getAllFAQ();
    } else {
      console.log(res.data.message);
      Toastr(res.data.message);
    }
    setLoading(false);
  } catch (err) {
    console.log(err);
    Toastr(t("An error occurred!"));
    setLoading(false);
  }
};

/**
 * Toggles the visibility of an FAQ item.
 *
 * @param {number} id - The ID of the FAQ item to toggle.
 *
 * @returns {Promise<void>}
 */
export const handleFAQToggle = async (id) => {
  const { setLoading } = useGeneralStore.getState();

  try {
    setLoading(true);

    const body = {
      id: id,
    };

    const res = await axios.post(kuToggleFAQ, body);

    if (res.data.success) {
      await getAllFAQ();
      Toastr(res.data.message, "success");
    } else {
      Toastr(res.data.message);
    }

    setLoading(false);
  } catch (err) {
    Toastr(t("An error occurred!"));
  }
};

export const handleAdminAccess = async (appRole = "") => {
  const { setLoading } = useGeneralStore.getState();
  const { setAdminAccessData } = useSettingsStore.getState();

  try {
    setLoading(true);
    const res = await axios.get(kuGetAdminAccess, {
      params: {
        app_role: appRole,
      },
    });
    console.log(res.data);
    if (res.data.success) {
      console.log("handleAdminAccess: " + res.data);
      setAdminAccessData(res.data.data);
    } else {
      console.log(res.data.message);
      Toastr(res.data.message);
    }
    setLoading(false);
  } catch (err) {
    console.log(err);
    Toastr(t("An error occurred!"));
  }
};

//   blue: updateAdminAccess
export const updateAdminAccess = async (
  description = "",
) => {
  const { setLoading } = useGeneralStore.getState();
  const { setAdminAccessData } = useSettingsStore.getState();

  try {
    setLoading(true);

    let body = {
      'description': description
    };

    let res = await axios.post(kuUpdateAdminAccess, body);

    if (res.data.success) {
      console.log("updateAdminAccess: " + res.data);
      setAdminAccessData(res.data.data);
      setLoading(false);
      return true;
    } else {
      console.log(res.data.message);
      Toastr(res.data.message);
      setLoading(false);
      return false;
    }
  } catch (err) {
    console.log(err);
    Toastr(t("An error occurred!"));
    setLoading(false);
    return false;
  }
};

//   blue: handleTermsGdpr
export const handleTermsGdpr = async (appRole = "") => {
  const { setLoading } = useGeneralStore.getState();
  const { setTermsGdprData } = useSettingsStore.getState();

  try {
    setLoading(true);
    const res = await axios.get(kuGetTermsAndCondition, {
      params: {
        app_role: appRole,
      },
    });
    console.log(res.data);
    if (res.data.success) {
      console.log("handleTermsGdpr: " + res.data);
      setTermsGdprData(res.data.data);
    } else {
      console.log(res.data.message);
      Toastr(res.data.message);
    }
    setLoading(false);
  } catch (err) {
    console.log(err);
    Toastr(t("An error occurred!"));
  }
};

//   blue: updateTermsGdpr
export const updateTermsGdpr = async (
  id = "",
  app_type = "",
  type = "",
  description = "",
  addNew = false
) => {
  const { setLoading } = useGeneralStore.getState();

  try {
    setLoading(true);

    // "id": 3,
    // "app_type": "company",
    // "type": "gdpr",
    // "description":  "something..."

    let body = {};
    let res = {};

    if (addNew) {
      body = {
        app_type: app_type,
        type: type,
        description: description,
      };
      res = await axios.post(kuAddTermsAndCondition, body);
    } else {
      body = {
        id: id,
        app_type: app_type,
        type: type,
        description: description,
      };
      res = await axios.post(kuUpdateTermsAndCondition, body);
    }

    console.log("body: ", body);

    console.log(res.data);

    if (res.data.success) {
      console.log("updateTermsGdpr: " + res.data);
      handleTermsGdpr(app_type);
      setLoading(false);
      return true;
    } else {
      console.log(res.data.message);
      Toastr(res.data.message);
      setLoading(false);
      return false;
    }
  } catch (err) {
    console.log(err);
    Toastr(t("An error occurred!"));
    setLoading(false);
    return false;
  }
};

export const submitChangePassword = async (cpForm) => {
  const { setLoading } = useGeneralStore.getState();

  try {
    setLoading(true);
    if (cpForm.newPassword !== cpForm.confirmNewPassword) {
      Toastr("Passwords do not match!", "warning");
      console.log("password not match");
      return;
    }

    const body = {
      old_password: cpForm.currentPassword,
      password: cpForm.newPassword,
      password_confirmation: cpForm.confirmNewPassword,
    };
    const res = await axios.post(kuChangePassword, body);
    console.log(res.data);
    if (res.data.success) {
      console.log("submitChangePassword: " + res.data);
      setLoading(false);
      Toastr(res.data.message, "success");
      return true;
    } else {
      Toastr(res.data.message);
      console.log(res.data.message);
      setLoading(false);
      return false;
    }
  } catch (err) {
    console.log("submitChangePassword" + err);
    Toastr("An error occurred!");
    setLoading(false);
    return false;
  }
};
