/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { BsCalendar2WeekFill, BsClockFill } from "react-icons/bs";
import { Outlet, useLocation, useParams } from "react-router-dom";
import CommonButton from "../../../../../Components/Button/CommonButton";
import DefaultButton from "../../../../../Components/Button/DefaultButton";
import Calendar from "../../../../../Components/Calendar";
import Dropdown from "../../../../../Components/Dropdown";
import Input02 from "../../../../../Components/Input/Input02";
import TimePicker from "../../../../../Components/Input/TimePicker";
import BackLinks from "../../../../../Components/Layout/BackLinks";
import Modal from "../../../../../Components/Modal";
import CommonModal from "../../../../../Components/Modal/CommonModal";
import { ShiftPlannerContext } from "../../../../../Context/ShiftPlannerContext";
import {
  formatDate,
  PageTitle,
  Toastr,
  validateDateTime,
} from "../../../../../Utility/UtilityFunctions";
import SelectDriver from "./SelectDriver";
import SelectLicensePlate from "./SelectLicensePlate";
import CommonCheckbox from "../../../../../Components/Checkbox/CommonCheckbox";
import SelectInput from "../../../../../Components/Select/SelectInput";

const ShiftPlannerManagement = () => {
  const {
    setIsCreatingShift,
    isShiftHistory,
    setClearOnSubmitShift,
    setIsShiftDetailsData,
    setIsShiftDetailsID,
    setSearchKey,
    showFilterModal,
    setShowFilterModal,
    setFilterState,
    licensePlateValue,
    filterShiftPlannerList,
    selectedDriver,
    isCompleted,
    setIsDeleted,
    setIsCompleted,
    setLicensePlateValue,
    setSelectedDriver,
    isDeleted,
    tempCarName,
    setTempCarName,
    setCarId,
    filterStartDate,
    setFilterStartDate,
    filterEndDate,
    setFilterEndDate,
    filterStartTime,
    setFilterStartTime,
    filterEndTime,
    setFilterEndTime,
    setChipData,
    onResetFilterHandler,

    filterStatus,
    setFilterStatus,
    is_maintenance,
    shiftFilterCar,
    setIsFilterApplied,
    isFilterApplied,
    driverShiftFilter,
    setDriverShiftFilter,
  } = useContext(ShiftPlannerContext);
  const location = useLocation();

  const { driver_id } = useParams();

  const [localFilter, setLocalFilter] = useState({
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    plate_number: "",
  });

  const [showStatusTypeModal, setShowStatusTypeModal] = useState(false);

  useEffect(() => {
    onResetFilterHandler();
  }, []);

  const onSubmitHandler = async () => {
    setSearchKey("");
    setClearOnSubmitShift(false);
    setIsCreatingShift(false);
    setDriverShiftFilter({
      ...localFilter,
      isApplied: true,
    });

    const { startDate, endDate, startTime, endTime, plate_number } =
      localFilter;

    if (!isDeleted && !isCompleted) {
      if (isShiftHistory) {
        await filterShiftPlannerList(
          {
            driver_user_id: driver_id ? driver_id : "",
            start_date: startDate ? startDate : "",
            end_date: endDate ? endDate : "",
            start_time: startTime ? startTime : "",
            end_time: endTime ? endTime : "",
            plate_number: plate_number ? plate_number : "",
            driver_name: selectedDriver ? selectedDriver : "",
            type: "history",
            status: filterStatus ? filterStatus : null,
            is_maintenance: is_maintenance,
            take: 1000,
          },
          true
        );
      } else {
        await filterShiftPlannerList(
          {
            driver_user_id: driver_id ? driver_id : "",
            start_date: startDate ? startDate : "",
            end_date: endDate ? endDate : "",
            start_time: startTime ? startTime : "",
            end_time: endTime ? endTime : "",
            plate_number: plate_number ? plate_number : "",
            driver_name: selectedDriver ? selectedDriver : "",
            take: 1000,
            status: filterStatus ? filterStatus : null,
            is_maintenance: is_maintenance,
          },
          true
        );
      }
    } else {
      await filterShiftPlannerList(
        {
          driver_user_id: driver_id ? [driver_id] : "",
          start_date: startDate ? startDate : "",
          end_date: endDate ? endDate : "",
          start_time: startTime ? startTime : "",
          end_time: endTime ? endTime : "",
          plate_number: plate_number ? plate_number : "",
          driver_name: selectedDriver ? selectedDriver : "",
          type: "history",
          take: 1000,
        },
        true
      );
    }
    // generateFilterChipData();
  };

  useEffect(() => {
    setIsShiftDetailsID(null);
  }, [isShiftHistory]);

  useEffect(() => {
    window.scrollTo(0, 0);
    PageTitle("Limadi | Shift Planner");
    setSearchKey("");
  }, []);

  const dataArray = shiftFilterCar?.map((item, index) => ({
    key: index,
    title: `${item?.name} / ${item?.car_license_plate_number}`,
    value: item?.car_license_plate_number,
    selected:
      item?.car_license_plate_number === licensePlateValue ? true : false,
  }));

  const handleClose = () => {
    if (!isFilterApplied) {
      onResetFilterHandler();
    }
    setShowFilterModal(false);
    setLocalFilter({
      startDate: driverShiftFilter?.startDate,
      endDate: driverShiftFilter?.endDate,
      startTime: driverShiftFilter?.startTime,
      endTime: driverShiftFilter?.endTime,
      plate_number: driverShiftFilter?.plate_number,
    })
  };

  return (
    <>
      {/* Filter Shifts Modal */}
      <CommonModal
        showModal={showFilterModal}
        setShowModal={handleClose}
        modalTitle={"Filter Shifts"}
        mainContent={
          <div className="pt-5">
            <div>
              <div className="grid grid-cols-2 gap-2 md:gap-8 2xl:gap-12 h-[80px] outline-none">
                <Dropdown
                  width={null}
                  button={
                    <Input02
                      onChange={(date) => {
                        setClearOnSubmitShift(false);
                        setLocalFilter({ ...localFilter, startDate: date });
                      }}
                      className="outline-none cursor-pointer"
                      className2="w-full outline-none"
                      value={localFilter?.startDate ? formatDate(localFilter?.startDate) : ""}
                      label="Start Date"
                      type="text"
                      placeholder="Start Date"
                      is_readonly={true}
                      icon={<BsCalendar2WeekFill className="h-[70%]" />}
                    />
                  }
                  body={
                    <Calendar
                      selectAction={(date) => {
                        setClearOnSubmitShift(false);
                        setLocalFilter({ ...localFilter, startDate: date });
                      }}
                      init_date={filterStartDate ? filterStartDate : ""}
                    />
                  }
                />

                <Dropdown
                  width={null}
                  button={
                    <Input02
                      onChange={(date) => {
                        setClearOnSubmitShift(false);
                        setLocalFilter({ ...localFilter, endDate: date });
                      }}
                      //is_disabled={filterStartDate ? false :true}
                      className="cursor-pointer"
                      className2="w-full"
                      name="end_date"
                      value={localFilter?.endDate ? formatDate(localFilter?.endDate) : ""}
                      label="End Date"
                      type="text"
                      placeholder="End Date"
                      is_readonly={true}
                      icon={<BsCalendar2WeekFill className="h-[70%]" />}
                      onClick={() => {
                        if (!filterStartDate) {
                          Toastr("Please select start date first", "warning");
                        }
                      }}
                    />
                  }
                  body={
                    filterStartDate ? (
                      <Calendar
                        selectAction={(date) => {
                          setClearOnSubmitShift(false);
                          setLocalFilter({ ...localFilter, endDate: date });
                        }}
                        init_date={filterEndDate ? filterEndDate : ""}
                      />
                    ) : (
                      ""
                    )
                  }
                />
              </div>

              <div className="grid grid-cols-2 gap-2 mb-12 md:gap-8 2xl:gap-12">
                <Dropdown
                  width={null}
                  button={
                    <Input02
                      value={localFilter?.startTime ? localFilter?.startTime : ""}
                      onChange={(e) => {
                        setClearOnSubmitShift(false);
                      }}
                      className="cursor-pointer"
                      className2="w-full"
                      label="Start Time"
                      type="text"
                      placeholder="Start Time"
                      icon={<BsClockFill className="h-[70%] cursor-pointer" />}
                    />
                  }
                  body={
                    <TimePicker
                      selectAction={(time) =>
                        setLocalFilter({ ...localFilter, startTime: time })
                      }
                      init_time={localFilter?.startTime ? localFilter?.startTime : ""}
                      // init_avg={pickup_avg}
                    />
                  }
                />

                <Dropdown
                  width={null}
                  button={
                    <Input02
                      value={localFilter?.endTime ? localFilter?.endTime : ""}
                      onChange={(e) => {
                        setClearOnSubmitShift(false);
                      }}
                      className2="w-full"
                      className="cursor-pointer"
                      label="End Time"
                      type="text"
                      placeholder="End Time"
                      icon={<BsClockFill className="h-[70%] cursor-pointer" />}
                    />
                  }
                  body={
                    <TimePicker
                      selectAction={(time) =>
                        setLocalFilter({ ...localFilter, endTime: time })
                      }
                      init_time={localFilter?.endTime ? localFilter?.endTime : ""}
                      // init_avg={pickup_avg}
                    />
                  }
                />
              </div>

              <SelectInput
                label={"Select Car/License Plate"}
                value={localFilter?.plate_number ? localFilter?.plate_number : ""}
                onChange={(e) => {
                  setLocalFilter({
                    ...localFilter,
                    plate_number: e.target.value,
                  });
                }}
                dataArray={dataArray}
                placeholder="Select Car/License Plate"
              />

              {location.pathname === "/shift-planner/shift-history" ? (
                <div
                  onClick={() => {
                    console.log("SELECT TYPE!!!");
                    setShowStatusTypeModal(true);
                  }}
                  className="my-5 text-center cursor-pointer"
                >
                  <Input02
                    label={"Status"}
                    placeholder="Select Status"
                    is_disabled={true}
                    value={
                      isCompleted && isDeleted
                        ? "Completed, Deleted"
                        : isCompleted
                        ? "Completed"
                        : isDeleted
                        ? "Deleted"
                        : ""
                    }
                    onChange={() => {}}
                    className2="cursor-pointer"
                    className="cursor-pointer"
                  />
                </div>
              ) : (
                <div className="mb-5"></div>
              )}

              {/* hy filter actions */}
              <div className="flex flex-row items-center justify-between">
                {/* hg reset filters */}
                <CommonButton
                  btnLabel={"Reset Filter"}
                  colorType={
                    localFilter?.startDate ||
                    localFilter?.endDate ||
                    localFilter?.startTime ||
                    localFilter?.endTime ||
                    localFilter?.plate_number 
                      ? "danger"
                      : "base"
                  }
                  onClick={async() => {
                    onResetFilterHandler();
                    if (driverShiftFilter?.isApplied) {
                      await filterShiftPlannerList(
                        {
                          driver_user_id: driver_id,
                          start_date: "",
                          end_date: "",
                          start_time: "",
                          end_time: "",
                          plate_number: "",
                          driver_name: "",
                          take: 1000,
                          status: null,
                          is_maintenance: null,
                        },
                        true
                      );
                    }
                    setLocalFilter({
                      startDate: "",
                      endDate: "",
                      startTime: "",
                      endTime: "",
                      plate_number: "",
                    });
                    setDriverShiftFilter({
                      startDate: "",
                      endDate: "",
                      startTime: "",
                      endTime: "",
                      plate_number: "",
                      isApplied: false
                    });
                    
                    setIsFilterApplied(false);
                    setShowFilterModal(false);
                  }}
                  isDisabled={
                    localFilter?.startDate || localFilter?.endDate || localFilter?.startTime || localFilter?.endTime || localFilter?.plate_number ? false : true
                  }
                />

                {/* hb submit filter */}
                <CommonButton
                  btnLabel={"Filter Shifts"}
                  onClick={async () => {
                    let x = true;
                    x = await validateDateTime(
                      localFilter?.startDate,
                      localFilter?.startTime,
                      localFilter?.endDate,
                      localFilter?.endTime
                    );
                    if (!x) return;
                    // submitFilterPopup();
                    onSubmitHandler();
                    setShowFilterModal(false);
                    setFilterState(false);

                    // hg clear previous shift details, selection etc
                    setIsShiftDetailsID(null);
                    setIsShiftDetailsData({});
                    setIsFilterApplied(true);
                  }}
                />
              </div>
            </div>
            {/* hg status type modal */}
            <Modal
              show_modal={showStatusTypeModal}
              setShowModal={setShowFilterModal}
              full_content={
                <div className="inline-block w-full max-w-[35vw] p-5 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
                  <div className="relative">
                    <AiFillCloseCircle
                      onClick={() => setShowStatusTypeModal(false)}
                      className="absolute top-0 right-0 text-2xl text-gray-600 cursor-pointer"
                    />
                    <div className="px-5 text-xl font-bold text-center text-gray-700">
                      Select Shift Type
                    </div>
                    <div className="pt-5 pb-2 text-center">
                      <label
                        htmlFor="complete"
                        className={`select-none cursor-pointer text-cIcon bg-cMoreLiteGrey flex justify-between items-center px-5 py-3 rounded-[5px] ${
                          isCompleted ? "ring-1 ring-cSecondary" : ""
                        }`}
                      >
                        <div>Completed</div>
                        <input
                          className="w-[18px] h-[18px] accent-cSecondary"
                          id="complete"
                          type={"checkbox"}
                          checked={isCompleted}
                          onChange={() => {
                            setIsCompleted(!isCompleted);
                          }}
                        />
                      </label>

                      <label
                        htmlFor="delete"
                        className={`select-none cursor-pointer text-cIcon bg-cMoreLiteGrey flex justify-between items-center px-5 py-3 my-4 rounded-[5px] ${
                          isDeleted ? "ring-1 ring-cSecondary" : ""
                        }`}
                      >
                        <div>Deleted</div>
                        <input
                          className="w-[18px] h-[18px] accent-cSecondary"
                          id="delete"
                          type={"checkbox"}
                          checked={isDeleted}
                          onChange={() => {
                            setIsDeleted(!isDeleted);
                          }}
                        />
                      </label>
                    </div>

                    <div className="flex flex-row justify-center">
                      <DefaultButton
                        label={"Submit"}
                        type="warning"
                        onSubmit={() => {
                          setShowStatusTypeModal(false);
                        }}
                        canSubmit={isCompleted || isDeleted ? true : false}
                      />
                    </div>
                  </div>
                </div>
              }
            />
          </div>
        }
      />
      <BackLinks
        linksArray={[
          { label: "drivers", linkTo: "/users/drivers" },
          {
            label: "driver Details",
            linkTo: "/users/drivers/details/" + driver_id,
          },
          { label: "Shift Planner", linkTo: "" },
        ]}
      />
      <Outlet />
    </>
  );
};

export default ShiftPlannerManagement;
