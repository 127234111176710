import PackageItemButton from "../../../Components/Button/PackageItemButton";


export default function PackageItem({ data }) {

  return (
    <>
      <div className="bg-white rounded-md w-full h-auto shadow-sm text-fs16">
        <div className="text-center text-fs16 font-bold pt-5">
          {data.number}
        </div>
        <div className="my-s10 border-cNmSelect border-b-[1px]"></div>

        <div className="text-center mb-5">
          <span className="text-fs24 font-semibold"> {data?.price.toLocaleString("da-DK")} DKK</span>
        </div>



        <div className="h-auto text-fs16 px-5 text-center">
          {data.description}
        </div>

        <div className="my-s10 border-cNmSelect border-b-[1px]"></div>

        <div className="flex justify-center pt-s10 pb-5 w-full items-center">
          <PackageItemButton id={data.id} isActive={data.is_active} />
        </div>
      </div>
    </>
  );
}
