import React from 'react'
import CommonModal from '../../../../../Components/Modal/CommonModal'
import useGeneralStore from '../../../../../App/stores/GeneralStore'
import { BaseUrlSrc } from '../../../../../Utility/url';
import { iNoImage } from '../../../../../Utility/Sources';
import CommonImage from '../../../../../Components/Image/CommonImage';
const ImageViewerModal = () => {

    const { showImageViewerModal, setShowImageViewerModal, imageViewerModalUrl } = useGeneralStore();

    const fileExtension = imageViewerModalUrl?.split('.')?.pop()?.toLowerCase();

    // Define the known image file extensions
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg", "webp"];

    // Define the known PDF file extensions
    const pdfExtensions = ["pdf"];

    return (
        <div>
            <CommonModal
                showModal={showImageViewerModal}
                setShowModal={setShowImageViewerModal}
                modalTitle={"​Attachment Preview"}
                mainContent={
                    <>
                        <div className='mb-s10'>

                            {
                                imageExtensions.includes(fileExtension) && <div className={`mt-s20 w-full flex flex-row justify-center h-auto max-h-[90vh]`}>
                                    <CommonImage
                                        src={imageViewerModalUrl}
                                        alt="img"
                                        className="!object-contain"
                                        dummyImage={iNoImage}
                                    />

                                </div>
                            }

                            {
                                imageViewerModalUrl === null && <div className={`mt-s20 w-full flex flex-row justify-center h-auto max-h-[90vh]`}>
                                    <CommonImage
                                        src={imageViewerModalUrl}
                                        alt="img"
                                        className="!object-contain"
                                        dummyImage={iNoImage}
                                    />

                                </div>
                            }
                            {
                                pdfExtensions.includes(fileExtension) && <div className=' mt-s20 w-full truncate border-2 border-dashed border-[#2257AA] p-s10 rounded-br5'>
                                    <a
                                        href={BaseUrlSrc + imageViewerModalUrl}
                                        download="Attachment.pdf"
                                        target="_blank"
                                        rel="noreferrer"
                                        alt=""
                                        className="text-cLightSkyBlue "
                                    > Attachment.pdf</a>
                                </div>
                            }
                            {!imageExtensions.includes(fileExtension) && !pdfExtensions.includes(fileExtension) && imageViewerModalUrl !== null && <p className='mt-5 text-center'>This file is not supported</p>}

                        </div>
                    </>
                }
            />
        </div>
    )
}

export default ImageViewerModal