import React, { useEffect, useState } from 'react'
import useCompanyStore, { updateCommonDetails } from '../../../../App/stores/CompanyStore';
import CommonButton from '../../../../Components/Button/CommonButton';
import ProfileImageUploader from '../../../../Components/Image/ProfileImageUploader';
import CommonInput from '../../../../Components/Input/CommonInput';
import CommonModal from '../../../../Components/Modal/CommonModal';
import InputAutoCompleteType2 from '../../../../Components/Input/InputAutocompleteType2';
import { Toastr, isValidUrl } from '../../../../Utility/UtilityFunctions';

export default function EditCompanyDetails() {

    const { showEditCompanyModal, setShowEditCompanyModal } = useCompanyStore();

    return (
        <>
            <CommonModal
                showModal={showEditCompanyModal}
                setShowModal={setShowEditCompanyModal}
                modalTitle={"Edit Details"}

                mainContent={
                    <EditForm />
                }
            />
        </>
    )
}

const EditForm = () => {

    const { setShowEditCompanyModal, companyDetails } = useCompanyStore();

    const [doSearch, setDoSearch] = useState(false);
    const [addressLat, setAddressLat] = useState("");
    const [addressLng, setAddressLng] = useState("");
    const [addressLabel, setAddressLabel] = useState("");

    const [updateForm, setUpdateForm] = useState({
        name: "",
        email: "",
        phone: "",
        address: "",
        lat: "",
        lng: "",
        image: "",
        website: ""
    });

    const handleChange = (name, value) => {
        if (name === "address") {
            setAddressLabel(value);
        } else if (name === "lat") {
            setAddressLat(value)
        } else if (name === "lng") {
            setAddressLng(value)
        }
    };

    useEffect(() => {
        setUpdateForm({
            ...updateForm,
            address: addressLabel,
            lat: addressLat,
            lng: addressLng,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressLabel, addressLat, addressLng])

    useEffect(() => {
        setUpdateForm(companyDetails);
        setAddressLabel(companyDetails?.address);
        setAddressLat(companyDetails?.lat);
        setAddressLng(companyDetails?.lng);
    }, [companyDetails])

    const editCompanyData = async (e) => {
        e.preventDefault();
        let formData = updateForm;
        if (formData?.website) {
            if (isValidUrl(formData?.website)) {
                if (!updateForm?.image?.includes("data:image")) {
                    formData= {
                        name: updateForm?.name,
                        email: updateForm?.email,
                        phone: updateForm?.phone,
                        address: updateForm?.address,
                        lat: updateForm?.lat,
                        lng: updateForm?.lng,
                        website: updateForm?.website,
                        id: companyDetails?.id,
                    }
                };
                if(formData?.address){
                    if (!formData?.lat && !formData?.lng) {
                        return Toastr("Invalid Address", "warning");
                    } else {
                        let updateSuccess = await updateCommonDetails(formData, 2);
                        if (updateSuccess) setShowEditCompanyModal(false);
                    }
                }else {
                    let updateSuccess = await updateCommonDetails(formData, 2);
                        if (updateSuccess) setShowEditCompanyModal(false);
                }
               
            } else {
                return Toastr("Please Enter valid url", "warning" )
            }
        }else{
            if (!updateForm?.image?.includes("data:image")) 
                {
                    formData= {
                        name: updateForm?.name,
                        email: updateForm?.email,
                        phone: updateForm?.phone,
                        address: updateForm?.address,
                        lat: updateForm?.lat,
                        lng: updateForm?.lng,
                        website: updateForm?.website,
                        id: companyDetails?.id,
                    }
                };
            if(formData?.address){
                if (!formData?.lat && !formData?.lng) {
                    return Toastr("Invalid Address", "warning");
                } else {
                    let updateSuccess = await updateCommonDetails(formData, 2);
                    if (updateSuccess) setShowEditCompanyModal(false);
                }
            }else {
                let updateSuccess = await updateCommonDetails(formData, 2);
                    if (updateSuccess) setShowEditCompanyModal(false);
            }
        }
    }

    return (
        <form onSubmit={editCompanyData}>
            <div className="py-5 space-y-5">
                <div className="flex justify-center">
                    <ProfileImageUploader value={updateForm?.image ? updateForm?.image?.includes("data:image") ? updateForm?.image : (updateForm?.image) : ""} onChange={(e) => console.log((e))} finalBase64={(coded) => { setUpdateForm({ ...updateForm, image: coded }); console.log("IMAGE DATA::::", coded); }} />
                </div>

                <CommonInput
                    max_input={55}
                    placeholder="Please enter name"
                    label={"name"}
                    value={updateForm?.name}
                    onChange={(e) => setUpdateForm({ ...updateForm, name: e.target.value })}
                />

                <CommonInput
                    labelCapitalize={true}
                    placeholder="CVR"
                    // required={true}
                    notEditable={true}
                    name="cvr"
                    label="CVR"
                    disabled={true}
                    type="email"
                    value={updateForm?.cvr}
                    onChange={(e) => setUpdateForm({ ...updateForm, email: e.target.value })}
                />

                <CommonInput
                    placeholder="Please enter email address"
                    // required={true}
                    notEditable={true}
                    name="email"
                    label="email"
                    disabled={true}
                    type="email"
                    value={updateForm?.email}
                    onChange={(e) => setUpdateForm({ ...updateForm, email: e.target.value })}
                />


                <CommonInput
                    unnecessaryCharacters={true}
                    max_input={15}
                    placeholder="Please enter phone number"
                    min_number={0}
                    // required={true}
                    label={"phone"}
                    type="tel"
                    value={updateForm?.phone}
                    onChange={(e) => {
                        const input = e.target.value;
                        const sanitizedInput = input.replace(/[^0-9+]/g, '');
                        const sanitizedWithSinglePlus = sanitizedInput.replace(/\+/g, '+');
                        const sanitizedWithMaxTwoPlus = sanitizedWithSinglePlus.replace(/\+/g, (match, index) => index === 0 ? match : '');
                        setUpdateForm({ ...updateForm, phone: sanitizedWithMaxTwoPlus });
                    }}
                />
                {/* <CommonInput
                    max_input={255}
                    placeholder="Please enter address"
                    label={"address"}
                    value={updateForm?.address}
                    onChange={(e) => setUpdateForm({ ...updateForm, address: e.target.value })}
                /> */}

                <InputAutoCompleteType2
                    required={false}
                    label={"Address"}
                    placeholder={'Please enter address'}
                    name={'address'}
                    latName={'lat'}
                    lngName={'lng'}
                    address={addressLabel}
                    // address={addForm.address}
                    changeValue={handleChange}
                    doSearch={doSearch}
                    setDoSearch={setDoSearch}
                />

                <CommonInput
                    type='text'
                    //max_input={55}
                    placeholder="Website"
                    label={"Website"}
                    value={updateForm?.website}
                    onChange={(e) => setUpdateForm({ ...updateForm, website: e.target.value })}
                />

            </div>
            <div className="flex justify-between">
                <CommonButton onClick={() => setShowEditCompanyModal(false)} colorType='danger' btnLabel='cancel' />
                <CommonButton type='submit' btnLabel='Save Changes' />
            </div>
        </form>
    )
}
