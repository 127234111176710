import React, { useEffect, useState } from 'react'
import { t } from 'i18next';
import CommonInput from '../../../../Components/Input/CommonInput';
import InputAutoCompleteType2 from '../../../../Components/Input/InputAutocompleteType2';
import CommonButton from '../../../../Components/Button/CommonButton';
import CommonModal from '../../../../Components/Modal/CommonModal';
import useFavAddressStore, { deleteFavAddress, updateFavAddress } from '../../../../App/stores/FavAddressStore';

function UpdateAddress({ u_id }) {
    const [doSearch, setDoSearch] = useState(false)
    const [canSubmit, setCanSubmit] = useState(false)
    const [showModal, setShowModal] = useState(false)

    const { updateAddress, setIsAddAddress, setAddAddressSelected } = useFavAddressStore();
    const [updateForm, setUpdateForm] = useState(updateAddress);

    const [addressLabel, setAddressLabel] = useState("")
    const [addressLat, setAddressLat] = useState("")
    const [addressLng, setAddressLng] = useState("")

    const handleChange = (name, value) => {
        if (name === "address") {
            setAddressLabel(value);
        } else if (name === "lat") {
            setAddressLat(value)
        } else if (name === "lng") {
            setAddressLng(value)
        }
    };

    useEffect(() => {
        setUpdateForm(updateAddress);

        setAddressLabel(updateAddress.address);
        setAddressLat(updateAddress.lat);
        setAddressLng(updateAddress.lng);

        console.log(updateAddress);
    }, [updateAddress]);

    useEffect(() => {
        if (updateForm.title && updateForm.address && updateForm.lat) {
            setCanSubmit(true);
        } else {
            setCanSubmit(false);
        }
    }, [updateForm])


    useEffect(() => {
        setUpdateForm({
            ...updateForm,
            address: addressLabel,
            lat: addressLat,
            lng: addressLng,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressLabel, addressLat, addressLng])

    return (
        <form onSubmit={(e) => e.preventDefault()} className="card">
            <div className="mb-5 text-xl font-bold">{t("Favorite Address Details")}</div>
            <CommonInput
                notEditable={true}
                disabled={true}
                //required={true}
                label={t("Address Name")}
                placeholder={t("Address Name")}
                name="name"
                value={updateForm.title}
                onChange={(e) => setUpdateForm({ ...updateForm, title: e.target.value })}
            />

            <div className="pb-5"></div>

            {/* <InputAutoCompleteType2
                disabled={true}
                required={true}
                label={t('Type Address')}
                placeholder={t('Type Address')}
                name={'address'}
                latName={'lat'}
                lngName={'lng'}
                address={updateForm.address}
                changeValue={handleChange}
                doSearch={doSearch}
                setDoSearch={setDoSearch}
            /> */}

            <CommonInput
                notEditable={true}
                disabled={true}
                //required={true}
                label={t("Address")}
                placeholder={t("Address")}
                name="name"
                value={updateForm.address}
                onChange={(e) => setUpdateForm({ ...updateForm, title: e.target.value })}
            />

            <div className="pb-5"></div>

            <CommonInput
                notEditable={true}
                disabled={true}
                //required={true}
                label={t("Floor Number")}
                placeholder={t("Floor number")}
                name="name"
                value={updateForm.floor_number ?? "No floor number"}
                onChange={(e) => setUpdateForm({ ...updateForm, title: e.target.value })}
            />

            <div className="pb-5"></div>

            <CommonInput
                textarea={true}
                notEditable={true}
                disabled={true}
                //required={true}
                label={t("Note")}
                placeholder={t("Note")}
                name="name"
                value={updateForm.note ?? "No note"}
                onChange={(e) => setUpdateForm({ ...updateForm, title: e.target.value })}
            />

            <div className='flex flex-row justify-between'>
                {/* <CommonButton
                    btnLabel={t('Delete')}
                    colorType='danger'
                    onClick={() => setShowModal(true)}
                />

                <CommonButton
                    type='submit'
                    btnLabel={t('Save')}
                    onClick={() => updateFavAddress(updateForm)}
                    isDisabled={!canSubmit}
                    colorType={canSubmit ? "primary" : "base"}
                /> */}
            </div>

            {/* delete modal */}
            <CommonModal
                showModal={showModal}
                setShowModal={setShowModal}
                modalTitle={("Delete Item")}
                mainContent={
                    <div>
                        <div>
                            <div className="py-5 text-center">
                                {("Are you sure you want to delete this item ?")}
                            </div>

                            <div className="flex flex-row justify-between">
                                <CommonButton
                                    btnLabel={t("Cancel")}
                                    onClick={() => setShowModal(false)}
                                />

                                <CommonButton
                                    btnLabel={t("Delete")}
                                    colorType="danger"
                                    onClick={() => {
                                        let delSuccess = deleteFavAddress(updateForm.id, u_id)
                                        if (delSuccess) {
                                            setShowModal(false);
                                            setIsAddAddress(true);
                                            setAddAddressSelected("");
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                }
            />
        </form>
    )
}

export default UpdateAddress