import { t } from "i18next";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CommonImage from "../../../../../Components/Image/CommonImage";
import { ShiftPlannerContext } from "../../../../../Context/ShiftPlannerContext";
import {
  iDemoUser,
  iMaintenance,
  iRightArrow,
} from "../../../../../Utility/Sources";
import { formatDate } from "../../../../../Utility/UtilityFunctions";
import useShiftStore from "../../../../../App/stores/ShiftStore";

const Shift = ({ data }) => {
  const {
    getShiftPlannerDetails,
    setIsShiftDetailsID,
    isShiftDetailsID,
    setIsCreatingShift,
  } = useContext(ShiftPlannerContext);
  const { setShiftRouteList } = useShiftStore()

  const { company_id } = useParams();
  const navigateTo = useNavigate();

  const coDrivers = (shiftDrivers = []) => {
    const coDrivers = shiftDrivers
      .filter((driver) => !driver?.is_main_driver)
      .map((driver) => driver?.driver?.name);

    if (coDrivers.length === 0) {
      return "";
    }

    return coDrivers.length === 1 ? coDrivers[0] : coDrivers.join(", ");
  };

  return (
    <div
      className="flex flex-row items-center justify-start w-full h-full cursor-pointer"
      onClick={() => {
        // useShiftPlannerStore.getState().setHideShiftDetails(false)
        setShiftRouteList([])
        setIsShiftDetailsID(data?.id);
        getShiftPlannerDetails(data?.id, company_id);
        if (
          window.location.pathname ===
          "/users/companies/details/" +
            company_id +
            "/shift-planner/create-new-shift"
        ) {
          //redirect to details page
          navigateTo(
            "/users/companies/details/" + company_id + "/shift-planner"
          );
        }
      }}
    >
      <div
        className={`w-s5 h-s50 mt-s10 ${
          isShiftDetailsID === data?.id ? "bg-cListIcon" : "bg-white"
        }`}
      ></div>
      <div className="flex justify-between w-full h-[75px] rounded-br5 bg-cListItem mx-5 px-s10 py-5 mt-2 cursor-pointer select-none">
        <div className="flex items-center">
          {data?.driver_name === "Maintenance" ? (
            <div className="flex items-center justify-center bg-white rounded-full h-s50 w-s50">
              <img
                src={iMaintenance}
                alt=""
              />
            </div>
          ) : data?.driver_image ? (
            <div className="flex justify-start items-center rounded-full gs-list-icon-color w-[40px] h-[40px] overflow-hidden">
              <CommonImage
                src={
                  data?.driver_image === null ||
                  data?.driver_image === undefined ||
                  data?.driver_image === ""
                    ? iDemoUser
                    : data?.driver_image
                }
                alt="img-shift"
                className="object-cover w-full h-full"
              />
            </div>
          ) : (
            <div className="w-10 h-10 rounded-full bg-slate-600"></div>
          )}
          <div className="flex flex-col pl-2 space-y-s4">
            {data?.driver_name === "Maintenance" ? (
              <span className="text-sm font-[500] max-w-[100px] truncate">
                {t("Maintenance")}
              </span>
            ) : (
              <span className="text-sm font-[500] max-w-[200px] truncate capitalize gs-list-title-black">
                {data?.driver_name} (M)
                <span>
                  {coDrivers(data?.shift_drivers)?.length > 0 &&
                    `, ${coDrivers(data?.shift_drivers)}`}
                </span>
              </span>
            )}

            <span className="text-xs gs-text-body">
              {data?.car_name} - {data?.car_number}
            </span>

            <span className="text-xs gs-text-body">
              {formatDate(data?.shift_start_date)} (
              {data?.shift_start_time?.slice(0, -3)} -{" "}
              {data?.shift_end_time?.slice(0, -3)})
            </span>
          </div>
        </div>
        <div className="flex items-center justify-center ">
          <div className="capitalize flex-col gs-text-body text-[10px] space-y-s10 justify-center items-end lg:hidden xl:flex 2xl:flex md:flex sm:flex flex">
            {/* <span>{formatDate(data?.shift_start_date)}</span>

            <span>
              {data?.shift_start_time?.slice(0, -3)} - {data?.shift_end_time?.slice(0, -3)}
            </span> */}
            {data?.status_show}
          </div>
          <img
            className="pl-2"
            src={iRightArrow}
            alt=""
          />
        </div>
      </div>
    </div>
    // </Link>
  );
};

export default Shift;
