import useGeneralStore from "../../../../../App/stores/GeneralStore";
import { iStarIcon } from "../../../../../Utility/Sources";

function LicenseCard({ isSelected, onClick, info, index }) {
  // console.log(info);
  let starArray = [];
  starArray[info.id] = 0;
  return (
    <div
      onClick={() => onClick(info.id)}
      style={{ flex: "0 0 auto" }}
      className={`z-20 w-[152px] ${isSelected ? "bg-cSecondary" : "bg-[#EDEDED]"
        }  rounded-lg   text-center my-2 cursor-pointer
        transform transition duration-500 hover:scale-105`}
    >
      <p
        className={` text-base ${isSelected ? "text-cWhite" : "text-[#333333]"
          }  mt-5 mb-1 font-bold`}
      >
        {info.number}
      </p>
      <p
        className={` text-xl font-bold ${isSelected ? "text-cWhite" : "text-[#333333]"
          } mb-1`}
      >
        {'DKK ' + info.price.toLocaleString("da-DK")}
      </p>
    </div>
  );
}

export default LicenseCard;
