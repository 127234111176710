import { Tooltip } from "antd";
import useLicenseStore, {
  changeLicenseStatus,
} from "../../App/stores/LicenseStore";

import EditIcon from "../../Images/icons/Edit.png";
import Switch from "@mui/material/Switch";

const label = { inputProps: { "aria-label": "Switch demo" } };

const PackageItemButton = ({
  onClick,
  type,
  onChange,
  name,
  label,
  deletePackage,
  id,
  isActive = false,
}) => {
  const { setShowDeleteLicense, setLicensePackID, setShowUpdateLicense } =
    useLicenseStore();

  return (
    <div className="flex items-center space-x-5">
      <span>
        <button
          onClick={() => {
            setShowUpdateLicense(true);
            setLicensePackID(id);
          }}
        >
          <Tooltip title="Edit this package">
            <img
              src={EditIcon}
              alt=""
            />
          </Tooltip>
        </button>
      </span>

      <span>
        <Switch
          {...label}
          size="small"
          onChange={(e) => {
            setLicensePackID(id);
            console.log("value", e.target.checked);
            if (!e.target.checked) {
              setShowDeleteLicense(true);
            } else {
              changeLicenseStatus();
            }
          }}
          checked={isActive}
        />
      </span>
    </div>
  );
};

export default PackageItemButton;
