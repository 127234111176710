import React, { useEffect } from 'react'
import CommonHomePageCard from '../../../../Components/cart/CommonHomePageCard';
import { useTranslation } from 'react-i18next';
import useRequestStore, { getRequestCount } from '../../../../App/stores/RequestStore';
import { k_data_set } from '../../../../App/Utility/const';
import { useParams } from 'react-router-dom';

const RequestSection = () => {

    const { t } = useTranslation();

    const { customer_id, company_id } = useParams();

    const { setRequestActiveSection, request_active_section, setRequestDetails, request_count_data, setRequestSearchKey, setSavedIndex } = useRequestStore();

    useEffect(() => {
        if (customer_id) getRequestCount(customer_id, false,"customer");
        else if (company_id) getRequestCount(company_id ,false,"company");
    }, [customer_id, company_id]);

    const clickEvent = (type) => {
        setRequestActiveSection(type); //set Request Active Section
        setRequestDetails({}); // request details clear
        setRequestSearchKey(""); // clear search
        setSavedIndex("")
        if (customer_id) getRequestCount(customer_id, false,"customer");
        else if (company_id) getRequestCount(company_id ,false,"company");
    }

    return (
        <div>
            <div className='grid w-full grid-cols-1 gap-4 md:w-1/7 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 md:gap-4 lg:gap-4 xl:gap-5 2xl:gap-5'>

                <CommonHomePageCard
                    onClick={() => {
                        clickEvent(k_data_set.saved);
                    }}
                    title={t("Saved")}
                    count={request_count_data?.saved ?? 0}
                    selected={request_active_section === k_data_set.saved}
                />

                {company_id && <CommonHomePageCard
                    onClick={() => {
                        clickEvent(k_data_set.invitation);
                    }}
                    title={t("Invitation")}
                    count={request_count_data?.invitation ?? 0}
                    selected={request_active_section === k_data_set.invitation}
                />}

                <CommonHomePageCard
                    onClick={() => {
                        clickEvent(k_data_set.in_bidding);
                    }}
                    title={t("In Bidding")}
                    count={request_count_data?.in_bidding ?? 0}
                    selected={request_active_section === k_data_set.in_bidding}
                />

                {company_id && <CommonHomePageCard
                    onClick={() => {
                        clickEvent(k_data_set.not_planned);
                    }}
                    title={t("Not Planned")}
                    count={request_count_data?.not_planned ?? 0}
                    selected={request_active_section === k_data_set.not_planned}
                />}


                <CommonHomePageCard
                    onClick={() => {
                        clickEvent(k_data_set.awarded);
                    }}
                    title={t("Upcoming")}
                    count={request_count_data?.awarded ?? 0}
                    selected={request_active_section === k_data_set.awarded}
                />


                <CommonHomePageCard
                    onClick={() => {
                        clickEvent(k_data_set.ongoing);
                    }}
                    title={t("Ongoing")}
                    count={request_count_data?.ongoing ?? 0}
                    selected={request_active_section === k_data_set.ongoing}
                />

                {company_id && <CommonHomePageCard
                    onClick={() => {
                        clickEvent(k_data_set.completed);
                    }}
                    title={t("Completed")}
                    count={request_count_data?.complete ?? 0}
                    selected={request_active_section === k_data_set.completed}
                />}

                <CommonHomePageCard
                    onClick={() => {
                        clickEvent(k_data_set.history);
                    }}
                    title={customer_id ? t("Completed") : t("History")}
                    count={request_count_data?.history ?? 0}
                    selected={request_active_section === k_data_set.history}
                />


            </div>
        </div>
    )
}

export default RequestSection