/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { BsCalendar2WeekFill, BsClockFill } from "react-icons/bs";
import { Outlet, useParams } from "react-router-dom";
import CommonButton from "../../../../../Components/Button/CommonButton";
import DefaultButton from "../../../../../Components/Button/DefaultButton";
import Calendar from "../../../../../Components/Calendar";
import Dropdown from "../../../../../Components/Dropdown";
import Input02 from "../../../../../Components/Input/Input02";
import TimePicker from "../../../../../Components/Input/TimePicker";
import BackLinks from "../../../../../Components/Layout/BackLinks";
import Modal from "../../../../../Components/Modal";
import CommonModal from "../../../../../Components/Modal/CommonModal";
import { ShiftPlannerContext } from "../../../../../Context/ShiftPlannerContext";
import {
  formatDate,
  PageTitle,
  validateDateTime,
} from "../../../../../Utility/UtilityFunctions";
import SelectDriver from "./SelectDriver";
import SelectLicensePlate from "./SelectLicensePlate";
import SelectInput from "../../../../../Components/Select/SelectInput";
import CommonCheckbox from "../../../../../Components/Checkbox/CommonCheckbox";

const ShiftPlannerManagement = () => {
  const {
    setIsCreatingShift,
    isShiftHistory,
    setClearOnSubmitShift,
    setIsShiftDetailsData,
    setIsShiftDetailsID,
    setSearchKey,
    showFilterModal,
    setShowFilterModal,
    setFilterState,
    licensePlateValue,
    filterShiftPlannerList,
    selectedDriver,
    isCompleted,
    setIsDeleted,
    setIsCompleted,
    setLicensePlateValue,
    setSelectedDriver,
    setDriverUserId,
    setSelected,
    setDriverName,
    isDeleted,
    tempCarName,
    filterStartDate,
    setFilterStartDate,
    filterEndDate,
    setFilterEndDate,
    filterStartTime,
    setFilterStartTime,
    filterEndTime,
    setFilterEndTime,
    onResetFilterHandler,
    filterStatus,
    setFilterStatus,
    is_maintenance,
    setIs_maintenance,
    driverUserId,
    isFilterApplied,
    setIsFilterApplied,
    setTempCarName,
    setCarId,
    setLicenseName,
    shiftFilter,
    setShiftFilter,
    setSelectedCar,
  } = useContext(ShiftPlannerContext);

  const { company_id } = useParams();

  const [localFilter, setLocalFilter] = useState({
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    car: {},
    driver: {},
    isMaintenance: null,
    status: null,
  })

  const [showStatusTypeModal, setShowStatusTypeModal] = useState(false);

  const ChangeStartDateValue = async (name, value) => setFilterStartDate(value);

  const ChangeEndDateValue = async (name, value) => setFilterEndDate(value);

  const onPickStartDateSelect = (time) => setFilterStartTime(time);

  const onPickEndDateSelect = (time) => setFilterEndTime(time);

  useEffect(() => {
    onResetFilterHandler();
  }, []);

  useEffect(()=> {
    if(shiftFilter?.isApplied) {
      setLocalFilter(shiftFilter)
      setSelected(shiftFilter?.driver?.id)
      setSelectedCar(shiftFilter?.car?.id)
    }
  }, [])

  const handleAllStateUpdate = () => {
    setDriverName(localFilter?.driver?.name);
    setSelectedDriver(localFilter?.driver?.name);
    setDriverUserId(localFilter?.driver?.id);
    setFilterStartDate(localFilter?.startDate);
    setFilterEndDate(localFilter?.endDate);
    setFilterStartTime(localFilter?.startTime);
    setFilterEndTime(localFilter?.endTime);
    setIs_maintenance(localFilter?.isMaintenance ? 1 : null);
    setFilterStatus(localFilter?.filterStatus);
    setLicenseName(localFilter?.car?.name);
    setTempCarName(localFilter?.car?.name);
    setLicensePlateValue(localFilter?.car?.car_license_plate_number);
    setCarId(localFilter?.car?.id);
  }

  const onSubmitHandler = async () => {
    handleAllStateUpdate()
    setSearchKey("");
    setClearOnSubmitShift(false);
    setIsCreatingShift(false);

    const {
      startDate,
      endDate,
      startTime,
      endTime,
      car,
      driver,
      isMaintenance,
      status,
    } = localFilter

    if (!isDeleted && !isCompleted) {
      if (isShiftHistory) {
        await filterShiftPlannerList(
          {
            user_id: company_id,
            start_date: startDate ? startDate : "",
            end_date: endDate ? endDate : "",
            start_time: startTime ? startTime : "",
            end_time: endTime ? endTime : "",
            plate_number: car?.car_license_plate_number ? [car?.car_license_plate_number] : "",
            driver_name: driver?.id ? [driver?.name] : "",
            driver_id: driver?.id ? [driver?.id] : "",
            type: "history",
            status: status ? [status] : null,
            is_maintenance: isMaintenance ? 1 : null,
            take: 1000,
          },
          false
        );
      } else {
        await filterShiftPlannerList(
          {
            user_id: company_id,
            start_date: startDate ? startDate : "",
            end_date: endDate ? endDate : "",
            start_time: startTime ? startTime : "",
            end_time: endTime ? endTime : "",
            plate_number: car?.car_license_plate_number ? [car?.car_license_plate_number] : "",
            driver_name: driver?.id ? [driver?.name] : "",
            driver_id: driver?.id ? [driver?.id] : "",
            status: status ? [status] : null,
            is_maintenance: isMaintenance ? 1 : null,
            take: 1000,
          },
          false
        );
      }
    } else {
      await filterShiftPlannerList(
        {
          user_id: company_id,
          start_date: startDate ? startDate : "",
          end_date: endDate ? endDate : "",
          start_time: startTime ? startTime : "",
          end_time: endTime ? endTime : "",
          plate_number: car?.car_license_plate_number ? [car?.car_license_plate_number] : "",
          driver_name: driver?.id ? [driver?.name] : "",
          driver_id: driver?.id ? [driver?.id] : "",
          type: "history",
          take: 1000,
        },
        false
      );
    }
  };

  useEffect(() => {
    setIsShiftDetailsID(null);
  }, [isShiftHistory]);

  useEffect(() => {
    window.scrollTo(0, 0);
    PageTitle("Limadi | Shift Planner");
    setSearchKey("");
  }, []);

  const handleClose = () => {
    if(!isFilterApplied) {
      onResetFilterHandler()
    }
    if(shiftFilter?.isApplied) {
      setLocalFilter(shiftFilter)
      setSelected(shiftFilter?.driver?.id)
      setSelectedCar(shiftFilter?.car?.id)
    }
    else  {
      setLocalFilter({
        startDate: "",
        endDate: "",
        startTime: "",
        endTime: "",
        car: {},
        driver: {},
        isMaintenance: null,
        status: null,
      });
      setSelected(null)
      setSelectedCar(null)
    }
    setShowFilterModal(false)
  }

  return (
    <>
      {/* Filter Shifts Modal */}
      <CommonModal
        showModal={showFilterModal}
        setShowModal={handleClose}
        modalTitle={"Filter Shifts"}
        mainContent={
          <div className="pt-5">
            <div>
              <div className="grid grid-cols-2 gap-2 md:gap-8 2xl:gap-12 h-[80px] outline-none">
                <Dropdown
                  width={null}
                  button={
                    <Input02
                      onChange={(date) => {
                        setClearOnSubmitShift(false);
                        setLocalFilter({...localFilter, startDate: date})
                      }}
                      className="outline-none cursor-pointer"
                      className2="w-full outline-none"
                      value={localFilter?.startDate ? formatDate(localFilter?.startDate) : ""}
                      label="Start Date"
                      type="text"
                      placeholder="Start Date"
                      is_readonly={true}
                      icon={<BsCalendar2WeekFill className="h-[70%]" />}
                    />
                  }
                  body={
                    <Calendar
                      selectAction={(date) => {
                        setClearOnSubmitShift(false);
                        setLocalFilter({...localFilter, startDate: date})
                      }}
                      init_date={localFilter?.startDate ? localFilter?.startDate : ""}
                    />
                  }
                />

                <Dropdown
                  width={null}
                  button={
                    <Input02
                      onChange={(date) => {
                        setClearOnSubmitShift(false);
                        setLocalFilter({...localFilter, endDate: date})
                      }}
                      className="cursor-pointer"
                      className2="w-full"
                      name="end_date"
                      value={localFilter?.endDate ? formatDate(localFilter?.endDate) : ""}
                      label="End Date"
                      type="text"
                      placeholder="End Date"
                      is_readonly={true}
                      icon={<BsCalendar2WeekFill className="h-[70%]" />}
                    />
                  }
                  body={
                    <Calendar
                      selectAction={(date) => {
                        setClearOnSubmitShift(false);
                        setLocalFilter({...localFilter, endDate: date})
                      }}
                      init_date={filterEndDate ? filterEndDate : ""}
                    />
                  }
                />
              </div>

              <div className="grid grid-cols-2 gap-2 mb-12 md:gap-8 2xl:gap-12">
                <Dropdown
                  width={null}
                  button={
                    <Input02
                      value={localFilter?.startTime ? localFilter?.startTime : ""}
                      onChange={(e) => {
                        setClearOnSubmitShift(false);
                      }}
                      className="cursor-pointer"
                      className2="w-full"
                      label="Start Time"
                      type="text"
                      placeholder="Start Time"
                      icon={<BsClockFill className="h-[70%] cursor-pointer" />}
                    />
                  }
                  body={
                    <TimePicker
                      selectAction={(time) => setLocalFilter({...localFilter, startTime: time})}
                      init_time={filterStartTime ? filterStartTime : ""}
                      // init_avg={pickup_avg}
                    />
                  }
                />

                <Dropdown
                  width={null}
                  button={
                    <Input02
                      value={localFilter?.endTime ? localFilter?.endTime : ""}
                      onChange={(e) => {
                        setClearOnSubmitShift(false);
                      }}
                      className2="w-full"
                      className="cursor-pointer"
                      label="End Time"
                      type="text"
                      placeholder="End Time"
                      icon={<BsClockFill className="h-[70%] cursor-pointer" />}
                    />
                  }
                  body={
                    <TimePicker
                      selectAction={(time) => setLocalFilter({...localFilter, endTime: time})}
                      init_time={localFilter?.endTime ? localFilter?.endTime : ""}
                      // init_avg={pickup_avg}
                    />
                  }
                />
              </div>

              {/* hp select car / license plate popup */}
              <div className="text-center">
                <SelectLicensePlate 
                  tempName={localFilter?.car?.id ? localFilter?.car?.name : null} 
                  hasManagement={true} 
                  onChange={(item) => {setLocalFilter({...localFilter, car: item})}} 
                  value={localFilter?.car}
                />
              </div>

              {/* hy select driver popup */}
              <div className="text-center mt-s20">
                <SelectDriver 
                  tempName={localFilter?.driver?.id ? localFilter?.driver?.name : null} 
                  isUnderMaintanance={localFilter?.isMaintenance} 
                  hasManagement={true} 
                  onChange={(item) => setLocalFilter({...localFilter, driver: item})} 
                  value={localFilter?.driver}
                />
              </div>

              <div className="my-s20 grid grid-cols-2 gap-2 md:gap-4 2xl:gap-8 h-[80px] outline-none">
                <SelectInput
                  disabled={localFilter?.isMaintenance}
                  label={"Status"}
                  value={localFilter?.status}
                  onChange={(e) => {
                    setLocalFilter({...localFilter, status: e.target.value});
                  }}
                  dataArray={[
                    {
                      title: "Not Started",
                      value: "init",
                      selected: false,
                    },
                    {
                      title: "Upcoming",
                      value: "upcoming",
                      selected: false,
                    },
                    {
                      title: "Ongoing",
                      value: "ongoing",
                      selected: false,
                    },
                    {
                      title: "Completed",
                      value: "complete",
                      selected: false,
                    },
                  ]}
                  placeholder="Selected Status"
                />
                <div className="flex items-center justify-center">
                  <CommonCheckbox
                    label={"in maintenance"}
                    checked={localFilter?.isMaintenance}
                    onChange={() => {
                      //console.log("is_maintenance",is_maintenance)
                      // setSelectedDriver("");
                      // setDriverUserId("");
                      setSelected(null);
                      // setDriverName("")
                      // setFilterStatus("")
                      // setIs_maintenance(is_maintenance ? 0 : 1);
                      setLocalFilter({...localFilter, driver: {}, status: "", isMaintenance: !localFilter?.isMaintenance})
                    }}
                  />
                </div>
              </div>

              {/*
              {/* hy filter actions */}
              <div className="flex flex-row items-center justify-between">
                {/* hg reset filters */}
                <CommonButton
                  btnLabel={"Reset Filter"}
                  colorType={
                    localFilter?.startDate ||
                    localFilter?.endDate ||
                    localFilter?.startTime ||
                    localFilter?.endTime ||
                    localFilter?.car?.id ||
                    localFilter?.driver?.id ||
                    localFilter?.isMaintenance ||
                    localFilter?.status
                      ? "danger"
                      : "base"
                  }
                  onClick={() => {
                    onResetFilterHandler();
                    setLocalFilter({
                      startDate: "",
                      endDate: "",
                      startTime: "",
                      endTime: "",
                      car: {},
                      driver: {},
                      isMaintenance: null,
                      status: null,
                    });
                    setShiftFilter({
                      startDate: "",
                      endDate: "",
                      startTime: "",
                      endTime: "",
                      car: {},
                      driver: {},
                      isMaintenance: null,
                      status: null,
                      isApplied: false
                    })
                    setSelected(null)
                    setSelectedCar(null)
                    if(isFilterApplied) {
                      filterShiftPlannerList({
                        user_id: company_id,
                        start_date: "",
                        end_date: "",
                        start_time: "",
                        end_time: "",
                        plate_number: "",
                        driver_name: "",
                        driver_id: "",
                        status: null,
                        is_maintenance: null,
                        take: 1000,
                      }, false);
                    }
                    setIsFilterApplied(false);
                    setShowFilterModal(false);
                  }}
                  isDisabled={
                    localFilter?.startDate ||
                    localFilter?.endDate ||
                    localFilter?.startTime ||
                    localFilter?.endTime ||
                    localFilter?.car?.id ||
                    localFilter?.driver?.id ||
                    localFilter?.isMaintenance ||
                    localFilter?.status
                      ? false
                      : true
                  }
                />

                {/* hb submit filter */}
                <CommonButton
                  btnLabel={"Filter Shifts"}
                  // colorType={
                  //   filterStartDate ||
                  //     filterEndDate ||
                  //     filterStartTime ||
                  //     filterEndTime ||
                  //     licensePlateValue ||
                  //     selectedDriver ||
                  //     isCompleted ||
                  //     isDeleted ||
                  //     is_maintenance ||
                  //     filterStatus
                  //     ?
                  //     "primary"
                  //     :
                  //     "base"
                  // }
                  onClick={async () => {
                    let x = true;
                    x = await validateDateTime(
                      localFilter?.startDate,
                      localFilter?.startTime,
                      localFilter?.endDate,
                      localFilter?.endTime
                    );
                    if (!x) return;
                    // submitFilterPopup();
                    onSubmitHandler();
                    setShowFilterModal(false);
                    setFilterState(false);
                    setShiftFilter({...localFilter, isApplied: true})

                    // hg clear previous shift details, selection etc
                    setIsShiftDetailsID(null);
                    setIsShiftDetailsData({});
                    setIsFilterApplied(true)
                  }}
                  // isDisabled={
                  //   filterStartDate ||
                  //     filterEndDate ||
                  //     filterStartTime ||
                  //     filterEndTime ||
                  //     licensePlateValue ||
                  //     selectedDriver ||
                  //     isCompleted ||
                  //     isDeleted ||
                  //     is_maintenance ||
                  //     filterStatus
                  //     ?
                  //     false
                  //     :
                  //     true
                  // }
                />
              </div>
            </div>
            {/* hg status type modal */}
            <Modal
              show_modal={showStatusTypeModal}
              setShowModal={setShowFilterModal}
              full_content={
                <div className="inline-block w-full max-w-[35vw] p-5 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
                  <div className="relative">
                    <AiFillCloseCircle
                      onClick={() => setShowStatusTypeModal(false)}
                      className="absolute top-0 right-0 text-2xl text-gray-600 cursor-pointer"
                    />
                    <div className="px-5 text-xl font-bold text-center text-gray-700">
                      Select Shift Type
                    </div>
                    <div className="pt-5 pb-2 text-center">
                      <label
                        htmlFor="complete"
                        className={`select-none cursor-pointer text-cIcon bg-cMoreLiteGrey flex justify-between items-center px-5 py-3 rounded-[5px] ${
                          isCompleted ? "ring-1 ring-cSecondary" : ""
                        }`}
                      >
                        <div>Completed</div>
                        <input
                          className="w-[18px] h-[18px] accent-cSecondary"
                          id="complete"
                          type={"checkbox"}
                          checked={isCompleted}
                          onChange={() => {
                            setIsCompleted(!isCompleted);
                          }}
                        />
                      </label>

                      <label
                        htmlFor="delete"
                        className={`select-none cursor-pointer text-cIcon bg-cMoreLiteGrey flex justify-between items-center px-5 py-3 my-4 rounded-[5px] ${
                          isDeleted ? "ring-1 ring-cSecondary" : ""
                        }`}
                      >
                        <div>Deleted</div>
                        <input
                          className="w-[18px] h-[18px] accent-cSecondary"
                          id="delete"
                          type={"checkbox"}
                          checked={isDeleted}
                          onChange={() => {
                            setIsDeleted(!isDeleted);
                          }}
                        />
                      </label>
                    </div>

                    <div className="flex flex-row justify-center">
                      <DefaultButton
                        label={"Submit"}
                        type="warning"
                        onSubmit={() => {
                          setShowStatusTypeModal(false);
                        }}
                        canSubmit={isCompleted || isDeleted ? true : false}
                      />
                    </div>
                  </div>
                </div>
              }
            />
          </div>
        }
      />
      <BackLinks
        linksArray={[
          { label: "companies", linkTo: "/users/companies" },
          {
            label: "company Details",
            linkTo: "/users/companies/details/" + company_id,
          },
          { label: "Shift Planner", linkTo: "" },
        ]}
      />
      <Outlet />
    </>
  );
};

export default ShiftPlannerManagement;
