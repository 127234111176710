import { useEffect, useState } from 'react';
import useLicenseStore, { updateLicenseStatus } from '../../../../App/stores/LicenseStore';
import CommonButton from '../../../../Components/Button/CommonButton';
import CommonInput from '../../../../Components/Input/CommonInput';
import CommonLabel from '../../../../Components/Label/CommonLabel';
import CommonModal from '../../../../Components/Modal/CommonModal';
import { extractDate, incrementDate } from '../../../../Utility/UtilityFunctions';

function ConfirmAccept() {
    const { showAcceptModal, setShowAcceptModal, licenseDetails } = useLicenseStore();

    const [updateLicense, setUpdateLicense] = useState({
        id: 0,
        status: null,
        license_start_date: "",
        license_end_date: "",
    });

    const handleSubmit = (e) => {
        e.preventDefault()
        if (updateLicense.id && updateLicense.license_start_date &&
            updateLicense.license_end_date && updateLicense.status) {
            if (updateLicense.status !== licenseDetails?.license_status) {
                let updateSuccess = updateLicenseStatus(updateLicense)
                if (updateSuccess) setShowAcceptModal(false);
            }
        }
    }

    useEffect(() => {
        setUpdateLicense({
            ...updateLicense, id: licenseDetails?.id,
            status: licenseDetails?.license_status,
            license_start_date: extractDate(licenseDetails?.license_start),
            license_end_date: (extractDate(licenseDetails?.license_end,
                licenseDetails?.license?.duration))
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [licenseDetails]);
    return (
        <>
            <CommonModal
                showModal={showAcceptModal}
                setShowModal={setShowAcceptModal}
                modalTitle="license status"
                mainContent={
                    <form onSubmit={handleSubmit} className='pt-5 text-center'>
                        <div className="flex justify-between">

                            {/* r: left */}
                            <div className='flex flex-col items-start'>
                                <CommonInput
                                    label={'start date'}
                                    withStar={false}
                                    required={true}
                                    type='date'
                                    value={updateLicense?.license_start_date}
                                    onChange={(e) => {
                                        console.log((e.target.value));

                                        const startDate = new Date(e.target.value);
                                        const endDate = new Date(updateLicense.license_end_date);

                                        console.log("startDate::::", startDate, "; endDate::::", endDate);

                                        if (startDate > endDate) {
                                            setUpdateLicense({ ...updateLicense, license_end_date: "", license_start_date: e.target.value });
                                        } else {
                                            setUpdateLicense({ ...updateLicense, license_start_date: e.target.value });
                                        }
                                    }}
                                />
                            </div>

                            {/* g: mid */}
                            <div className='flex flex-col items-start'>
                                <CommonInput
                                    label={'end date'}
                                    withStar={false}
                                    required={true}
                                    type='date'
                                    disabled={!updateLicense.license_start_date}
                                    startDate={updateLicense.license_start_date}
                                    value={updateLicense.license_end_date}
                                    onChange={(e) => {
                                        console.log(e.target.value);
                                        setUpdateLicense({ ...updateLicense, license_end_date: (e.target.value) });
                                    }}
                                />
                            </div>

                            {/* B: right */}
                            <div className='flex flex-col items-start'>
                                <div className={`text-cInputLabel text-fs16 mb-s10 font-semibold text-gray-600 capitalize w-full`}>
                                    license status
                                </div>
                                <select
                                    required={true}
                                    value={updateLicense?.status ? updateLicense?.status : ""}
                                    onChange={(e) => {
                                        console.log(e.target.value);
                                        setUpdateLicense({ ...updateLicense, status: e.target.value });
                                    }}
                                    className='pl-5 rounded-br5 select-style space-y-s5 max-w-[180px] h-s36'
                                >

                                    <option
                                        className='py-s10 text-cMainBlack'
                                        selected={updateLicense?.status === ""}
                                        value="">Select a status</option>

                                    {/* <option
                                        className='py-s10 text-cMainBlack'
                                        selected={updateLicense?.status === "pending"}
                                        value="pending">Pending</option> */}

                                    {
                                        licenseDetails?.license_status !== "active" && 
                                        licenseDetails?.license_status !== "expire_warning" && 
                                        <option
                                            className='py-s10 text-cMainBlack'
                                            selected={updateLicense?.status === "processing"}
                                            value="processing">Processing</option>
                                    }

                                    <option
                                        className='py-s10 text-cMainBlack'
                                        selected={updateLicense?.status === "active"}
                                        value="active">Active</option>

                                    <option
                                        className='py-s10 text-cMainBlack'
                                        selected={updateLicense?.status === "rejected"}
                                        value="rejected">Rejected</option>

                                    <option
                                        className='py-s10 text-cMainBlack'
                                        selected={updateLicense?.status === "expired"}
                                        value="expired">Expired</option>

                                </select>
                            </div>

                        </div>



                        <div className="flex justify-center pt-5">
                            <CommonButton btnLabel='Confirm' type='submit' />
                        </div>
                    </form>
                }

            />
        </>
    )
}

export default ConfirmAccept