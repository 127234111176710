import CommonImage from "../../../Components/Image/CommonImage";
import { formatDate } from "../../../Utility/UtilityFunctions";

function LowCompanyRow({ data, num }) {
  const lastActivity = (data) => {
    if (data?.latest_req == null && data?.latest_shift == null) {
      return "No Activity";
    }

    if (data?.latest_req != null && data?.latest_shift == null) {
      return data.latest_req?.created_at >= data.latest_req?.updated_at
        ? formatDate(data.latest_req?.created_at)
        : formatDate(data.latest_req?.updated_at);
    }

    if (data?.latest_req == null && data?.latest_shift != null) {
      return data.latest_shift?.created_at >= data.latest_shift?.updated_at
        ? formatDate(data.latest_shift?.created_at)
        : formatDate(data.latest_shift?.updated_at);
    }

    if (data?.latest_req != null && data?.latest_shift != null) {
      const latestReqTimestamp =
        data.latest_req?.created_at >= data.latest_req?.updated_at
          ? data.latest_req?.created_at
          : data.latest_req?.updated_at;

      const latestShiftTimestamp =
        data.latest_shift?.created_at >= data.latest_shift?.updated_at
          ? data.latest_shift?.created_at
          : data.latest_shift?.updated_at;

      return latestReqTimestamp >= latestShiftTimestamp
        ? formatDate(latestReqTimestamp)
        : formatDate(latestShiftTimestamp);
    }

    return "No Activity";
  };

  return (
    <tr className="hover:bg-cGridView">
      <td className="border-collapse border-[1px] border-cNmSelect py-s10 text-center px-s10">
        {num}
      </td>

      <td className="border-r-[1px] border-b p-s10 text-left max-w-[150px] min-w-[150px] truncate">
        <div className="flex items-center">
          <div className="mx-s5 max-w-[40px] min-w-[40px]">
            <CommonImage
              className="rounded-full w-s40 h-s40 grow-0"
              src={data?.image}
              alt=""
            />
          </div>
          <div className="capitalize items-center pl-s10 truncate">
            {data?.name ? data?.name : ""}
          </div>
        </div>
      </td>

      <td className="border-collapse border-[1px] border-cNmSelect py-s10 text-left">
        <span className="pl-s10">{data?.email ? data?.email : ""}</span>
      </td>

      <td className="border-collapse border-[1px] border-cNmSelect py-s10 text-left capitalize">
        <span className="pl-s10">{data?.cvr ? data?.cvr : ""}</span>
      </td>

      <td className="border-collapse border-[1px] border-cNmSelect py-s10 text-center">
        <span className="pl-s10">{lastActivity(data)}</span>
      </td>

      <td className="border-collapse border-[1px] border-cNmSelect py-s10 text-center">
        <span className="pl-s10">
          {data?.is_active ? "Active" : "Inactive"}
        </span>
      </td>
    </tr>
  );
}

export default LowCompanyRow;
