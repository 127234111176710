import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useCompanyStore, { commonUserDelete } from '../../../../App/stores/CompanyStore';
import CommonButton from '../../../../Components/Button/CommonButton';
import CommonInput from '../../../../Components/Input/CommonInput';
import CommonModal from '../../../../Components/Modal/CommonModal';

export default function DeleteCompanyModal() {
  const { showDeleteCompanyModal, setShowDeleteCompanyModal, companyDetails, companyDeleteID } = useCompanyStore();
  const { t } = useTranslation();

  const [confirmationText, setConfirmationText] = useState("");
  
  const navigateTo = useNavigate();
  useEffect(() => setConfirmationText(""), [companyDeleteID]);

  return (
    <CommonModal
      showModal={showDeleteCompanyModal}
      setShowModal={setShowDeleteCompanyModal}
      modalTitle={t("Confirmation")}
      mainContent={
        <form onSubmit={(e) => e.preventDefault()} className='pt-5'>
          <div className="text-base text-center">
            {t("Are you sure you want to delete this company account?")}
          </div>

          <div className="py-5">
            <CommonInput
              required={true}
              disableCopy={true}
              disablePaste={true}
              label={
                <span className="mt-5 text-center normal-case font-normal select-none text-fs14">
                  Please type{" "}
                  <span className='font-bold'>'{companyDetails?.name}'</span>
                  {" "}to confirm.
                </span>
              }
              placeholder="Enter company name"
              value={confirmationText}
              onChange={(e) => setConfirmationText(e.target.value)}
            />
          </div>

          <div className="flex justify-center items-center">
            <CommonButton
              onClick={async() => {
                // return console.log(companyDeleteID);
                let delSuccess = await commonUserDelete(companyDeleteID);
                if (delSuccess) {
                  setConfirmationText("");
                  navigateTo("/users/companies");
                  setShowDeleteCompanyModal(false);
                }
              }}
              isDisabled={confirmationText === companyDetails?.name ? false : true}
              colorType={confirmationText === companyDetails?.name ? 'danger' : 'base'}
              type='submit'
              btnLabel={t("I understand, delete")}
              width="w-[180px]"
            />
          </div>
        </form>
      }
    />
  )
}
