/* eslint-disable react-hooks/exhaustive-deps */
import { Rating } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { k_data_set } from "../../../../App/Utility/const";
import useGeneralStore from "../../../../App/stores/GeneralStore";
import useRequestStore, {
  definePickupAndDeliveryStatus,
} from "../../../../App/stores/RequestStore";
import Accordion from "../../../../Components/Accordion";
import {
  checkIsCustomerProfileShow,
  defineNotPlannedDeliveryDate,
  formatDate,
  formatTime,
} from "../../../../Utility/UtilityFunctions";
import CommonListItem from "./components/CommonListItem";
import ImageViewerModal from "./modal/ImageViewerModal";

const RequestDetails = ({ type = "" }) => {
  const [toggled_accordion, setToggledAccordion] = useState("pickup_overview");
  const {
    request_details,
    request_active_section,
    generateStops,
    not_planned_stops,
    setBiddingCompaniesDetailsModal,
    setBiddingCompaniesDetails,
  } = useRequestStore();
  const { setShowImageViewerModal, setImageViewerModalUrl, showImageViewerModal } = useGeneralStore();
  const [title, setTitle] = useState("Saved");
  const [selectedStops, setSelectedStops] = useState(false);
  const { customer_id, company_id } = useParams();

  useEffect(() => {
    if (request_active_section === k_data_set.saved) {
      setTitle("Saved");
    } else if (request_active_section === k_data_set.in_bidding) {
      setTitle("In Bidding");
    } else if (request_active_section === k_data_set.awarded) {
      setTitle("Upcoming");
    } else if (request_active_section === k_data_set.ongoing) {
      setTitle("Ongoing");
    } else if (request_active_section === k_data_set.history) {
      if (request_details?.user?.user_role === "customer")
        setTitle("Completed");
      else setTitle("History");
    } else if (request_active_section === k_data_set.invitation) {
      setTitle("Invitation");
    } else if (request_active_section === k_data_set.not_planned) {
      setTitle("Not Planned");
    } else if (request_active_section === k_data_set.completed) {
      setTitle("Completed");
    }
  }, [request_active_section]);

  useEffect(() => {
    if (request_active_section === k_data_set.not_planned) {
      generateStops({
        stops: request_details?.stops,
        date: request_details?.pickup_date,
      });
    }
    setToggledAccordion("pickup_overview");
    setSelectedStops(false);
  }, [request_details]);

  const { t } = useTranslation();

  const showDuration = () => {
    if(request_details?.user?.user_role === "company") return true;
    else if(request_details?.user?.user_role === "customer" && request_active_section === k_data_set.awarded) return true;
    else return false
  }

  return (
    <div className="p-4 bg-white shadow rounded-xl">
      <div className="text-xl font-bold">
        {type === "global-request" ? "Global Request" : title}
      </div>
      <div className="text-sm font-semibold capitalize truncate pt-s10">
        Request ID - {request_details?.request_id}
      </div>
      <Accordion
        accordion_type="dependent"
        isInitOpen={toggled_accordion === "pickup_overview" ? true : false}
        customOnClick={() => setToggledAccordion("pickup_overview")}
        header={
          <div className="flex space-x-2">
            <div className="py-2 text-lg font-semibold text-gray-700">
              Pickup Overview
            </div>
            {request_active_section === k_data_set.ongoing ||
            request_active_section === k_data_set.completed ||
            request_active_section === k_data_set.history ? (
              <div className="flex items-center justify-center">
                ({`${definePickupAndDeliveryStatus(request_details, "pickup")}`}
                )
              </div>
            ) : (
              ""
            )}
          </div>
        }
        body={
          <div className="h-full pr-s10 space-y-2">
            <CommonListItem
              title={t("Title")}
              value={request_details?.title ?? "No title"}
            />
            <CommonListItem
              title={t("Type")}
              value={request_details?.transport_type ?? "No type"}
            />
            <CommonListItem
              title={t("Pickup Address")}
              value={request_details?.pickup_address ?? "No pickup address"}
            />
            <CommonListItem
              title={t("Floor Number")}
              value={request_details?.pickup_floor_number ?? "No floor number"}
            />
            <CommonListItem
              title={t("Pickup Date")}
              value={
                request_details?.pickup_date
                  ? formatDate(request_details?.pickup_date)
                  : "No pickup date"
              }
            />
            <CommonListItem
              title={t("Pickup Start Time")}
              value={
                request_details?.pickup_start_time
                  ? formatTime(request_details?.pickup_start_time)
                  : "No pickup start time"
              }
            />
            <CommonListItem
              title={t("Pickup End Time")}
              value={
                request_details?.pickup_end_time
                  ? formatTime(request_details?.pickup_end_time)
                  : "No pickup end time"
              }
            />
            <CommonListItem
              title={t("Pickup Duration(min)")}
              value={
                request_details?.pickup_duration
                  ? request_details?.pickup_duration + " min"
                  : "No pickup duration"
              }
            />
            <CommonListItem
              title={t("Comment")}
              value={request_details?.pickup_comment ?? "No pickup comment"}
            />
            {request_details?.pickup_attachment && (
              <div className="flex justify-between">
                <div className="text-fs14 font-fw400 text-[#828282] capitalize">
                  Attachment
                </div>
                <div
                  onClick={() => {
                    setImageViewerModalUrl(request_details?.pickup_attachment);
                    setShowImageViewerModal(true);
                  }}
                  className="text-fs14 font-fw400 text-[#2257AA] underline cursor-pointer"
                >
                  Attachment
                </div>
              </div>
            )}

            {(request_active_section === k_data_set.ongoing ||
              request_active_section === k_data_set.history ||
              request_active_section === k_data_set.completed) &&
            request_details?.pickup_driver_comment ? (
              <CommonListItem
                title={t("Driver Comment")}
                value={request_details?.pickup_driver_comment ?? "No comment"}
              />
            ) : (
              ""
            )}

            {(request_active_section === k_data_set.ongoing ||
              request_active_section === k_data_set.history ||
              request_active_section === k_data_set.completed) && (
              <>
                {request_details?.pickup_driver_attachment && (
                  <div className="flex justify-between">
                    <div className="text-fs14 font-fw400 text-[#828282] capitalize">
                      {" "}
                      Driver Attachment
                    </div>
                    <div
                      onClick={() => {
                        setImageViewerModalUrl(
                          request_details?.pickup_driver_attachment
                        );
                        setShowImageViewerModal(true);
                      }}
                      className="text-fs14 font-fw400 text-[#2257AA] underline cursor-pointer"
                    >
                      Attachment
                    </div>
                  </div>
                )}

                {request_details?.pickup_driver_signature && (
                  <div className="flex justify-between">
                    <div className="text-fs14 font-fw400 text-[#828282] capitalize">
                      Signature
                    </div>
                    <div
                      onClick={() => {
                        setImageViewerModalUrl(
                          request_details?.pickup_driver_signature
                        );
                        setShowImageViewerModal(true);
                      }}
                      className="text-fs14 font-fw400 text-[#2257AA] underline cursor-pointer"
                    >
                      Signature
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        }
      />

      {request_details?.stops?.map((item, index) => (
        <>
          <Accordion
            accordion_type="dependent"
            isInitOpen={
              toggled_accordion === "biddings" && selectedStops === index
            }
            customOnClick={() => {
              setToggledAccordion("biddings");
              setSelectedStops(index);
            }}
            header={
              <div className="flex space-x-2">
                <div className="py-2 text-lg font-semibold text-gray-700">
                  {`${t("Delivery")} ${index + 1}`}
                </div>
                {request_active_section === k_data_set.ongoing ||
                request_active_section === k_data_set.completed ||
                request_active_section === k_data_set.history ? (
                  <div className="flex items-center justify-center">
                    ({`${definePickupAndDeliveryStatus(item, "delivery")}`})
                  </div>
                ) : (
                  ""
                )}
              </div>
            }
            body={
              <div className="h-full pr-s10 space-y-2 ">
                <CommonListItem
                  title={t("Delivery Address")}
                  value={item?.address ?? "No address"}
                />
                <CommonListItem
                  title={t("Floor Number")}
                  value={item?.floor_number ?? "No floor number"}
                />
                {item?.products?.map((product, index) => (
                  <CommonListItem
                    key={index}
                    title={t(`Product ${index + 1}`)}
                    value={product?.text ?? "No products"}
                  />
                ))}
                <CommonListItem
                  title={t("Comment")}
                  value={item?.comment ?? "No comment"}
                />
                {item?.attachment && (
                  <div className="flex justify-between">
                    <div className="text-fs14 font-fw400 text-[#828282] capitalize">
                      Attachment
                    </div>
                    <div
                      onClick={() => {
                        setImageViewerModalUrl(item?.attachment);
                        setShowImageViewerModal(true);
                      }}
                      className="text-fs14 font-fw400 text-[#2257AA] underline cursor-pointer"
                    >
                      Attachment
                    </div>
                  </div>
                )}

                {company_id &&
                  request_active_section !== k_data_set.invitation &&
                  request_active_section !== k_data_set.in_bidding && (
                    <>
                      {request_active_section !== k_data_set.not_planned &&
                      type !== "global-request" ? (
                        <>
                          <CommonListItem
                            title={t("Delivery Date")}
                            value={
                              item?.date ? formatDate(item?.date) : "No date"
                            }
                            className="my-[20px]"
                          />
                          <CommonListItem
                            title={t("Delivery Time")}
                            value={
                              item?.start_time
                                ? formatTime(item?.start_time)
                                : "No start time"
                            }
                            className="my-[20px]"
                          />
                        </>
                      ) : (
                        ""
                      )}

                      {request_active_section === k_data_set.not_planned &&
                        request_details?.awarded?.status === "init" && (
                          <>
                            <CommonListItem
                              title={t("Delivery Date")}
                              value={defineNotPlannedDeliveryDate(
                                request_details?.pickup_date,
                                not_planned_stops[index]?.start_time,
                                request_details?.pickup_start_time
                              )}
                            />
                          
                            <CommonListItem
                              title={t("Delivery Time")}
                              value={
                                not_planned_stops[index]?.start_time ?
                                not_planned_stops[index]?.start_time :
                                "No delivery time"
                              }
                            />

                            {/* {not_planned_stops[index]?.start_time ? <Tooltip title={t('Clear delivery time')} color={'#F89818'} >
                                                            <img onClick={() => {
                                                                updateStopInfo(index, 'start_time', '')
                                                                updateStopInfo(index, 'end_time', '')
                                                            }} className='w-4 h-4 cp' src={iRedCancel} alt="" srcset="" />
                                                        </Tooltip> : <></>} */}
                          </>
                        )}
                    </>
                  )}
                {showDuration() && <CommonListItem
                  title={t("Duration (Min)")}
                  value={
                    item?.duration ? item?.duration + " min" : "No duration"
                  }
                />}
                {(request_active_section === k_data_set.ongoing ||
                  request_active_section === k_data_set.completed ||
                  request_active_section === k_data_set.history) && (
                  <>
                    {item?.driver_comment && (
                      <CommonListItem
                        title={t("Driver Comment")}
                        value={item?.driver_comment ?? "No comment"}
                      />
                    )}

                    {item?.driver_attachment && (
                      <div className="flex justify-between">
                        <div className="text-fs14 font-fw400 text-[#828282] capitalize">
                          {" "}
                          Driver Attachment
                        </div>
                        <div
                          onClick={() => {
                            setImageViewerModalUrl(item?.driver_attachment);
                            setShowImageViewerModal(true);
                          }}
                          className="text-fs14 font-fw400 text-[#2257AA] underline cursor-pointer"
                        >
                          Attachment
                        </div>
                      </div>
                    )}

                    {item?.driver_signature && (
                      <div className="flex justify-between">
                        <div className="text-fs14 font-fw400 text-[#828282] capitalize">
                          Driver Signature
                        </div>
                        <div
                          onClick={() => {
                            setImageViewerModalUrl(item?.driver_signature);
                            setShowImageViewerModal(true);
                          }}
                          className="text-fs14 font-fw400 text-[#2257AA] underline cursor-pointer"
                        >
                          Signature
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            }
          />
        </>
      ))}
      {customer_id &&
      request_active_section === k_data_set.in_bidding &&
      request_details?.biddings?.length > 0 ? (
        <Accordion
          accordion_type="dependent"
          isInitOpen={toggled_accordion === "bidding_companies" ? true : false}
          customOnClick={() => setToggledAccordion("bidding_companies")}
          header={
            <div className="py-2 text-lg font-semibold text-gray-700">
              Bidding Companies
            </div>
          }
          body={
            <div className="h-full space-y-2">
              {request_details?.biddings?.map((item, index) => (
                <div key={index}>
                  <div
                    onClick={() => {
                      setBiddingCompaniesDetails(item);
                      setBiddingCompaniesDetailsModal(true);
                    }}
                    className="flex items-center justify-between px-4 py-3 mb-3 font-bold text-gray-600 bg-gray-100 rounded-lg cursor-pointer"
                  >
                    <div className="w-[410px] truncate capitalize">
                      {item?.company_info?.name}
                    </div>
                    <div>{"DKK " + item?.budget.toLocaleString("da-DK")}</div>
                  </div>
                </div>
              ))}
            </div>
          }
        />
      ) : (
        ""
      )}

      {customer_id &&
        (request_active_section === k_data_set.awarded ||
          request_active_section === k_data_set.ongoing ||
          request_active_section === k_data_set.history) && (
          <Accordion
            accordion_type="dependent"
            isInitOpen={toggled_accordion === "biddings-details" ? true : false}
            customOnClick={() => setToggledAccordion("biddings-details")}
            header={
              <div className="py-2 text-lg font-semibold text-gray-700">
                Bidding Details
              </div>
            }
            body={
              <div className="h-full pr-s10 space-y-2">
                <>
                  <CommonListItem
                    title={t("Company Name")}
                    value={request_details?.awarded_company?.name ?? "No name"}
                  />
                  <CommonListItem
                    title={t("Budget")}
                    value={`DKK ${
                      request_details?.awarded?.budget?.toLocaleString(
                        "da-DK"
                      ) ?? "0"
                    }`}
                  />
                  <CommonListItem
                    title={t("Comment")}
                    value={request_details?.awarded?.details ?? "No comment"}
                  />
                </>
              </div>
            }
          />
        )}

      {(type !== "global-request" &&
        checkIsCustomerProfileShow(request_active_section) &&
        company_id) ||
      (company_id &&
        parseInt(request_details?.is_global) === 0 &&
        request_active_section === "in_bidding") ? (
        <Accordion
          accordion_type="dependent"
          isInitOpen={toggled_accordion === "customer-profile" ? true : false}
          customOnClick={() => setToggledAccordion("customer-profile")}
          header={
            <div className="py-2 text-lg font-semibold text-gray-700">
              Customer Profile
            </div>
          }
          body={
            <div className="h-full pr-s10 space-y-2">
              <>
                <CommonListItem
                  title={t("Customer Name")}
                  value={request_details?.user?.name ?? "No name"}
                />
                <CommonListItem
                  title={t("Email")}
                  value={request_details?.user?.email ?? "No email"}
                />
                <CommonListItem
                  email={true}
                  title={t("Phone")}
                  value={request_details?.user?.phone ?? "No phone number"}
                />
              </>
            </div>
          }
        />
      ) : (
        ""
      )}

      {(request_active_section === k_data_set.saved ||
        request_active_section === k_data_set.in_bidding ||
        request_active_section === k_data_set.not_planned ||
        request_active_section === k_data_set.awarded ||
        request_active_section === k_data_set.ongoing ||
        request_active_section === k_data_set.history ||
        request_active_section === k_data_set.completed) &&
      company_id ? (
        <Accordion
          accordion_type="dependent"
          isInitOpen={toggled_accordion === "proposal-info" ? true : false}
          customOnClick={() => setToggledAccordion("proposal-info")}
          header={
            <div className="py-2 text-lg font-semibold text-gray-700">
              Proposal Info
            </div>
          }
          body={
            <div className="h-full pr-s10 space-y-2">
              <>
                <CommonListItem
                  title={t("Budget")}
                  value={
                    request_details?.my_bid?.budget
                      ? `DKK ${request_details?.my_bid?.budget.toLocaleString(
                          "da-DK"
                        )}`
                      : "DKK 0"
                  }
                />
                <CommonListItem
                  title={t("Customer")}
                  value={
                    request_details?.my_bid?.customer_name ??
                    "Created by company"
                  }
                />
                <CommonListItem
                  title={t("Description")}
                  value={request_details?.my_bid?.details ?? "No description"}
                />
              </>
            </div>
          }
        />
      ) : (
        ""
      )}

      {((request_active_section === k_data_set.saved && request_details?.shift_id) ||
        request_active_section === k_data_set.awarded ||
        request_active_section === k_data_set.ongoing ||
        request_active_section === k_data_set.completed ||
        request_active_section === k_data_set.history) &&
        company_id && (
          <Accordion
            accordion_type="dependent"
            isInitOpen={toggled_accordion === "shift-details" ? true : false}
            customOnClick={() => setToggledAccordion("shift-details")}
            header={
              <div className="py-2 text-lg font-semibold text-gray-700">
                Shift Details
              </div>
            }
            body={
              <div className="h-full pr-s10 space-y-2">
                <>
                  <CommonListItem
                    title={t("Driver Name")}
                    value={
                      request_details?.driver?.name
                        ? request_details.driver?.name
                        : request_details?.shift_with_car_and_driver?.driver_user?.name || "No name"
                    }
                  />
                  <CommonListItem
                    title={t("Vehicle number")}
                    value={
                      request_details?.car_license_number ? request_details?.car_license_number: request_details?.shift_with_car_and_driver?.car?.name || "No license number"
                    }
                  />
                  {request_active_section === k_data_set.awarded && (
                    <>
                      <CommonListItem
                        title={t("Date & time")}
                        value={
                          (formatDate(
                            request_details?.shift_plan?.start_date
                          ) ?? "--") +
                          ", " +
                          (formatTime(
                            request_details?.shift_plan?.start_time
                          ) ?? "00") +
                          " - " +
                          (formatTime(request_details?.shift_plan?.end_time) ??
                            "00")
                        }
                      />
                    </>
                  )}
                </>
              </div>
            }
          />
        )}

      {request_active_section === k_data_set.history ? (
        <Accordion
          accordion_type="dependent"
          isInitOpen={toggled_accordion === "acknowledgement" ? true : false}
          customOnClick={() => setToggledAccordion("acknowledgement")}
          header={
            <div className="py-2 text-lg font-semibold text-gray-700">
              {request_details?.is_rated ? t("Review") : t("Acknowledgement")}
            </div>
          }
          body={
            <div className="h-full pr-s10 space-y-2">
              {request_details?.acknowledge ? (
                <>
                  {
                    <>
                      <CommonListItem
                        title={t("Acknowledgement Comment")}
                        value={request_details?.acknowledge ?? "No comment"}
                      />
                    </>
                  }

                  {request_details?.rate && (
                    <div className="flex justify-between">
                      <div
                        className={`text-fs14 font-fw400 text-[#828282] capitalize`}
                      >
                        Rating
                      </div>
                      <Rating
                        name="size-large"
                        size="small"
                        value={Math.round(request_details?.rate) ?? 0}
                        readOnly={true}
                        datatype={"number"}
                      />
                    </div>
                  )}

                  {request_details?.is_rated ? (
                    <>
                      <CommonListItem
                        title={t("Comment")}
                        value={request_details?.review ?? "No comment"}
                      />
                    </>
                  ) : (
                    <div className="flex items-center justify-center"></div>
                  )}
                </>
              ) : (
                <div className="flex items-center justify-center text-center">
                  Company has not been acknowledged yet!
                </div>
              )}
            </div>
          }
        />
      ) : (
        <></>
      )}

      {showImageViewerModal ? <ImageViewerModal /> : ""}
    </div>
  );
};

export default RequestDetails;
