/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import useSettingsStore, { handleAdminAccess, updateAdminAccess } from '../../../App/stores/SettingsStore';
import CommonButton from '../../../Components/Button/CommonButton';
import RichTextEditor from '../../../Components/TextEditor/RichTextEditor';


function AdminAccessInfo({
    title = ""
}) {
    const { t } = useTranslation();
    const { adminAccessData, setAdminAccessData, editNow, setEditNow, editAdminAccessData, setEditAdminAccessData } = useSettingsStore();

    useEffect(() => {
        handleAdminAccess(title);
    }, [title])

    return (
        <div>
            <div className="text-2xl font-bold pb-5 capitalize">{t("Admin Access Description")}</div>
            {editNow === "terms" ?
                <div>
                    <div className="h-[318px] mb-10">
                        <RichTextEditor
                            placeholder="Write your description..."
                            value={editAdminAccessData}
                            onChange={(e) => setEditAdminAccessData(e)}
                        />
                    </div>
                    <div className="flex justify-between items-center py-5">
                        <CommonButton btnLabel={t("cancel")} colorType='danger' onClick={() => setEditNow("")} />
                        <CommonButton btnLabel={t("update")} onClick={() => {
                            let updateDone = updateAdminAccess(
                                editAdminAccessData
                            );
                            if (updateDone) {
                                setEditNow("");
                                setEditAdminAccessData(null)
                            }
                        }} />
                    </div>
                </div>
                :
                <div>
                    <div className="bg-gray-200 h-[25vh] p-5 rounded-br10 overflow-y-auto">
                        <div className="prose prose-lg" dangerouslySetInnerHTML={{
                            __html: adminAccessData
                        }} />
                    </div>
                    <div className="flex justify-end pt-5">
                        <CommonButton btnLabel={t("edit")} onClick={() => {
                            setEditNow("terms");
                            setEditAdminAccessData(adminAccessData);
                        }} />
                    </div>
                </div>
            }


        </div>
    )
}

export default AdminAccessInfo
