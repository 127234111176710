import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useLicenseStore, { updateLicense } from "../../../App/stores/LicenseStore";
import CommonButton from "../../../Components/Button/CommonButton";
import CommonInput from "../../../Components/Input/CommonInput";
import { CalculateMonthsYears } from "../../../Utility/UtilityFunctions";

function UpdatePackage({ title, start_date, end_date, app_type, }) {
  const { licensePackID, allLicenseList, updateForm, setUpdateForm, setShowUpdateLicense } = useLicenseStore();
  const { t } = useTranslation();

  const [targetLicense, setTargetLicense] = useState();
  useEffect(() => {
    let temp = allLicenseList.find((item) => item.id === licensePackID);
    setTargetLicense(temp);
    console.log("To Update:::", temp);

    const { month, year } = CalculateMonthsYears(temp?.duration);

    setUpdateForm({
      ...updateForm,
      id: temp?.id,
      number: temp?.number,
      price: temp?.price,
      duration: temp?.duration,
      description: temp?.description,

      month: month,
      year: year,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licensePackID, updateForm?.id]);

  return (
    <>
      <div className="w-full bg-white p-5 rounded-md mt-5 md:mt-0 md:w-1/2">

        <div className="text-fs28 font-semibold text-gray-700">{t("Update Package")}</div>

        <form className="mt-5" onSubmit={async(e) => {
          e.preventDefault()
          await updateLicense({
            id: updateForm?.id,
            number: targetLicense.number !== updateForm.number ? updateForm.number : "",
            price: updateForm?.price,
            description: updateForm?.description,
            duration: (updateForm?.month * 30) + (updateForm?.year * 365),
          });
          setShowUpdateLicense(false)
        }}>

          <div className="">
            <CommonInput
              label={t("Package Name")}
              onChange={(e) => setUpdateForm({ ...updateForm, "number": e.target.value })}
              name={"number"}
              value={updateForm?.number}
              placeholder={t("Package Name")}
              required={true}
            />
          </div>

          <div className="py-5">
            <CommonInput
              label={t("Price")}
              min_number={1}
              onChange={(e) => setUpdateForm({ ...updateForm, "price": e.target.value })}
              type={"number"}
              name={"price"}
              value={updateForm?.price}
              placeholder={t("Package price")}
              required={true}
            />
          </div>

          <div className="">
            <CommonInput
              textarea={true}
              max_char_limit={255}
              is_show_char_limit={true}
              rows={7}
              label={t("Description")}
              onChange={(e) => setUpdateForm({ ...updateForm, "description": e.target.value })}
              type={"text"}
              name={"description"}
              value={updateForm?.description}
              placeholder={t("Package description")}
              required={true}
            />
          </div>

          <div className="pt-5 flex items-center space-x-5">
            <CommonInput
              required={true}
              onChange={(e) => setUpdateForm({ ...updateForm, "year": e.target.value })}
              label={t("Year")}
              type={"number"}
              name={"year"}
              value={updateForm?.year}
              placeholder={t("Year")}
              min_number={0}
              max_number={10}
            />
            <CommonInput
              required={true}
              onChange={(e) => setUpdateForm({ ...updateForm, "month": e.target.value })}
              label={t("Month")}
              type={"number"}
              name={"month"}
              value={updateForm?.month}
              placeholder={t("Month")}
              min_number={updateForm?.year && updateForm?.year != 0 ? 0 : 1}
              max_number={11}
            />
          </div>
          <div className="flex justify-center pt-5">

            <CommonButton
              btnLabel={t("Save Changes")}
              type="submit"
            />
          </div>
        </form>
      </div>
    </>
  );
}

export default UpdatePackage;
