import { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import { useDebounce } from "use-debounce";
// import useUpdateLogger from "../../App/Hooks/useUpdateLogger";
import useGeneralStore from "../../App/stores/GeneralStore";

// Author:: coded by tamzidpeace(arafat) on 02/06/2022 for favorite address & profile sections
function InputAutoCompleteType2({
    label,
    placeholder,
    name,
    address = null,
    latName,
    lngName,
    doSearch,
    setDoSearch,
    changeValue,
    onSelectItem = () => { },
    classNameInp,
    required = false,
    disabled = false,
}) {
    // const { setChange, changeAddressLatLng } = useContext(FavoriteAddressContext);

    const [key] = useDebounce(address, 300); // 0.3 sec delay/debounce
    const [items, setItems] = useState([]); // address suggestion items
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [isSearching, setIsSearching] = useState(false);
    const [showSuggestion, setShowSuggestion] = useState(false);
    const [onChangeAddress, setOnChangeAddress] = useState(false);
    const { setCompanyCity } = useGeneralStore();

    // set search address result to generate suggestion list
    useEffect(() => {
        if (items?.length > 0) {
            setShowSuggestion(true);
            setSelectedIndex(null);
        } else {
            setShowSuggestion(false);
            setSelectedIndex(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    // show suggestions if user is typing and get any result [1 sec debounce]. for debounce, after selecting any suggestion,
    // it will not show suggestions again.
    useEffect(() => {
        // console.log("KEY :: ", key);
        if (doSearch) {
            searchAddress(key);
        } else {
            setDoSearch(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [key, onChangeAddress]);

    // check user is type or not
    const handleOnChangeAddress = (e) => {
        changeValue(e.target.name, e.target.value);
        setOnChangeAddress(true);
        // console.log("ADDRESS", e.target.value);
    };

    // select address from suggestion list on mouse click
    const handleSelectItem = async (item) => {
        setCompanyCity(item?.address?.city)
        changeValue(name, item.title);
        onSelectItem(name, item.title);
        setShowSuggestion(false);
        setDoSearch(false);
        setLatLng(item.position);
    };

    const setLatLng = (position) => {
        // console.log("position", position);
        if (position === null) {
            changeValue(latName, null);
            changeValue(lngName, null);
        } else {
            changeValue(latName, position.lat);
            changeValue(lngName, position.lng);
        }
    };

    // keyboard up/down/enter key event handle
    document.onkeydown = function (e) {
        switch (e.key) {
            case "ArrowUp":
                if (selectedIndex === 0) return;
                setSelectedIndex(selectedIndex - 1);
                // stop scrolling while arrow key is pressed
                document.body.classList.add("no-scroll");
                break;
            case "ArrowDown":
                if (selectedIndex === items.length - 1) return;
                setSelectedIndex(selectedIndex === null ? 0 : selectedIndex + 1);
                // stop scrolling while arrow key is pressed
                document.body.classList.add("no-scroll");
                break;
            case "Enter":
                setCompanyCity(items[selectedIndex]?.address?.city)
                changeValue(name, items[selectedIndex].title);
                setShowSuggestion(false);
                setDoSearch(false);
                setLatLng(items[selectedIndex].position);
                break;
            case "Escape":
                setShowSuggestion(false);
                setDoSearch(false);
                break;
            default:
                return 0;
        }
    };

    // handle mouse click outside suggestion list
    document.onclick = function (e) {
        setShowSuggestion(false);
        setDoSearch(false);
    };

    // api functions
    function searchAddress(queryText) {
        if (!queryText || queryText === null || doSearch === false) return;
        setLatLng(null); // reset lat lng
        setIsSearching(true);
        return fetch(
            "https://devabz.com/api/v3/geocode/autocomplete?text=" +
            queryText +
            "&size=10"
        )
            .then((response) => response.json())
            .then((json) => {
                // console.log('addresses', json.items);
                setItems(json.items);
                setIsSearching(false);
            });
    }

    function searchLatLng(locationID) {
        // console.log(locationID);
        return fetch(
            "https://geocode.search.hereapi.com/v1/geocode?apiKey=xY9bb2kSsO92hUWv58fV0cRA-CRNtH95VCkHhvgnD1U&q=" +
            locationID

        )
            .then((response) => response.json())
            .then((json) => {
                // console.log('geo-code: ', json);
                try {
                    const position =
                        json.items[0].position;
                    setLatLng(position);
                } catch (error) {
                    setLatLng(null);
                }
            });
    }

    return (
        <div className="relative mb-5">
            <div className={`font-semibold text-gray-600 text-left text-fs16  mb-2 ${required === true ? "req-field" : ""} `}>
                {label}
            </div>

            <div className="relative flex items-center justify-center">
                <input
                disabled={disabled}
                    required={required}
                    className={`bg-cMoreLiteGrey px-4 py-2 w-full text-sm rounded-br5 overflow-ellipsis ${classNameInp}`}
                    type={"search"}
                    onChange={handleOnChangeAddress}
                    value={address}
                    name={name}
                    placeholder={placeholder}
                />
            </div>

            {showSuggestion && (
                <div className="w-full max-h-[150px] overflow-y-auto text-sm absolute z-20 rounded-none shadow-sm mt-[2px] ">
                    {!isSearching ? (
                        items?.map((item, index) => (
                            <div
                                key={item.id}
                                onClick={() => handleSelectItem(item)}
                                onMouseOver={() => setSelectedIndex(index)}
                                className={` ${index === selectedIndex
                                    ? "bg-[#ebcfa8]"
                                    : "bg-cMoreLiteGrey"
                                    } pl-4 pr-2 rounded-none shadow-md cursor-pointer py-1`}
                            >
                                {item.title}
                            </div>
                        ))
                    ) : (
                        <div className="flex items-center justify-center w-full h-16 shadow-md bg-cMoreLiteGrey">
                            <Oval
                                ariaLabel="loading-indicator"
                                height={30}
                                width={30}
                                strokeWidth={3}
                                strokeWidthSecondary={3}
                                color="#f89818"
                                secondaryColor="bg-gray-600"
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default InputAutoCompleteType2;
