/* eslint-disable react-hooks/exhaustive-deps */
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import useCompanyStore from "../../../../../../App/stores/CompanyStore";
import useShiftPlannerStore, {
  checkCanShiftStart,
  checkIfShiftIsExpired,
  checkIfShiftIsRunning,
} from "../../../../../../App/stores/ShiftPlannerStore";
import CommonModal from "../../../../../../Components/Modal/CommonModal";
import { ShiftPlannerContext } from "../../../../../../Context/ShiftPlannerContext";
import { TrackOnlineContext } from "../../../../../../Context/TrackOnlineContext";
import {
  coDrivers,
  formatDate,
  formatTimeHourMinutes,
  secondsToHmsString,
} from "../../../../../../Utility/UtilityFunctions";
import ConfirmDelivery from "../../TrackOnline/Popup/ConfirmDelivery";
import ConfirmHold from "../../TrackOnline/Popup/ConfirmHold";
import ConfirmPauseShift from "../../TrackOnline/Popup/ConfirmPauseShift";
import ConfirmStopShift from "../../TrackOnline/Popup/ConfirmStopShift";
import ShiftPlannerBar from "../Bars/ShiftPlannerBar";
import PauseResumeShiftModal from "../Modals/PauseResumeShiftModal";
import StartStopShiftModal from "../Modals/StartStopShiftModal";
import ShiftPlannerList from "../ShiftLists/ShiftPlannerList";
import ShiftExpiredInfo from "./ShiftExpiredInfo";
import ShiftInMaintenanceInfo from "./ShiftInMaintenanceInfo";
import OverViewItem from "./OverViewItem";
import ShiftLogBookTable from "./ShiftLogBookTable";
import RouteOverview from "./RouteOverview";
import Summary from "../../../../../../Components/Summary";
import CustomStopListTable from "./CustomStopListTable";
import ShiftDetailsTable from "./ShiftDetailsTable";

const CommonShiftShiftDetails = () => {
  const {
    setIsShiftDetailsID,
    isShiftDetailsData,
    setShortMonth,
    setShortDateNo,
    getShiftPlannerList,
    setIsShiftHistory,
    setIsShiftDetailsData,
    clearDetailsPanel,
    setClearEditPanel,
    setClearHistoryDetailsPanel,
    setClearRequestDetailsPanel,
  } = useContext(ShiftPlannerContext);

  const {
    setShowDeliveryModal,
    setShowHoldModal,
    setShowIncompleteDeliveryModal,
    // setShiftAction,
    showDeliveryModal,
    showHoldModal,
    showIncompleteDeliveryModal,
    showStopShiftModal,
    setShowStopShiftModal,
    showPauseShiftModal,
    setShowPauseShiftModal,
  } = useContext(TrackOnlineContext);

  const [showDelModal, setShowDelModal] = useState(false);

  const [is_shift_in_two_days, setIsShiftInTwoDays] = useState(false);
  const [shortMonth2, setShortMonth2] = useState("");
  const [shortDateNo2, setShortDateNo2] = useState(0);

  const { setCompanyID } = useCompanyStore();
  const {
    isShowShiftPauseResumeModal,
    isShowShiftStartStopModal,
    setShowShiftPauseResumeModal,
    setShowShiftStartStopModal,
    hideShiftDetails,
  } = useShiftPlannerStore();

  const { driver_id, company_id } = useParams();

  useEffect(() => {
    if (isShiftDetailsData?.start_date) {
      let d_date = new Date(isShiftDetailsData.start_date.toString());
      const month = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      setShortMonth(month[d_date.getMonth()]);
      setShortDateNo(d_date.getDate());

      const start_date = isShiftDetailsData?.start_date;
      const end_date = isShiftDetailsData?.end_date;

      let d_date2 = new Date(isShiftDetailsData?.end_date?.toString());
      // console.log('start, end date', start_date, end_date);
      if (start_date === end_date) {
        setIsShiftInTwoDays(false);
      } else {
        setIsShiftInTwoDays(true);
        setShortMonth2(month[d_date2.getMonth()]);
        setShortDateNo2(d_date2.getDate());
      }
    }
    checkIfShiftIsRunning({ shiftInfo: isShiftDetailsData });
    checkCanShiftStart({ shiftInfo: isShiftDetailsData });
  }, [isShiftDetailsData]);

  useEffect(() => {
    if (clearDetailsPanel) {
      setIsShiftDetailsID(null);
      setIsShiftDetailsData({});
      getShiftPlannerList(company_id);
      setIsShiftHistory(false);
    }
    setClearEditPanel(false);
    setClearHistoryDetailsPanel(true);
    setCompanyID(company_id);
  }, []);

  const [summaryData, setSummaryData] = useState([]);

  useEffect(() => {
    setSummaryData();
    if (isShiftDetailsData?.is_maintenance === 1) {
      setSummaryData([
        {
          title: t("Status"),
          description: isShiftDetailsData?.status_show,
        },
        {
          title: t("License plate"),
          description: isShiftDetailsData?.car?.car_license_plate_number,
        },
        {
          title: t("Shift date"),
          description: formatDate(isShiftDetailsData?.start_date),
        },
        {
          title: t("End date"),
          description: formatDate(isShiftDetailsData?.end_date),
        },
        {
          title: t("Shift time"),
          description:
            formatTimeHourMinutes(
              isShiftDetailsData?.start_time
                ? isShiftDetailsData?.start_time
                : "00:00:00"
            ) +
            " - " +
            formatTimeHourMinutes(
              isShiftDetailsData?.end_time
                ? isShiftDetailsData?.end_time
                : "00:00:00"
            ),
        },
      ]);
    } else {
      setSummaryData([
        {
          title: t("Status"),
          description: isShiftDetailsData?.status_show,
        },
        {
          title: t("Driver name"),
          description: isShiftDetailsData?.driver_user?.name,
        },
        {
          title: t("Co-Driver name"),
          description: coDrivers(isShiftDetailsData?.shift_drivers),
        },
        {
          title: t("License plate"),
          description: isShiftDetailsData?.car?.car_license_plate_number,
        },
        {
          title: t("Shift date"),
          description: formatDate(isShiftDetailsData?.start_date),
        },
        {
          title: t("Shift time"),
          description:
            formatTimeHourMinutes(
              isShiftDetailsData?.start_time
                ? isShiftDetailsData?.start_time
                : "00:00:00"
            ) +
            " - " +
            formatTimeHourMinutes(
              isShiftDetailsData?.end_time
                ? isShiftDetailsData?.end_time
                : "00:00:00"
            ),
        },
      ]);
    }
  }, [isShiftDetailsData]);

  return (
    <>
      {/* top-title-bar */}
      <ShiftPlannerBar />

      <div className="grid grid-cols-12 gap-2 md:gap-4 2xl:gap-12">
        <div className="col-span-12 mt-5 md:col-span-5">
          <ShiftPlannerList />
        </div>

        {!isShiftDetailsData?.id || hideShiftDetails ? (
          <div className="col-span-12 mt-5 md:col-span-7">
            <div className="card">
              <div className="h-[40vh] flex justify-center text-center gs-text-placeholder font-semibold italic text-lg">
                <div className="flex items-center justify-center text-cPlaceholder">
                  {t("Select a Shift to see details")}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-span-12 mt-5 lg:col-span-7">
            <div className="flex card ">
              <div className="flex items-center justify-between pb-4">
                <div className="text-2xl font-bold">{t("Shift Details")}</div>
                {/* {checkIfShiftIsRunning({ shiftInfo: isShiftDetailsData }) ? (
                  ""
                  <Link to={`/users/companies/details/${company_id}/track-online/route-planner/${isShiftDetailsData.id}`}>
                    <CommonButtonOutlined
                      btnLabel={t("Go to Route Planner")}
                      type="submit"
                      width={"w-[180px]"}
                    />
                  </Link>
                ) : (
                  ""
                )} */}
              </div>

              <Summary content={summaryData} />

              {/* //r      shift ongoing, break state and others state */}
              {checkIfShiftIsRunning({ shiftInfo: isShiftDetailsData }) ? (
                <>
                  {isShiftDetailsData?.is_maintenance === 0 && (
                    <>
                      <div className="flex justify-between px-1 pt-5 text-center gs-text-icon-color">
                        <div
                          onClick={() => {
                            setClearRequestDetailsPanel(true);
                          }}
                        >
                          <OverViewItem
                            title={t("Requests")}
                            data={
                              (isShiftDetailsData?.request_completed ?? 0) +
                              "/" +
                              (isShiftDetailsData?.request_count ?? 0)
                            }
                            cursor=""
                          />
                        </div>
                        <OverViewItem
                          title={t("Stops")}
                          data={
                            (isShiftDetailsData?.stops_completed ?? 0) +
                            "/" +
                            (isShiftDetailsData?.stops_count ?? 0)
                          }
                          cursor=""
                        />
                        <OverViewItem
                          title={t("Packages")}
                          data={
                            (isShiftDetailsData?.products_completed ?? 0) +
                            "/" +
                            (isShiftDetailsData?.products_count ?? 0)
                          }
                          cursor=""
                        />
                      </div>

                      <div className="flex justify-between px-1 pt-5 text-center gs-text-icon-color">
                        <OverViewItem
                          title={t("Breaks")}
                          data={secondsToHmsString(
                            isShiftDetailsData?.breaks ?? 0
                          )}
                          cursor=""
                        />
                        <OverViewItem
                          title={t("Working hours")}
                          data={secondsToHmsString(
                            isShiftDetailsData?.work_time ?? 0
                          )}
                          cursor=""
                        />
                        <OverViewItem
                          title={t("Total hours")}
                          data={secondsToHmsString(
                            isShiftDetailsData?.shift_hours ?? 0
                          )}
                          cursor=""
                        />
                      </div>
                    </>
                  )}
                  {!isShiftDetailsData?.is_maintenance && (
                    <div className="flex flex-col pt-5 text-sm">
                      <span className="font-semibold gs-text-main-black">
                        {t("Shift Instruction")}
                      </span>
                      <span className="gs-text-body break-all whitespace-pre-line">
                        {isShiftDetailsData.comment}
                      </span>
                    </div>
                  )}
                </>
              ) : (
                <div className="">
                  {/* p-5 mb-5 bg-cListItem rounded-xl */}
                  {isShiftDetailsData?.is_maintenance === 0 && (
                    <>
                      <div className="flex justify-between px-1 pt-5 text-center gs-text-icon-color">
                        <div
                          onClick={() => {
                            setClearRequestDetailsPanel(true);
                          }}
                        >
                          <OverViewItem
                            title={t("Requests")}
                            data={
                              (isShiftDetailsData?.request_completed ?? 0) +
                              "/" +
                              (isShiftDetailsData?.request_count ?? 0)
                            }
                            cursor=""
                          />
                        </div>
                        <OverViewItem
                          title={t("Stops")}
                          data={
                            (isShiftDetailsData?.stops_completed ?? 0) +
                            "/" +
                            (isShiftDetailsData?.stops_count ?? 0)
                          }
                          cursor=""
                        />
                        <OverViewItem
                          title={t("Packages")}
                          data={
                            (isShiftDetailsData?.products_completed ?? 0) +
                            "/" +
                            (isShiftDetailsData?.products_count ?? 0)
                          }
                          cursor=""
                        />
                      </div>

                      <div className="flex justify-between px-1 pt-5 text-center gs-text-icon-color">
                        <OverViewItem
                          title={t("Breaks")}
                          data={secondsToHmsString(
                            isShiftDetailsData?.breaks ?? 0
                          )}
                          cursor=""
                        />
                        <OverViewItem
                          title={t("Working hours")}
                          data={secondsToHmsString(
                            isShiftDetailsData?.work_time ?? 0
                          )}
                          cursor=""
                        />
                        <OverViewItem
                          title={t("Total hours")}
                          data={secondsToHmsString(
                            isShiftDetailsData?.shift_hours ?? 0
                          )}
                          cursor=""
                        />
                      </div>
                    </>
                  )}

                  {!isShiftDetailsData?.is_maintenance && (
                    <div className="flex flex-col pt-5 text-sm">
                      <span className="font-semibold gs-text-main-black">
                        {t("Shift Instruction")}
                      </span>
                      <span className="gs-text-body break-all whitespace-pre-line">
                        {isShiftDetailsData.comment}
                      </span>
                    </div>
                  )}
                  {isShiftDetailsData?.is_maintenance === 1 ? (
                    isShiftDetailsData?.status_show !==
                      "Maintenance Completed" &&
                    isShiftDetailsData?.status_show !==
                      "Planned Maintenance" ? (
                      <ShiftInMaintenanceInfo />
                    ) : (
                      <></>
                    )
                  ) : checkIfShiftIsExpired({
                      shiftInfo: isShiftDetailsData,
                    }) ? (
                    <ShiftExpiredInfo />
                  ) : (
                    <div className="flex justify-center pt-5">
                      {/* {
                            checkCanShiftStart({ shiftInfo: isShiftDetailsData }) ?
                              <DefaultButton onSubmit={() => setShowShiftStartStopModal(true)} label={t("Start Shift")} />
                              :
                              <DefaultButton label={`${t("Start in")} ${isShiftDetailsData?.starts_in}`} canSubmit={false} width="60px" />
                          } */}
                    </div>
                  )}
                </div>
              )}

              {/*e     if any shift is on going state */}
              {/* {
                isShiftDetailsData.on_going_stop ? (
                  <div className="min-h-20 w-full mx-auto mt-5 p-5 bg-cListItem rounded-[10px] shadow-sm">
                    <div className="flex items-center justify-between w-full">
                      <div className="font-semibold text-xl max-w-[30vw] truncate">
                        {isShiftDetailsData?.on_going_stop
                          ? isShiftDetailsData?.on_going_stop?.title
                          : "NA"}
                      </div>
                      <div>
                        <Link to={`/users/companies/details/${company_id}/track-online/stop-details-of-shift-tracked?id=${isShiftDetailsData?.on_going_stop?.id}`}>
                          <img
                            onClick={() => {
                              // eslint-disable-next-line no-lone-blocks
                              {
                                // isShiftDetailsData.on_going_stop &&
                                // setSelectedStop(isShiftDetailsData.on_going_stop.id);
                              }
                            }}
                            src={iInfo}
                            alt=""
                            className="w-[20px] h-[20px] cursor-pointer select-none"
                          />
                        </Link>
                      </div>
                    </div>

                     <div className="w-full flex justify-between items-center pt-[10px]">
                      <div className="font-normal text-sm max-w-[30vw] truncate">
                        {isShiftDetailsData?.on_going_stop?.address ?? "NA"}
                      </div>
                      <div>
                        <a target={'_blank'} rel='noreferrer' href={`https://maps.google.com/?q=${isShiftDetailsData?.on_going_stop?.address}`}>
                          <img
                            src={iInfoLocationIcon}
                            alt=""
                            className="w-[20px] h-[20px] cursor-pointer select-none"
                          />
                        </a>
                      </div>
                    </div> 

                     {isShiftDetailsData.on_going_stop.products.length ? (
                      <div className="w-full flex justify-between items-center pt-[10px]">
                        <div className="font-normal text-sm max-w-[30vw] truncate">
                          {isShiftDetailsData?.on_going_stop?.products?.length
                            ? isShiftDetailsData?.on_going_stop?.products
                              ?.length + t(" Products")
                            : t("0 Products")}
                        </div>
                        <div>
                          <img
                            src={iInfoPackageIcon}
                            alt=""
                            className="w-[20px] h-[20px] cursor-pointer select-none"
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                     <div className="flex items-center justify-between pt-5">
                      <div
                        onClick={() => setShowIncompleteDeliveryModal(true)}
                        className="gs-list-icon-btn"
                      >
                        {t("Not Delivered")}
                      </div>
                      <div
                        className="gs-list-icon-btn"
                        onClick={() => setShowHoldModal(true)}
                      >
                        {t("On Hold")}
                      </div>
                    </div> 
                    <div className="w-full pt-5">
                      <div
                        onClick={() => setShowDeliveryModal(true)}
                        className="gs-full-size-secondary-btn"
                      >
                        {t("Complete")}
                      </div>
                    </div> 
                  </div>
                ) : <></>
              } */}

              {!checkIfShiftIsRunning({ shiftInfo: isShiftDetailsData }) && (
                <div className="flex flex-row justify-between">
                  <div>
                    {/* <CommonButton
                      btnLabel={t("Delete")}
                      colorType="danger"
                      onClick={() => {
                        setShowDelModal(true);
                      }} /> */}
                  </div>

                  <Link to="edit-shift">
                    {/* <CommonButton
                      btnLabel={t("Edit")}
                      onClick={() => {
                        setIsEditingShiftID(isShiftDetailsID);
                        setIsEditingShift(true);
                        setIsShiftDetailsID(isShiftDetailsID);
                      }}
                      canSubmit={() => { }}
                    /> */}
                  </Link>
                </div>
              )}
            </div>

            {isShiftDetailsData?.reqs?.length > 0 ? (
              <div className="w-full mt-s20 ">
                <ShiftDetailsTable dataArray={isShiftDetailsData?.reqs} />
              </div>
            ) : (
              isShiftDetailsData?.is_maintenance === 0 && (
                <div className="w-full text-[24px] text-cTextGray limadi-semibold text-center pt-s20">
                  {t("No request assigned to this shift yet!")}
                </div>
              )
            )}

            {isShiftDetailsData?.custom_stops?.length > 0 ? (
              <div className="w-full pt-s20">
                <CustomStopListTable
                  dataArray={isShiftDetailsData?.custom_stops}
                />
              </div>
            ) : (
              isShiftDetailsData?.is_maintenance === 0 && (
                <div className="w-full text-[24px] text-cTextGray limadi-semibold text-center pt-s20">
                  {t("No custom stops found in this shift!")}
                </div>
              )
            )}
            {isShiftDetailsData?.is_maintenance !== 1 ? (
              <div className="w-full pt-s20">
                <ShiftLogBookTable />
              </div>
            ) : (
              ""
            )}

            <RouteOverview />
          </div>
        )}
      </div>

      {/* Delete Modal */}
      <CommonModal
        showModal={showDelModal}
        setShowModal={setShowDelModal}
        modalTitle={t("Delete Item")}
        mainContent={
          <div>
            <div>
              <div className="py-5 text-center text-fs16">
                {t("Are you sure, you want to delete this item ?")}
              </div>

              <div className="flex flex-row justify-between">
                {/* <CommonButton
                  btnLabel={t("Cancel")}
                  onClick={() => {
                    setShowDelModal(false);
                  }}
                /> */}

                {/* <CommonButton
                  btnLabel={t("Delete")}
                  colorType="danger"
                  onClick={() => {
                    setShowDelModal(false);
                    deleteShiftData(isShiftDetailsData?.id, parseInt(company_id));
                  }}
                /> */}
              </div>
            </div>
          </div>
        }
      />

      <CommonModal
        showModal={showHoldModal}
        setShowModal={setShowHoldModal}
        modalTitle={t("Confirmation")}
        mainContent={
          <div>
            <ConfirmHold />
          </div>
        }
      />
      {
        // hb stop shift ?? confirm modal
      }
      <CommonModal
        showModal={showStopShiftModal}
        setShowModal={setShowStopShiftModal}
        mainContent={
          <div>
            <ConfirmStopShift />
          </div>
        }
      />
      {
        // hb Pause shift ?? confirm modal
      }
      <CommonModal
        showModal={showPauseShiftModal}
        setShowModal={setShowPauseShiftModal}
        mainContent={
          <div>
            <ConfirmPauseShift />
          </div>
        }
      />

      {/* hb confirm delivery modal */}
      <CommonModal
        showModal={showDeliveryModal}
        setShowModal={setShowDeliveryModal}
        mainContent={
          <div>
            <ConfirmDelivery status={1} />
          </div>
        }
      />

      {/* hb NOT delivered modal */}
      <CommonModal
        showModal={showIncompleteDeliveryModal}
        setShowModal={setShowIncompleteDeliveryModal}
        mainContent={
          <div>
            <ConfirmDelivery status={0} />
          </div>
        }
      />

      <StartStopShiftModal
        showModal={isShowShiftStartStopModal}
        setShowModal={setShowShiftStartStopModal}
        status={
          checkIfShiftIsRunning({ shiftInfo: isShiftDetailsData })
            ? "stop"
            : "start"
        }
        shift_id={isShiftDetailsData?.id}
      />

      <PauseResumeShiftModal
        showModal={isShowShiftPauseResumeModal}
        setShowModal={setShowShiftPauseResumeModal}
        status={isShiftDetailsData?.status}
        shift_id={isShiftDetailsData?.id}
      />
    </>
  );
};

export default CommonShiftShiftDetails;
