import { useState } from "react";
import { useTranslation } from "react-i18next";
import useLicenseStore, { createNewLicense } from "../../../App/stores/LicenseStore";
import CommonButton from "../../../Components/Button/CommonButton";
import CommonInput from "../../../Components/Input/CommonInput";

function AddPackage() {
  const { t } = useTranslation();
  const {addForm, setAddForm} = useLicenseStore()

  return (
    <>
      <div className="w-full bg-white p-5 rounded-md mt-5 md:mt-0 md:w-1/2">

        <div className="text-fs28 font-semibold text-gray-700 font-poppins">
          {t("Add License")}
        </div>

        <form onSubmit={(e) => {
          e.preventDefault();
          if (addForm?.number && addForm?.price && addForm?.description) {
            let createSuccess = createNewLicense(addForm);
            if (createSuccess) {
              setAddForm({
                number: "",
                price: "",
                duration: 0,
                description: "",

                month: "",
                year: "",
              });
            }
          }
        }} className="">

          <div className="py-5">
            <CommonInput
              required={true}
              label={t("Package Name")}
              onChange={(e) => setAddForm({ ...addForm, "number": e.target.value })}
              type={"text"}
              name={"number"}
              value={addForm.number}
              placeholder={t("Package name (unique)")}
            />
          </div>

          <div className="">
            <CommonInput
              max_input={8}
              required={true}
              min_number={0}
              label={t("Price")}
              onChange={(e) => setAddForm({ ...addForm, "price": e.target.value })}
              type={"number"}
              name={"price"}
              value={addForm.price}
              placeholder={t("Package price")}
              min={'1'}
            />
          </div>

          <div className="pt-5">
            <CommonInput
              required={true}
              textarea={true}
              max_char_limit={255}
              is_show_char_limit={true}
              label={t("Description")}
              onChange={(e) => setAddForm({ ...addForm, "description": e.target.value })}
              type={"text"}
              name={"description"}
              value={addForm.description}
              placeholder={t("Package description")}
            />
          </div>

          <div className="py-5 flex items-center justify-between space-x-5">
            <div className="w-full">
              <CommonInput
                required={true}
                onChange={(e) => {
                  setAddForm({
                    ...addForm,
                    "year": e.target.value,
                  });

                }}
                label={t("Year")}
                type={"number"}
                name={"year"}
                value={addForm.year}
                placeholder={t("Year")}
                min_number={0}
                max_number={10}
              />
            </div>
            <div className="w-full">
              <CommonInput
                required={true}
                onChange={(e) => {
                  setAddForm({
                    ...addForm,
                    "month": e.target.value,
                  });

                }}
                label={t("Month")}
                type={"number"}
                name={"month"}
                min_number={0}
                max_number={11}
                value={addForm.month}
                placeholder={t("Month")}
              />
            </div>
          </div>

          <div className="flex justify-center">
            <CommonButton
              btnLabel={t("Add License")}
              type="submit"
            />
          </div>

        </form>
      </div>
    </>
  );
}

export default AddPackage;
