import { t } from 'i18next';
import useGeneralStore from '../../App/stores/GeneralStore';
import useTransportationTypesStore, { getTransportationTypes } from '../../App/stores/TransportationTypesStore';
import CommonButtonOutlined from '../../Components/Button/CommonButtonOutlined';
import CircularProgressBar from '../../Components/CircularProgressBar';
import CommonTable from '../../Components/Table/CommonTable';
import { iRefresh } from '../../Utility/Sources';
import TransportationTypeTableRow from './components/TransportationTypeTableRow';
import NoDataRow from '../../Components/Table/NoDataRow';
import { useEffect } from 'react';

export default function ManageTransportationType() {
  const { transportationTypes } = useTransportationTypesStore();


  const header = [
    { index: 1, name: t("title") },
    { index: 2, name: t("Duration (Min)") },
    { index: 3, name: t("status") },
    { index: 4, name: t("action") },
  ];

  useEffect(() => {
    getTransportationTypes();
  }, []);
  return (
    <div>
      <TitleBar count={transportationTypes?.data?.length} />

      <div className="justify-between w-full space-x-0 md:flex md:space-x-5">

        <CommonTable
          headers={header}
          pagination={false}
          totalResult={transportationTypes?.total}
          showingFrom={transportationTypes?.from}
          showingTo={transportationTypes?.to}
          items={transportationTypes?.data?.length > 0 ? transportationTypes?.data?.map((item, index) => <TransportationTypeTableRow key={index} data={item} />) : <NoDataRow />}

          shoSearchBox={false}

          paginationObject={transportationTypes}
          showPagination={transportationTypes?.last_page !== 1 ? true : false}

        />
      </div>
    </div>
  )
}



const TitleBar = ({ count }) => {

  const { isLoading } = useGeneralStore();
  const { setShowAddTransportationTypeModal, setEditTransportationTypeMode } = useTransportationTypesStore();
  return (
    <>
      <div className="flex flex-row justify-between w-full p-5 mb-5 bg-white shadow-sm rounded-2xl">
        <div className="flex items-center text-fs28 font-fw600">
          <div className="capitalize">{'Transportation Types'}</div>
          <div className="pr-5 pl-s5">({count ?? 0})</div>
          {
            isLoading ?
              <CircularProgressBar />
              :
              <img
                onClick={async () => { await getTransportationTypes(); }}
                src={iRefresh}
                alt="refresh-icon"
                className='cursor-pointer'
              />
          }
        </div>

        <CommonButtonOutlined
          width={'w-[210px]'}
          btnLabel={'Add Transportation Type'}
          onClick={() => { 
            setShowAddTransportationTypeModal(true); 
            setEditTransportationTypeMode(false);
          }}
        />
      </div>
    </>
  );
}



// const TakeItem = () => {
//   const { setCompanyTakeAmount, companyTakeAmount, companySearchValue, setCompanyPageUrl } = useCompanyStore();
//   return (
//       <div className=''>
//           <span className='pr-s10'>Show</span>
//           <select
//               value={companyTakeAmount ? companyTakeAmount : 10}
//               onChange={async (e) => {
//                   console.log(e.target.value);
//                   setCompanyTakeAmount(e.target.value);
//                   console.log(e.target.value);
//                   setCompanyPageUrl()
//                   if (companySearchValue) {
//                       searchUsers(companySearchValue, 'company', e.target.value)
//                   } else await getCompanies();
//               }}
//               className='pl-5 h-s30 rounded-br5 select-style space-y-s5'
//           >
//               <option
//                   className='py-s10 text-cMainBlack'
//                   selected={companyTakeAmount === 10}
//                   value={10}
//               >10</option>

//               <option
//                   className='py-s10 text-cMainBlack'
//                   selected={companyTakeAmount === 25}
//                   value={25}
//               >25</option>
//               <option
//                   className='py-s10 text-cMainBlack'
//                   selected={companyTakeAmount === 50}
//                   value={50}
//               >50</option>
//               <option
//                   className='py-s10 text-cMainBlack'
//                   selected={companyTakeAmount === 100}
//                   value={100}
//               >100</option>
//           </select>
//           <span className='pl-s10'>Entries</span>

//       </div>
//   )
// }
