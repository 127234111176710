import UpdateLicenseModal from '../../Pages/License/Application/UpdateLicenseModal'
import DeleteLicensePackage from '../../Pages/License/Package/DeleteLicensePackage'
import DeleteMessage from '../../Pages/Message/DeleteMessage'
import ReplyMessage from '../../Pages/Message/ReplyMessage'
import DeleteCarModal from '../../Pages/Users/Companies/CompanyDetails/CarManagement/Modals/DeleteCarModal'
import DeactivateCompany from '../../Pages/Users/Companies/modals/DeactivateCompany'
import DeleteCompanyModal from '../../Pages/Users/Companies/modals/DeleteCompanyModal'
import EditCompanyDetails from '../../Pages/Users/Companies/modals/EditCompanyDetails'
import ResetPasswordCompany from '../../Pages/Users/Companies/modals/ResetPasswordCompany'
import DeactivateCustomer from '../../Pages/Users/Customers/modals/DeactivateCustomer'
import DeleteCustomerModal from '../../Pages/Users/Customers/modals/DeleteCustomerModal'
import EditCustomerDetails from '../../Pages/Users/Customers/modals/EditCustomerDetails'
import ResetPasswordCustomer from '../../Pages/Users/Customers/modals/ResetPasswordCustomer'
import DeactivateDriver from '../../Pages/Users/Drivers/modals/DeactivateDriver'

import DeleteDriverModal from '../../Pages/Users/Drivers/modals/DeleteDriverModal'
import DeleteDriverModalDriver from '../../Pages/Users/Companies/CompanyDetails/DriverManagement/DeleteDriverModal';

import EditDriverDetails from '../../Pages/Users/Drivers/modals/EditDriverDetails'
import ResetPasswordDriver from '../../Pages/Users/Drivers/modals/ResetPasswordDriver'
import DeleteBanner from '../Button/DeleteBanner'
import LoadingModal from './LoadingModal'
import ConfirmAccept from '../../Pages/License/Application/Modal/ConfirmAccept'
import ConfirmReject from '../../Pages/License/Application/Modal/ConfirmReject'
import NewMessageReply from '../../Pages/Dashboard/NewMessageReply'
import MessageFilterModal from '../../Pages/Message/Modal/MessageFilterModal'
import ConfirmDeactivate from '../../Pages/License/Application/Modal/ConfirmDeactivate'
// import LicenseModal from '../../Pages/Users/Companies/CompanyDetails/CarManagement/LIcenseModal'
import AdminLicenseApply from '../../Pages/License/Application/Modal/AdminLicenseApply'
import AddCustomerModal from '../../Pages/Users/Customers/modals/AddCustomerModal'
import GlobalRequestDetailsModal from '../../Pages/Users/Companies/CompanyDetails/CloudRequest/Modal/GlobalRequestDetailsModal'
import AddCompany from '../../Pages/Users/Companies/modals/AddCompany'
import AddNewDriver from '../../Pages/Users/Drivers/modals/AddNewDriver'
import AssignedCompaniesModal from '../../Pages/Users/Drivers/AssignedCompanies/AssignedCompaniesModal'
import AssignedCompaniesDeclineModal from '../../Pages/Users/Drivers/AssignedCompanies/AssignedCompaniesDeclineModal'
import WebMessageFilterModal from '../../Pages/Message/WebMessage/WebMessageFilterModal'
import WebMessageDetailsModal from '../../Pages/Message/WebMessage/WebMessageDetailsModal'
import ImageViewerModal from '../../Pages/Users/Common/RequestSection/modal/ImageViewerModal'
import BiddingCompaniesDetails from '../../Pages/Users/Common/RequestSection/modal/BiddingCompaniesDetails'
import CustomStopDetails from '../../Pages/Users/Companies/CompanyDetails/ShiftPlanner/Details/CustomStopDetails'
import CarAcceptModal from '../../Pages/Users/Companies/CompanyDetails/CarManagement/Modals/CarAcceptModal'
import CarRejectModal from '../../Pages/Users/Companies/CompanyDetails/CarManagement/Modals/CarRejectModal'
import ReviewModal from '../../Pages/Users/Common/FavoriteCompanies/ReviewModal'
import AddChatModal from '../../Pages/chat/modal/AddChatModal'
import ChatDeleteModal from '../../Pages/chat/modal/ChatDeleteModal'
import ImagePreviewPopup from '../Image/ImagePreviewPopup'
import AddEditTransportationTypeModal from '../../Pages/ManageTransportationType/components/AddEditTransportationTypeModal'

export default function CommonModalArea() {
    return (
        <div>
            <LoadingModal />

            {/* <ImageViewerModal /> */}

            <ReplyMessage />

            <DeleteMessage />

            <WebMessageFilterModal />
            <WebMessageDetailsModal />

            <DeleteBanner />

            <DeleteLicensePackage />

            <UpdateLicenseModal />
            <ConfirmAccept />
            <ConfirmReject />

            {/* l       company modals */}
            <AddCompany />
            <DeactivateCompany />
            <DeleteCompanyModal />
            <ResetPasswordCompany />
            <EditCompanyDetails />

            {/* b: customer modals */}
            <EditCustomerDetails />
            <DeleteCustomerModal />
            <DeactivateCustomer />
            <ResetPasswordCustomer />
            <AddCustomerModal />

            {/*e        driver modals */}
            <AddNewDriver />
            <DeactivateDriver />
            <DeleteDriverModal />
            <ResetPasswordDriver />
            <EditDriverDetails />
            <AssignedCompaniesModal />
            <AssignedCompaniesDeclineModal />

            {/*e car management */}
            {/* <DeleteCarModal /> */}

            {/* driver management */}
            <DeleteDriverModalDriver />

            {/* b: dashboard area */}
            <NewMessageReply />

            {/* b: message filter */}
            <MessageFilterModal />

            {/* g: license modal */}
            {/* <LicenseModal /> */}
            <ConfirmDeactivate />
            <AdminLicenseApply />

            <GlobalRequestDetailsModal />
            <BiddingCompaniesDetails />
            <CustomStopDetails />
            {/* <CarAcceptModal/> */}
            <ReviewModal />


            {/* chat */}
            <AddChatModal />
            <ChatDeleteModal />
            <ImagePreviewPopup />

            {/* transportation types */}
            <AddEditTransportationTypeModal />

        </div>
    )
}
